import { LaunchersState } from '@core/interfaces/launchers';
import { createReducer, on } from '@ngrx/store';
import * as launchersActions from './launchers.actions';



export const launchersState: LaunchersState = {
  launchers: []
};

const _launchersReducer = createReducer( launchersState,
  on(launchersActions.setLaunchers, (state, launchersState) =>  {
    return {...state, launchers: launchersState.launchers }
  }),
);


export function launchersReducer(state, action) {
  return _launchersReducer(state, action);
}