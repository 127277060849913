
            <div
              class="d-flex justify-content-between p-16 header-table-awards"
            >
              <div class="fontw-600 fts-12">Condición</div>
              <div class="fontw-600 fts-12">Monto</div>
            </div>
            <hr class="dividing-line-solid m-0" />

            <!-- Filas de la tabla generadas dinámicamente -->
            <ng-container *ngFor="let item of itemsSkeleton">
              <div class="d-flex justify-content-between pv-12 ph-16">
                <div class="fontw-400 fts-14 vw-85">
                  <ngx-skeleton-loader count="1" appearance="line">
                  </ngx-skeleton-loader>
                </div>
                <div class="fontw-400 fts-14 vw-85">
                  <ngx-skeleton-loader count="1" appearance="line">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <hr class="dividing-line-solid m-0" />
            </ng-container>

            <div
              class="d-flex justify-content-between p-16 total-profit align-items-center"
            >
              <div class="font-medium fontw-700">Ganancia Total</div>
              <div class="font-medium vw-85">
                <ngx-skeleton-loader count="1" appearance="line">
                </ngx-skeleton-loader>
              </div>
            </div>