import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageActivitiesNull } from 'src/assets/data/errors-message';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-routine-modal',
  templateUrl: './routine-modal.component.html',
  styleUrls: ['./routine-modal.component.scss'],
})
export class RoutineModalComponent {
  public pageSize = 1; // Ítems por página
  messageClientNull = [MessageActivitiesNull];
  constructor(
    public dialogRef: MatDialogRef<RoutineModalComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }
  onPageChange(page: number) {
    console.log(this.findRoutineByPosition(page + 1));
    this.data.routine = this.findRoutineByPosition(page + 1);
  }

  close(): void {
    this.dialogRef.close();
  }

  goToUrl(url: string) {
    window.open(url, '_blank');
  }

  findRoutineByPosition(position: number) {
    return this.data.routines.routines.find(
      (routine: any) => routine.position === position
    );
  }

  processText(text: string): SafeHtml {
    const html = text.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
