import { createSelector } from '@ngrx/store';
import { CarsState } from '@core/interfaces/cars';
import { AppState } from './app.reducer';
 
export const selectCarsStateFeature = (state: AppState) => state.carsState;
export const selectClientStateFeature = (state: AppState) => state.clientSelect;
export const listCars = createSelector(
  selectCarsStateFeature,
  (state: CarsState) => state.cars
);
export const selectLoginUserInfo = createSelector(
  selectClientStateFeature,
  (state: any) => {
    return state.client
  }
);