import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Sectors } from '@core/interfaces/user.model';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-business-unities',
  templateUrl: './business-unities.component.html',
  styleUrls: ['./business-unities.component.scss']
})
export class BusinessUnitiesComponent implements OnInit, OnDestroy {
  businessUnities: Array<Sectors> = [];
  logInSuscription!: Subscription;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private _localStorageService: LocalStorageService,
  ) { }
  ngOnDestroy(): void {
    this.logInSuscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initStore();
  }

  selectUnit(i) {
    this._localStorageService.setItem('sectorSelect',i);
    this.router.navigate(['/tienda']);
  }

  initStore() {
    this.logInSuscription = this.store.select('auth').subscribe((authState) => {
      if(authState.user){
        this.businessUnities = authState.user.sectors.map((element : Sectors) => {
          let temp: Sectors = {
            ...element,
            hover: false,
            image: element.name.toLowerCase()
          }
          return temp
        })
      }
    })
    this._localStorageService.setItem('sectors',this.businessUnities);
  }

}
