<!-- Contenido principal -->
<div class="container-detail-client">
  <!-- Contenedor cliente y período -->
  <div class="container-client container-border-line p-24">
    <!-- Imagen del cliente -->
    <div
      class="container-border-line vw-100 content-img-client show-desktop-img_client"
    >
      <div class="vw-100 align-items-center d-flex">
        <img
          src="assets/svg/sales-progress/sales-progress-client.svg"
          alt=""
          width="100%"
        />
      </div>
    </div>
    <!-- Detalles del cliente -->
    <div class="container-border-line mt-12 mb-12 p-12 data-client">
      <div class="d-flex">
        <img
              src="assets/svg/awards/icon-storefront.svg"
              alt=""
              class="pr-8"
            />
        <div class="vw-100 align-items-center name-client pl-12">
          <ngx-skeleton-loader count="1" appearance="line" >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <!-- Período -->
    <div class="container-border-line mt-12 mb-12 p-12 data-client">
      <div class="d-flex">
        <img
        src="assets/svg/awards/icon-calendar.svg"
        alt=""
        class="pr-8"
      />
        <div class="vw-100 align-items-center name-client pl-12">
          <ngx-skeleton-loader count="1" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>

  <!-- Contenedor de información general -->
  <div class="container-border-line vw-100 p-24">
    <div class="d-flex justify-content-between align-items-center info-general">
      <div class="">
        <div class="vw-100 align-items-center">
          <div class="font-medium fts-16 fontw-700">Resumen general</div>
        </div>
      </div>
    </div>
    <!-- Línea divisoria -->
    <hr class="dividing-line-solid my-3" />
    <section class="container-cards">
      <ng-container *ngFor="let item of [1, 2, 3]">
        <div class="container-border-line pv-24 ph-20 show-desktop-tablet">
          <div class="d-flex ai-center justify-content-between">
            <div class="align-items-center d-flex justify-content-center">
              <div class="vw-100 align-items-center">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  class="loader-unit"
                  [theme]="{
                    height: '32px',
                    width: '100px',
                    'border-radius': '8px'
                  }"
                >
                </ngx-skeleton-loader>
              </div>
            </div>
            <div class="p-0 m-0 fts-14">
              <div class="vw-100 align-items-center">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  class="loader-unit"
                  [theme]="{
                    height: '16px',
                    width: '64px',
                    'border-radius': '16px'
                  }"
                >
                </ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="vw-100">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '8px',
                  width: '100%',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="d-flex ai-center justify-content-between vw-100">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '48px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
            <div class="vw-100 align-items-center d-flex justify-content-end">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '48px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="d-flex ai-center justify-content-between">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '64px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
            <div class="vw-100 align-items-center d-flex justify-content-end">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '64px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
          <!-- Línea divisoria -->
          <hr class="dividing-line-solid my-2 show-desktop" />
          <div class="d-flex ai-center justify-content-between">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '128px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
            <div class="vw-100 align-items-center d-flex justify-content-end">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '64px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="d-flex ai-center justify-content-between">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '128px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
            <div class="vw-100 align-items-center d-flex justify-content-end">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '64px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="show-mobile">
        <div class="d-flex justify-content-start">
          <div class="d-flex align-items-center mr-8">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              [theme]="{
                height: '32px',
                width: '117px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>

          <div class="d-flex align-items-center mr-8">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              [theme]="{
                height: '32px',
                width: '92px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>

          <div class="d-flex align-items-center">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              [theme]="{
                height: '32px',
                width: '61px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="container-border-line pv-24 ph-20 show-mobile">
        <div class="d-flex ai-center justify-content-between">
          <div class="align-items-center d-flex justify-content-center">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '32px',
                  width: '100px',
                  'border-radius': '8px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="p-0 m-0 fts-14">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="loader-unit"
                [theme]="{
                  height: '16px',
                  width: '64px',
                  'border-radius': '16px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="vw-100">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              [theme]="{
                height: '8px',
                width: '100%',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="d-flex ai-center justify-content-between vw-100">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '48px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
          <div class="vw-100 align-items-center d-flex justify-content-end">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '48px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="d-flex ai-center justify-content-between">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '64px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
          <div class="vw-100 align-items-center d-flex justify-content-end">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '64px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
        <!-- Línea divisoria -->
        <hr class="dividing-line-solid my-2 show-desktop" />
        <div class="d-flex ai-center justify-content-between">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '128px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
          <div class="vw-100 align-items-center d-flex justify-content-end">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '64px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="d-flex ai-center justify-content-between">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '128px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
          <div class="vw-100 align-items-center d-flex justify-content-end">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '64px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
