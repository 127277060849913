import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from '@core/services/utils/utils.service';

export interface errors {
  sku: string;
  description: string;
}

@Component({
  selector: 'app-suggested-order-modal',
  templateUrl: './suggested-order-modal.component.html',
  styleUrls: ['./suggested-order-modal.component.scss'],
})
export class SuggestedOrderComponent implements OnInit, OnDestroy {
  checked = false;
  dataRecieved: any;
  dataSourceSuggestedProducts: any[] = [];
  noDataSourceSuggestedProducts: any[] = [];
  atLeastOneAcceptSuggestion: boolean = false;
  shouldDisableButton: boolean = true;
  modals = [
    {
      img: '',
      title:
        'Hay productos sin suficiente stock, puedes reemplazarlos por los siguientes.',
      description: 'Si no aceptas las sugerencias, los productos que no cuenten con stock suficiente se mantendrán en tu carrito para que puedas reintentar con una cantidad menor.',
      actions: [
        {
          name: 'Cancelar',
          class: 'font-medium close-class',
          event: () => this.dialogRef.close(),
        },
        {
          name: 'Confirmar',
          class: 'font-medium custom-class',
          event: () => this.save(1, 'success'),
        },
      ],
    },
    {
      img: '',
      title:
        'Estos productos no cuentan con suficiente stock.',
      description: 'Los productos que no cuenten con stock suficiente se mantendrán en tu carrito para que puedas reintentar con una cantidad menor.',
      actions: [
        {
          name: 'Entendido',
          class: 'font-medium custom-class',
          event: () => this.dialogRef.close(),
        },
      ],
    },
  ];
  displayedColumns: string[] = ['sku', 'description'];
  constructor(
    public dialogRef: MatDialogRef<SuggestedOrderComponent>,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.dataRecieved = data;
    this.dataSourceSuggestedProducts = data.skuError.filter(
      (value) => value.suggestedProducts?.length > 0
    );
    this.noDataSourceSuggestedProducts = data.skuError.filter(
      (value) => !(value.suggestedProducts?.length > 0)
    );
  }

  ngOnInit(): void {
    this.openedStart();
  }

  ngOnDestroy(): void {
    this.closedStart();
  }
  changeCount(e, product, index) {
    this.dataSourceSuggestedProducts[index].suggestedProducts[0].count = e;
    if (
      this.stringToNumber(
        this.dataSourceSuggestedProducts[index].suggestedProducts[0].count
      ) > this.stringToNumber(this.dataSourceSuggestedProducts[index].count)
    ) {
      this.dataSourceSuggestedProducts[index].acceptSuggestion = false;
    }
    this.changeCountTransforme(e, product, index);
  }
  changeCountTransforme(e, p, i) {
    const replace = e.replace(/[^0-9-.]/g, '');
    let countFormat = parseFloat(replace);
    if (isNaN(countFormat) || countFormat == 0) {
      p.suggestedProducts[0].totalPrice = (0.0).toFixed(2);
      p.suggestedProducts[0].count = '';
    } else if (countFormat < 0) {
      countFormat = countFormat * -1;
      p.suggestedProducts[0].totalPrice = UtilsService.formatTotalNumber(
        (countFormat * parseFloat(p.suggestedProducts[0].salePrice)).toFixed(2)
      );
      p.suggestedProducts[0].count = UtilsService.formatNumber(countFormat);
    } else if (countFormat > 999999) {
      p.suggestedProducts[0].totalPrice = UtilsService.formatTotalNumber(
        (999999 * parseFloat(p.suggestedProducts[0].salePrice)).toFixed(2)
      );

      p.suggestedProducts[0].count = '999,999';
    } else {
      p.suggestedProducts[0].count = UtilsService.formatNumber(countFormat);
      p.suggestedProducts[0].totalPrice = UtilsService.formatTotalNumber(
        (countFormat * parseFloat(p.suggestedProducts[0].salePrice)).toFixed(2)
      );
    }

    this.checkAtLeastOneAcceptSuggestion(
      parseInt(p.suggestedProducts[0].count.replace(/,/g, '')),
      parseInt(p.count.replace(/,/g, ''))
    );
  }
  evaluateValue(p, index) {
    if (
      p.suggestedProducts[0].count == '' ||
      p.suggestedProducts[0].count == 0
    ) {
      this.changeCount('1', p, index);
    }
  }
  updateDisableButton(){
    this.shouldDisableButton = (this.atLeastOneAcceptSuggestion && this.dataSourceSuggestedProducts.length > 0) ||
    !this.dataSourceSuggestedProducts.some(item => item.acceptSuggestion);
  }
  isCancelButton(actionName: string): boolean {
    return actionName.toLowerCase() === 'cancelar' || actionName.toLowerCase() === 'entendido';
  }

  updateAcceptSuggestion(newValue: boolean, index: number) {
    this.dataSourceSuggestedProducts[index].acceptSuggestion = newValue;
    this.updateDisableButton();
  }
  checkAtLeastOneAcceptSuggestion(newvalues, value) {
    this.atLeastOneAcceptSuggestion = this.dataSourceSuggestedProducts.some(
      (value) =>
        this.stringToNumber(value.suggestedProducts[0].count) >
        this.stringToNumber(value.count)
    );
    this.updateDisableButton();
  }
  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  save(status, message) {
    let concatProducts = this.dataSourceSuggestedProducts.concat(
      this.noDataSourceSuggestedProducts
    );
    this.dialogRef.close({
      status: status,
      message: message,
      concatProducts: concatProducts,
    });
  }
  // Dentro de tu componente
  stringToNumber(str: string): number {
    return +str.replace(/,/g, '');
  }
  onNoClick() {
    this.dialogRef.close(false);
  }
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
