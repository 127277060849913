import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SalesProgressService implements CanActivate {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  canActivate(_route: ActivatedRouteSnapshot): boolean {
    const userType = this.localStorageService.getItem('user')?.type;
    if (userType === 'Vendedor') {
      return true;
    } else {
      this.router.navigate(['/tienda']); // Cambia '/inicio' por la ruta que desees
      return false;
    }
  }
}
