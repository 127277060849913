
// Core
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

// Services
import { HttpClienteService } from '@core/services/http-cliente.service';
@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private url = environment.apiURL;
  user: any;
  constructor(
    public router: Router,
    private _http: HttpClienteService,) {
  }

  getCategories(obj: any) {
    const query = `${this.url}/wcl-material/categories?code=${obj.code}&sector=${obj.sector}&zone=${obj.zone}&schema=${obj.schema}&segment=${encodeURIComponent(obj.segment)}`;
    return this._http.get(query);
  }
  getSearchCategories(obj: any) {
    const query = `${this.url}/wcl-material/search-products?code=${obj.code}&value=${obj.description}&sector=${obj.sector}&zone=${obj.zone}&schema=${obj.schema}&segment=${encodeURIComponent(obj.segment)}`;
    return this._http.get(query);
  }
}
