import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderChangeService {
  private buttonStateSubject = new BehaviorSubject<boolean>(false);

  setButtonState(value: boolean): void {
    this.buttonStateSubject.next(value);
  }

  getButtonState(): boolean {
    return this.buttonStateSubject.getValue();
  }

  getButtonStateObservable() {
    return this.buttonStateSubject.asObservable();
  }
}
