<div class="paginator" [ngClass]="displayType">
  <button (click)="previousPage()" [disabled]="pageIndex === 0" [ngClass]="{'desktop-button': displayType === 'desktop', 'mobile-button': displayType === 'mobile'}">
    <img src="assets/svg/paginator-previous.svg" alt="Previous" [ngClass]="{'desktop-icon': displayType === 'desktop', 'mobile-icon': displayType === 'mobile'}" />
    <span [ngClass]="{'desktop-span': displayType === 'desktop', 'mobile-span': displayType === 'mobile'}" *ngIf="displayType === 'mobile'">Anterior</span>
  </button>
  <span *ngFor="let page of pages" (click)="setPage(page)" [class.active]="page === pageIndex + 1">
    {{ page }}
  </span>
  <button (click)="nextPage()" [disabled]="pageIndex === totalPages - 1" [ngClass]="{'desktop-button': displayType === 'desktop', 'mobile-button': displayType === 'mobile'}">
    <span [ngClass]="{'desktop-span': displayType === 'desktop', 'mobile-span': displayType === 'mobile'}" *ngIf="displayType === 'mobile'">Siguiente</span>
    <img src="assets/svg/paginator-next.svg" alt="Next" [ngClass]="{'desktop-icon': displayType === 'desktop', 'mobile-icon': displayType === 'mobile'}" />
  </button>
</div>
