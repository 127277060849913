import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Car } from '@core/interfaces/cars';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import * as ShoppingActions from '@core/store/shoppingCar/shoppingCar.actions';
import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  selector: 'app-show-seller-car',
  templateUrl: './show-seller-car.component.html',
  styleUrls: ['./show-seller-car.component.scss']
})
export class ShowSellerCarComponent implements OnInit {
  @Input() car!: Car;
  @Input() totalPriceAdd = 0;
  @Output() countProduct = new EventEmitter<any>();
  @Output() selectConfirmation = new EventEmitter<any>();
  @Output() deleteProduct = new EventEmitter<any>();
  @Output() deleteCar = new EventEmitter<any>();
  @Output() emitCloseX = new EventEmitter<string>();

  @ViewChildren('referenceInput', { read: ElementRef })
  referenceInput!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild(MatMenuTrigger) matMenuTrigger!: MatMenuTrigger;

  cart?: any;
  constructor(private ref: ChangeDetectorRef, private router: Router, private store: Store<AppState>,private localStorageService: LocalStorageService) {}

  ngOnInit() {
    this.store
    .select('clientSelect')
    .subscribe((cliCentSelect:any) => {
      this.store
      .select('carsState')
      .subscribe((carsState) => {
        const tempCar = carsState.cars.find(
          (car) =>
            car.unitBussines?.toLowerCase() ==
            cliCentSelect.client?.name.toLowerCase()
        );
        this.cart = tempCar
      })
    });
  }

  onHiddenInputFocus() {
    const customButton = document.querySelector('.custom-button');
    if (customButton) {
      customButton.classList.remove('no-focus-outline');
    }
  }

  validatePrice(a: any, b: any) {
    const count = a.toString();
    if (parseInt(count.replace(/,/g, ''), 10) >= b && b != 0) {
      return true;
    } else {
      return false;
    }
  }
  closeX() {
    this.emitCloseX.emit();
  }

  changeCount(e: any, p: any, index: number) {
    this.countProduct.emit({ e, p });
    this.ref.detectChanges();
    const refInput = this.referenceInput.find((_, i) => i == index);
    if (refInput) {
      refInput.nativeElement.select();
    }
  }
  evaluateValue(p: any, index: any) {
    if (p.count == '' || p.count == 0) {
      this.changeCount('1', p, index);
      const refInput = this.referenceInput.find((_, i) => i == index);
      const refInputResponsive = this.referenceInput.find((_, i) => i == index);
      refInput?.nativeElement.classList.add('animation');
      refInputResponsive?.nativeElement.classList.add('animation');
    }
  }
  deleteCardProduct(p: any) {
    this.deleteProduct.emit(p);
  }
  closeMenu() {
    this.matMenuTrigger.closeMenu();
  }
  cleanAll() {
    this.deleteCar.emit();
    this.closeX();
  }

  clickConfirmOrder(){
    this.closeX();
    this.router.navigate(['/main/confirmacion']);
  }

}
