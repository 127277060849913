import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-account',
  templateUrl: './skeleton-account.component.html',
  styleUrls: ['./skeleton-account.component.scss']
})
export class SkeletonAccountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
