<section>
  <div class="container d-flex jc-center ai-center">
   <div class="show-box-shadow content-error ta-c">
    <div class="logo mb-40">
      <img src="assets/svg/logo_alicorp.svg" width="172" height="50" alt="Alicorp">
    </div>
    <div class="iconography-two mb-30">
      <img src="{{ responseService.img }}" width="175" height="160" alt="">
    </div>
    <p class="ta-c font-medium fts-24">{{responseService.title}}</p>
    <p class="ta-c mb-0">{{responseService.description}}</p>
    <div class="ta-c mt-24">
      <button href="#" class="btn-green w-button mb-8 fts-16 font-medium ph-16" (click)="goBack()"
      *ngIf="responseService?.button">Regresar</button>
    </div>
   </div>
</div>
</section>