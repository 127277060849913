
// Core
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { Login, LoginClient } from '@core/interfaces/login';
import { Subscriber } from 'rxjs';

// Services
import { HttpClienteService } from '@core/services/http-cliente.service';
import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})

export class AuthListClientService {
  
  useIsLogIn

  private token:string;
  private url = environment.apiURL;
  private obsIsLoggedIn: Observable<boolean>;
  private subscriberIsLoggedIn: Subscriber<boolean> | undefined;
  user : any;

  constructor(
    public router: Router,
    private _http: HttpClienteService,
    private _localStorageService:LocalStorageService,
  ) {
    this.token = this.getToken();
    this.useIsLogIn = new BehaviorSubject(this.getUser)
    this.obsIsLoggedIn = new Observable((subscriber) => {
      this.subscriberIsLoggedIn = subscriber;
      this.subscriberIsLoggedIn && this.subscriberIsLoggedIn.next(this.isLoggedIn())
    })
  }

  subscribeIsLoggedIn(): Observable<boolean>{
    return this.obsIsLoggedIn
  }

  pushIsLoggedIn(): void{
    this.subscriberIsLoggedIn && this.subscriberIsLoggedIn.next(this.isLoggedIn());
  }

  getToken(): string {
    return this.getUser() ?
    (this.getUser().token && this.getUser().token != "" ? this.getUser().token: {} ) : {};
  }

  getUser() {
    const user = this._localStorageService.getItem('user');
    return Object.keys(user).length !== 0 ? user: null;
  }

  setUser(obj: any) {
    this._localStorageService.setItem('user', obj);
    this.token = this.getToken();
    if(this.token) this.pushIsLoggedIn();
    this.user = this.getUser();
    
  }

  removeUser() {
    this._localStorageService.removeItem('user');
    this._localStorageService.removeItem('sectors');
    this._localStorageService.removeItem('sectorSelect');
    this.token = this.getToken();
    this.user = this.getUser();
  }

  getSector() {
    return this._localStorageService.getItem('sectorSelect');
  }
  isSectorSelect(): boolean {
    const sector = this.getSector();
    return Object.keys(sector).length !== 0
  }

 login(obj: Login){
    const query = `${this.url}/wcl-authentication/login`;
    return this._http.post(query,obj);
  }
  loginListClient(obj: LoginClient){
    const query = `${this.url}/wcl-authentication/seller-login`;
    return this._http.post(query,obj);
  }
  logout() {
    this._localStorageService.cleanStore();
    this.pushIsLoggedIn();
    this.router.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return Object.keys(token).length !== 0
  }

  getInformation(obj){
    const query = `${this.url}/wcl-customer/address-delivery?code=${obj.code}&sector=${obj.sector}`;
    return this._http.get(query);
  }

}
