import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate
} from '@angular/router';
import { CheckingAccountService } from '@core/services/checking-account/checking-account.service';
@Injectable()
export class SummaryGuard implements CanActivate {
  constructor(
    private orderAccessService: CheckingAccountService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    if (this.orderAccessService.isAccessPermitted()) {
      return true; // Permite el acceso a la URL '/main/checkout'
    } else {
      this.router.navigate(['/main/inicio']); // Redirige a la página de inicio si no se permite el acceso
      return false;
    }
  }
}
