<!-- MODO DESKTOP -->
<div class="container-table overflow-auto pt-0 hp-100">
  <div class="table-responsive mb-2 bg-white rounded">
    <table class="table vw-100">
      <thead class="shadow-sm">
        <tr>
          <th
            *ngFor="let value of headerTable"
            class="table-head ta-c {{ value.classname }}"
            scope="col"
          >
            <span class="text-size-table"> {{ value.title }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let number of counter(cantColm)">
          <td
            *ngFor="let number of counter(cantColmPromotionPrices)"
            scope="row"
            class="ta-c td-padding align-middle"
          >
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="mb-0"
            ></ngx-skeleton-loader>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
