import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/authentication/auth.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ClientJS } from 'clientjs';
import { Base64 } from 'js-base64';

interface EcommerceItem {
  item_name: string;
  item_id: number;
  item_brand: string;
  item_category: string;
  price: number;
  quantity: number;
  index: number;
}

@Injectable({
  providedIn: 'root',
})
export class GtmEventService{
  public typeDevice: any;
  public user: any;
  public sector: any;
  private _totalPrice: any;
  private _products: any;
  public clientInfo: any = {};
  public clientIp: string = '';
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService,
    private _authService: AuthService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private _localStorageService: LocalStorageService
  ) {
    this.clientInfo  = new ClientJS();
    this.sector = this.setSector()
    this.detectDeviceType();
  }

  set totalPrice(value: any) {
    this._totalPrice = value;
  }

  get totalPrice(): any {
    return this._totalPrice;
  }

  set products(value: any) {
    this._products = value;
  }

  get products(): any {
    return this._products;
  }

  private detectDeviceType(): void {
    if (this.deviceService.isDesktop()) {
      this.typeDevice = 'Desktop';
    } else if (this.deviceService.isMobile()) {
      this.typeDevice = 'Mobile';
    } else if (this.deviceService.isTablet()) {
      this.typeDevice = 'Tablet';
    } else {
      this.typeDevice = 'Desconocido';
    }
  }

  setSector(){
    return this._localStorageService.getItem('sectorSelect').name || undefined;
  }

  setUser(){
    return this._localStorageService.getItem('user').code || undefined;
  }

  setCarsStorage(){
    const data : EcommerceItem[] = [];
    const cars = this._localStorageService.getItem('cars')
    const sector = this.setSector();
    const coreUnitBussiness = cars?.filter(item => item.unitBussines === sector)
    coreUnitBussiness[0]?.products?.map((product:any, index:any) => {
      const payload : EcommerceItem = {
        item_name: product.description,
        item_id: product.sku,
        item_brand: product.brandName || product.brand_name,
        item_category: product.categoryName || product.category_name,
        price: Number(product.salePrice),
        quantity: parseInt(product.count.replace(",", ""), 10),
        index: index,
      }
      data.push(payload);
    })
    return data;
  }

  setCars(products){
    const carState = this.setCarsStorage();
    const data : EcommerceItem[] = [];
    if(products.length){
      products.map( (product, index) => {
        const payload = {
          item_name: product.description,
          item_id: product.sku,
          item_brand: product.brandName || product.brand_name,
          item_category: product.categoryName || product.category_name,
          price: Number(product.salePrice),
          quantity: parseInt(product.count.replace(",", ""), 10),
          index: index,
        }
        data.push(payload)
      })
      return data
    } else {
      let _i: any;
      carState.map( item => {
        if(products.sku == item.item_id){
          _i = item.index;
        }
      })
      const payload = {
        item_name: products.description,
        item_id: products.sku,
        item_brand: products.brandName || products.brand_name,
        item_category: products.categoryName || products.category_name,
        price: Number(products.salePrice),
        quantity: parseInt(products.count.replace(",", ""), 10),
        index: _i ? _i : 1,
      }
      data.push(payload)
      return data
    }
  }

  setTotalPrice(){
    const cars = this._localStorageService.getItem('cars')
    const sector = this.setSector();
    const coreUnitBussiness = cars.filter(item => item.unitBussines === sector)
    const valorTotal: number = coreUnitBussiness[0].products.reduce((total: number, producto: any) => {
      const totalPrice: number = parseFloat(producto.totalPrice.replace(/,/g, ''));
      return total + totalPrice;
    }, 0);
    return valorTotal.toFixed(2);
  }

  async pushEvent(eventData: any, event?: string) {
    await this.getClientIp();
    this.user = this._authService.getUser();
    if (this.user) {
      const modifiedEventData = {
        event: event? event : 'virtualEvent',
        TimeStamp: new Date().getTime(),
        Url: this.document.location.href,
        TipoDispositivo: this.typeDevice,
        PagePath: this.router.url,
        TipoUsuario: this.user.type,
        UserId:  Base64.encode(this.setUser()),
        UnidadDeNegocio: this.setSector(),
        Fingerprint: Base64.encode(String(this.clientInfo.getFingerprint())),
        ClientIP: Base64.encode(this.clientIp),
        clientID: Base64.encode(`${this.clientIp}-${this.clientInfo.getFingerprint()}-${this.typeDevice}`),
        ...eventData,
      };
      //console.table(modifiedEventData)
      this.gtmService.pushTag(modifiedEventData);
    }
  }

  async getClientIp() {
    try {
      const response = await fetch('https://api.ipify.org/?format=json');
      const data = await response.json();
      this.clientIp = data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  }

  loginEvent(): void {
    const eventData = {
      ProcesoGeneral: 'Login',
      CTA: 'Ingresar',
      Seccion: 'Login',
      SubSeccion: 'Boton login',
    };
    this.pushEvent(eventData);
  }

  clickCategoriesEvent() {
    const eventData = {
      ProcesoGeneralBusqueda: 'Búsqueda de productos',
      ProcesoEspecificoBusqueda: 'Por despliegue de categorías',
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Por despliegue de categorías',
      CTA: 'Categorias',
      Seccion: 'Header',
      SubSeccion: 'Menú categorias',
      Paso: 'Paso 1',
    };
    this.pushEvent(eventData);
  }

  clickCategoryEvent(category: string) {
    const eventData = {
      ProcesoGeneralBusqueda: 'Búsqueda de productos',
      ProcesoEspecificoBusqueda: 'Por despliegue de categorias',
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Por despliegue de categorias',
      CategoriaProducto: category,
      Seccion: 'Header',
      SubSeccion: 'Menu categorias',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  clickBrandEvent(brand: string) {
    const eventData = {
      ProcesoGeneralBusqueda: 'Búsqueda de productos',
      ProcesoEspecificoBusqueda: 'Por despliegue de categorias',
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Por despliegue de categorias',
      MarcaProducto: brand,
      Seccion: 'Header',
      SubSeccion: 'Menu categorias',
      Paso: 'Paso 3',
    };
    this.pushEvent(eventData);
  }

  viewProductsEvent(category: string, brand: string) {
    const eventData = {
      ProcesoGeneralBusqueda: 'Búsqueda de productos',
      ProcesoEspecificoBusqueda: 'Por despliegue de categorias',
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Por despliegue de categorias',
      Seccion: category,
      SubSeccion: brand,
      Paso: 'Paso 4',
    };
    this.pushEvent(eventData);
  }

  clickSearchBarEvent() {
    const eventData = {
      ProcesoGeneralBusqueda: 'Búsqueda de productos',
      ProcesoEspecificoBusqueda: 'Por barra de busqueda',
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Por barra de busqueda',
      Seccion: 'Header',
      SubSeccion: 'Barra de busqueda',
      Paso: 'Paso 1',
    };
    this.pushEvent(eventData);
  }

  clickSearchResultEvent(optionSelected: string) {
    const eventData = {
      ProcesoGeneralBusqueda: 'Búsqueda de productos',
      ProcesoEspecificoBusqueda: 'Por barra de busqueda',
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Por barra de busqueda',
      BusquedaSeleccionada: optionSelected,
      Seccion: 'Header',
      SubSeccion: 'Barra de busqueda',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  viewSearchResultEvent(brand: string, category: string, result: string) {
    const eventData = {
      ProcesoGeneralBusqueda: 'Búsqueda de productos',
      ProcesoEspecificoBusqueda: 'Por barra de busqueda',
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Por barra de busqueda',
      BusquedaSeleccionada: result,
      Seccion: category,
      SubSeccion: brand,
      Paso: 'Paso 3',
    };
    this.pushEvent(eventData);
  }

  clickUploadOrderEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Copiado y pegado de SKUs',
      CTA: 'Subir pedido',
      Seccion: 'Header',
      SubSeccion: 'Subir pedido',
      Paso: 'Paso 1',
    };
    this.pushEvent(eventData);
  }

  clickAddButtonEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Copiado y pegado de SKUs',
      CTA: 'Agregar',
      Seccion: 'Subir pedido',
      SubSeccion: 'Agregar SKU',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  clickContinueButtonEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Copiado y pegado de SKUs',
      CTA: 'Continuar',
      Seccion: 'Subir pedido',
      SubSeccion: 'PopUpSKU',
      Paso: 'Paso 3',
    };
    this.pushEvent(eventData);
  }

  viewVerificationOkEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Copiado y pegado de SKUs',
      TextoExito: 'Verificación exitosa',
      Seccion: 'Subir pedido SKU',
      SubSeccion: 'PopUpExitoso',
      Paso: 'Paso 4',
    };
    this.pushEvent(eventData);
  }

  viewWarningDisplaySkuEvent(warnings: string[]) {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Copiado y pegado de SKUs',
      TextoAdvertencia: warnings,
      Seccion: 'Subir pedido',
      SubSeccion: 'PopUpAdvertencia',
      Paso: 'Paso 4',
    };
    this.pushEvent(eventData);
  }

  viewErrorDisplaySkuEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Copiado y pegado de SKUs',
      TextoError: 'Algo salió mal!',
      Seccion: 'Subir pedido',
      SubSeccion: 'PopUpError',
      Paso: 'Paso 4',
    };
    this.pushEvent(eventData);
  }

  uploadExcelFileEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga de excel',
      CTA: 'Subir archivo',
      Seccion: 'Subir pedido',
      SubSeccion: 'Subir archivo de excel',
      Paso: 'Paso 1',
    };
    this.pushEvent(eventData);
  }

  viewSuccessfulVerificationEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga de excel',
      TextoExito: 'Archivo subido con éxito',
      Seccion: 'Subir pedido Excel',
      SubSeccion: 'PopUpExitoso',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  viewWarmomgDisplayEvent(warnings: string[]) {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga de excel',
      TextoAdvertencia: warnings,
      Seccion: 'Subir pedido',
      SubSeccion: 'PopUpAdvertencia',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  viewWarmomgDisplay2Event() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga de excel',
      TextoError: 'La columna CANTIDAD está vacía.',
      Seccion: 'Subir pedido',
      SubSeccion: 'PopUpError',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  clickPurchaseHistoryEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga por compras pasadas',
      CTA: 'Historial de compras',
      Seccion: 'Header',
      SubSeccion: 'Mi cuenta',
      Paso: 'Paso 1',
    };
    this.pushEvent(eventData);
  }

  viewPurchaseHistoryEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga por compras pasadas',
      Seccion: 'Historial de compras pasadas',
      SubSeccion: undefined,
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  clickViewOrderButtonEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga por compras pasadas',
      CTA: 'Ver pedido',
      Seccion: 'Historial de compras pasadas',
      SubSeccion: 'Tabla de historial',
      Paso: 'Paso 3',
    };
    this.pushEvent(eventData);
  }
  viewOrderDetailEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga por compras pasadas',
      Seccion: 'Detalle de pedido',
      SubSeccion: undefined,
      Paso: 'Paso 4',
    };
    this.pushEvent(eventData);
  }
  clickAddCartButtonEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga por compras pasadas',
      CTA: 'Añadir todo al carrito',
      Seccion: 'Detalle del pedido',
      SubSeccion: 'Número del pedido',
      Paso: 'Paso 5',
    };
    this.pushEvent(eventData);
  }

  viewPriceUpdateDisplayEvent() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga por compras pasadas',
      Seccion: 'Actualización de precios',
      SubSeccion: 'PopUpExitoso',
      Paso: 'Paso 6',
    };
    this.pushEvent(eventData);
  }

  viewPriceUpdateDisplay2Event() {
    const eventData = {
      ProcesoGeneralCompra: 'Compra de productos',
      ProcesoEspecificoCompra: 'Carga por compras pasadas',
      Seccion: 'Productos no disponibles',
      SubSeccion: 'PopUpAdvertencia',
      Paso: 'Paso 6',
    };
    this.pushEvent(eventData);
  }

  clickMyAccountEvent() {
    const eventData = {
      ProcesoGeneral: 'Interacción con el header',
      CTA: 'Mi cuenta',
      Seccion: 'Header',
      SubSeccion: 'Mi cuenta',
    };
    this.pushEvent(eventData);
  }

  clickMyCarEvent() {
    const eventData = {
      ProcesoGeneral: 'Gestion del carrito',
      CTA: 'Mi carrito',
      Seccion: 'Header',
      SubSeccion: 'Mi carrito',
    };
    this.pushEvent(eventData);
  }

  clickDownloadCatalogueEvent() {
    const eventData = {
      ProcesoGeneral: 'Gestión del carrito',
      CTA: 'Descargar catálogo',
      Seccion: 'Subir pedido',
      SubSeccion: 'Catálogo de productos con precio',
    };
    this.pushEvent(eventData);
  }

  clickCheckAvailabilityEvent() {
    const eventData = {
      ProcesoGeneral: 'Comprobar disponibilidad',
      CTA: 'Comprobar disponibilidad',
      Seccion: 'Confirmación de pedido',
      SubSeccion: 'Comprueba el stock',
      Paso: 'Paso 1',
    };
    this.pushEvent(eventData);
  }

  errorAvailabilityEvent() {
    const eventData = {
      ProcesoGeneral: 'Comprobar disponibilidad',
      TextoError: 'No pudimos comprobar la disponibilidad de tu pedido',
      Seccion: 'Confirmación de pedido',
      SubSeccion: 'PopUpError',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  withoutSuggestedAvailabilityEvent(nombre, cantidad, precio) {
    const eventData = {
      ProcesoGeneral: 'Comprobar disponibilidad',
      TextoAdvertencia: 'Estos productos no están disponibles y serán eliminados de tu carrito.',
      ProductoNoDisponible: nombre,
      CantidadProductoSolicitado: cantidad,
      PrecioTotalProductoSolicitado: precio,
      Seccion: 'Confirmación de pedido',
      SubSeccion: 'PopUpAdvertencia',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  withSuggestedAvailabilityEvent(nombre, cantidad, precio, nombreSugerido, cantidadSugerida, precioSugerido, estado) {
    const eventData = {
      ProcesoGeneral: 'Comprobar disponibilidad',
      TextoError: 'Hay productos sin suficiente stock, puedes reemplazarlos por los siguientes.',
      ProductoNoDisponible: nombre,
      CantidadProductoSolicitado: cantidad,
      PrecioTotalProductoSolicitado: precio,
      ProductoSugerido: nombreSugerido,
      CantidadProductoSugerido: cantidadSugerida,
      PrecioTotalProductoSugerido: precioSugerido,
      EstadoDeSugerencia: estado ? "Aceptado" : "No aceptado",
      Seccion: 'Confirmación de pedido',
      SubSeccion: 'PopUpAdvertenciaSugerido',
      Paso: 'Paso 2',
    };
    this.pushEvent(eventData);
  }

  acceptedSuggestedProductEvent(nombre, cantidad, precio) {
    const eventData = {
      ProcesoGeneral: 'Comprobar disponibilidad',
      TextoExito: '¡Listo! Se aplicarán los siguientes cambios',
      ProductoSugeridoAceptado: nombre,
      CantidadProductoSugeridoAceptado: cantidad,
      PrecioProductoSugeridoAceptado: precio,
      Seccion: 'Confirmación de pedido',
      SubSeccion: 'PopUpConfirmacionAceptado',
      Paso: 'Paso 3',
    };
    this.pushEvent(eventData);
  }

  deletedProductoWithoutStockEvent(nombre, cantidad, precio) {
    const eventData = {
      ProcesoGeneral: 'Comprobar disponibilidad',
      TextoExito: '¡Listo! Se aplicarán los siguientes cambios',
      ProductoEliminado: nombre,
      CantidadProductoEliminado: cantidad,
      PrecioProductoEliminado: precio,
      Seccion: 'Confirmación de pedido',
      SubSeccion: 'PopUpConfirmacionEliminado',
      Paso: 'Paso 3',
    };
    this.pushEvent(eventData);
  }

  checkAvailabilityEvent(before, after) {
    const eventData = {
      ProcesoGeneral: 'Comprobar disponibilidad',
      TextoExito: 'Entendido',
      MontoTotalAnterior: before,
      MontoTotalNuevo: after,
      Seccion: 'Confirmación de pedido',
      SubSeccion: 'PopUpConfirmacion',
      Paso: 'Paso 4',
    };
    this.pushEvent(eventData);
  }

  //event, TimeStamp, Url, TipoDispositivo, PagePath, TipoUsuario, UserId

  checkAvailabilityOkEvent() {
    const eventData = {
      procesoGeneral: "Comprobar disponibilidad",
      textoExito: "¡Genial! Todos los productos de tu carrito cuentan con stock.",
      seccion: "Confirmación de pedido",
      subSeccion: "PopUpCorrecto",
    };
    this.pushEvent(eventData);
  }

  /* updateStockEvent(before: number, after: number) {
    const eventData = {
      ProcesoGeneral: 'Comprobar disponibilidad',
      CantidadProductosEnCarrito: before,
      CantidadNuevaProductosEnCarrito: after,
      CTA: 'OK! ENTIENDO',
      Seccion: 'Confirmación de pedido',
      SubSeccion: 'PopUpAdvertencia',
    };
    this.pushEvent(eventData);
  } */

  clickAdvanceSalesEvent() {
    const eventData = {
      ProcesoGeneral: 'Avance de ventas',
      CTA: 'Avance de ventas',
      Seccion: 'Header',
      SubSeccion: 'Mi cuenta',
    };
    this.pushEvent(eventData);
  }

  viewAdvanceSalesEvent() {
    const eventData = {
      ProcesoGeneral: 'Avance de ventas',
      Seccion: 'Avance de ventas',
      SubSeccion: 'Descargas',
    };
    this.pushEvent(eventData);
  }

  downloadAdvanceSalesEvent(fileName: string) {
    const eventData = {
      ProcesoGeneral: 'Avance de ventas',
      NombreArchivo: fileName,
      Seccion: 'Avance de ventas',
      SubSeccion: 'Descargas',
    };
    this.pushEvent(eventData);
  }

  clickAwardsEvent() {
    const eventData = {
      ProcesoGeneral: 'Reconocimientos',
      CTA: 'Reconocimientos',
      Seccion: 'Header',
      SubSeccion: 'Mi cuenta',
    };
    this.pushEvent(eventData);
  }

  viewAwardsEvent() {
    const eventData = {
      ProcesoGeneral: 'Reconocimientos',
      Seccion: 'Reconocimientos',
      SubSeccion: 'Descargas',
    };
    this.pushEvent(eventData);
  }

  downloadAwardsEvent(fileName: string) {
    const eventData = {
      ProcesoGeneral: 'Reconocimientos',
      NombreArchivo: fileName,
      Seccion: 'Reconocimientos',
      SubSeccion: 'Descargas',
    };
    this.pushEvent(eventData);
  }

  clickTrackEvent() {
    const eventData = {
      ProcesoGeneral: 'Seguimientos de pedido',
      CTA: 'Seguimiento',
      Seccion: 'Detalle de pedido',
      SubSeccion: 'undefined',
    };
    this.pushEvent(eventData);
  }

  viewOrderTrackingEvent() {
    const eventData = {
      ProcesoGeneral: 'Seguimientos de pedido',
      Seccion: 'Seguimiento de pedido',
      SubSeccion: 'undefined',
    };
    this.pushEvent(eventData);
  }

  clickCheckingAccountEvent() {
    const eventData = {
      ProcesoGeneral: 'Cuenta corriente',
      CTA: 'Cuenta corriente',
      Seccion: 'Header',
      SubSeccion: 'Mi cuenta',
    };
    this.pushEvent(eventData);
  }

  viewCheckingAccountEvent() {
    const eventData = {
      ProcesoGeneral: 'Cuenta corriente',
      Seccion: 'Cuenta corriente',
      SubSeccion: 'undefined',
    };
    this.pushEvent(eventData);
  }

  clickElectronicReceiptEvent() {
    const eventData = {
      ProcesoGeneral: 'Cuenta corriente',
      CTA: 'Comprobante electrónico',
      Seccion: 'Header',
      SubSeccion: 'Mi cuenta',
    };
    this.pushEvent(eventData);
  }

  clickAlliesEvent() {
    const eventData = {
      ProcesoGeneral: 'Aliados',
      CTA: 'Aliados',
      Seccion: 'Header',
      SubSeccion: 'Mi cuenta',
    };
    this.pushEvent(eventData);
  }

  viewAlliesEvent() {
    const eventData = {
      ProcesoGeneral: 'Aliados',
      Seccion: 'Aliados',
      SubSeccion: 'undefined',
    };
    this.pushEvent(eventData);
  }

  viewClosingPageEvent() {
    const eventData = {
      ProcesoGeneral: 'Aliados',
      Seccion: 'Cierre',
      SubSeccion: 'undefined',
    };
    this.pushEvent(eventData);
  }

  clickPasswordChangeEvent() {
    const eventData = {
      ProcesoGeneral: 'Cambiar contraseña',
      CTA: 'Cambiar Contraseña',
      Seccion: 'Header',
      SubSeccion: 'Mi cuenta',
    };
    this.pushEvent(eventData);
  }

  // !Ecommerce Section

  addProductCartEvent(name:string, products?:any) {
    const eventData = {
      Intencion: 'Añadir al carrito',
      CTA: name,
      ecommerce: {
        items: products ? this.setCars(products) : this.setCarsStorage()
      },
    };
    this.pushEvent(eventData, "add_to_cart");
  }

  deleteProductCarEvent(name:string, products?:any){
    const eventData = {
      Intencion: 'Remover del carrito',
      CTA: name,
      ecommerce: {
        items: products ? this.setCars(products) : this.setCarsStorage()
      },
    };
    this.pushEvent(eventData, "remove_from_cart");
  }

  checkoutCarEvent(){
    const eventData = {
      Intencion: 'Iniciar checkout',
      ecommerce: {
        items: this.setCarsStorage()
      },
    };
    this.pushEvent(eventData, "begin_checkout");
  }

  //event, TimeStamp, Url, TipoDispositivo, PagePath, TipoUsuario
  purchaseCarEvent(transaction:any){
    const eventData = {
      Intencion: 'Compra',
      ecommerce: {
        transaction_id: transaction,
        value: this._totalPrice,
        currency: 'PEN',
        items: this._products ? this.setCars(this._products) : this.setCarsStorage()
      }
    };
    this.pushEvent(eventData, "purchase");
  }
}
