<section class="d-flex">
  <div class="content-dialog">
    <div class="button-x justify-content-end d-flex">
      <button mat-button class="button-x-style" (click)="closeDialog()">
        <span class="material-symbols-rounded fst-32"> close </span>
      </button>
    </div>
    <div class="ta-c img-password d-flex justify-content-center">
      <img class="ta-c" src="assets/image/login/🚨 close.svg" alt="alert" />
    </div>
    <div class="title-suport ta-c p-2">
      <p class="m-0 p-0">¿Seguro que quieres cerrar tu sesión?</p>
    </div>
    <div class="ta-c">
      <p class="p-desc-suport m-0 p-0 fts-14">
        Estás a punto de salir de nuestra plataforma, haz click en cancelar para
        mantener tu sesión activa.
      </p>
    </div>
    <div
      class="content-button-close d-flex justify-content-center vvw-100 mt-16"
    >
      <div
        class="d-flex justify-content-between content-button-close_button w-60 align-items-center"
      >
        <div class="ta-c hp-100 d-flex">
          <button
            mat-button
            class="font-bold btn-enter hp-100"
            (click)="closeSesion()"
            color="accent"
          >
            Cerrar sesión
          </button>
        </div>
        <div class="ta-c">
          <button
            mat-flat-button
            class="font-bold btn-enter ph-24"
            color="accent"
            (click)="closeDialog()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
    <div class="content-button-close-mobile">
      <div class="ta-c mt-16">
        <button
          mat-flat-button
          class="font-bold btn-enter"
          color="accent"
          (click)="closeDialog()"
        >
          Cancelar
        </button>
      </div>
      <div class="ta-c mt-16">
        <button
          mat-button
          class="font-bold btn-enter vw-100 hp-48"
          (click)="closeSesion()"
          color="accent"
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  </div>
</section>
