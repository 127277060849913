import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/authentication/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, tap, take, map, catchError, of, switchMap } from 'rxjs';
import * as authActions from './auth.actions';
import { LocalStorageService } from '@core/services/local-storage.service';
import { User } from '@core/interfaces/user.model';
import { GtmEventService } from '@core/analytics/gtm-events.service';

@Injectable()
export class authEffects {
  constructor(
    private actions$: Actions,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private _gtmEventService: GtmEventService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logIn),
      switchMap(({ data }) => {
        return this._authService
          .login({ username: data.username, password: data.password })
          .pipe(
            map((user: User) => {
              if (user) {
                const response = {
                  ...user,
                  type: data.type,
                };
                this._authService.setUser(response);
                return authActions.setUser({ user: response });
              }
              return authActions.logInError({ payload: '201' });
            }),
            catchError((error: Error) => {
              return of(authActions.logInError({ payload: error }));
            })
          );
      }),
      tap(() => {
        this._gtmEventService.loginEvent();
      })
    )
  );
}
