import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AppState } from '@core/store/app.reducer';
import { select, Store } from '@ngrx/store';
import * as clientsActions from '@core/store/selectClient/selectClient.actions';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { map, Subscription, take } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/services/authentication/auth.service';
import * as authActions from '@core/store/auth/auth.actions';
import * as shoppingActions from '@core/store/shoppingCar/shoppingCar.actions';
import { CarsState } from '@core/interfaces/cars';
import { UtilsService } from '@core/services/utils/utils.service';
import { DialogCloseSesionComponent } from '../modals/dialog-close-sesion/dialog-close-sesion.component';
import {
  Categories_request,
  categories_response,
} from '@core/interfaces/categories';
import { GtmEventService } from '@core/analytics/gtm-events.service';
import { CategoriesService } from '@core/services/categories/categories.service';
import { selectLoginUserInfo } from '@core/store/app.selectors';
import { NavigationInterceptorService } from '@core/services/orders/navigationInterceptor.service';
import { OrderChangeService } from '@core/services/orders/orderChange.service';
@Component({
  selector: 'app-header-seller',
  templateUrl: './header-seller.component.html',
  styleUrls: ['./header-seller.component.scss'],
  providers: [TitleCasePipe],
})
export class HeaderClientComponent implements OnInit, OnDestroy {
  dataConvertClients: any;
  dataConvertClientSelect: any;
  urlActive!: string;
  user!: any;
  @ViewChild('drawerProfile')
  sidenavProfile!: MatSidenav;
  @ViewChild('drawerCar') viewCar!: MatSidenav;
  selectSuscription!: Subscription;
  loginSuscription$!: Subscription;
  @Output() nameSelect = new EventEmitter<string>();
  @ViewChild(MatMenuTrigger) myMenu!: MatMenuTrigger;
  @ViewChild('drawer')
  sidenav!: MatSidenav;
  public carSubscription!: Subscription;
  carsState!: CarsState;
  car!: any;
  badge = 0;
  totalPriceAdd: any;
  actualRoute: any;
  lockCart: boolean = false;
  classCar: string = '';
  selectedClient: any;
  listCategories: Array<categories_response> = [];
  searchTextValue = '';
  searchResponse$: any;
  dataCurrentAccount: any;
  hidden = false;
  unitSelect!: Categories_request;
  initialDateSearch = [];
  @ViewChild('search_opacity_seller ')
  search_opacity_seller!: ElementRef;
  @ViewChild('drawerSeller') sidenavSeller!: MatSidenav;
  @ViewChild('drawerSellerMobile') sidenavSellerMobile!: MatSidenav;
  categoriesSuscription$!: Subscription;
  constructor(
    private _localStorageService: LocalStorageService,
    private router: Router,
    private store: Store<AppState>,
    private titlecasePipe: TitleCasePipe,
    private _authService: AuthService,
    public dialogCloseSesion: MatDialog,
    private ref: ChangeDetectorRef,
    private renderer: Renderer2,
    private _gtmEventService: GtmEventService,
    private _categoriesService: CategoriesService,
    private orderChangeService: OrderChangeService
  ) {}
  options = [
    { label: 'Inicio', link: '/main/inicio', icon: 'home' },
    { label: 'Subir pedido', link: '/main/subir-pedido', icon: 'downloads' },
    {
      label: 'Cuenta corriente',
      link: '/main/cuenta-corriente',
      icon: 'account',
    },
  ];
  optionsResponsive = [
    { show: true, label: 'Inicio', link: '/main/inicio', icon: 'home' },
    {
      show: true,
      label: 'Datos del cliente',
      link: '/main/inicio',
      icon: 'storefront',
    },
    {
      show: true,
      label: 'Subir pedido',
      link: '/main/subir-pedido',
      icon: 'downloads',
    },
    {
      show: true,
      label: 'Avance de ventas',
      link: '/main/avances-ventas',
      icon: 'advance',
    },
    {
      show: true,
      label: 'Reconocimientos',
      link: '/main/reconocimientos',
      icon: 'recognitions',
    },
    {
      show: true,
      label: 'Cuenta corriente',
      link: '/main/cuenta-corriente',
      icon: 'account',
    },
    {
      show: true,
      label: 'Historial de compras',
      link: '/main/historial-compras',
      icon: 'history',
    },
    { show: true, label: 'Facturas', link: '', icon: 'bills' },
    { show: true, label: 'Aliados', link: '/main/aliados', icon: 'allies' },
  ];

  ngOnInit() {
    this.initConvertData();
    this.initStore();
    this.initialData();
  }

  initConvertData() {
    const client = this._localStorageService.getItem('clients');

    this.unitSelect = this._localStorageService.getItem('clientSelect');
    this.dataConvertClients = client.map((data: any) => {
      return {
        ...data,
      };
    });
  }
  initStore() {
    this.loginSuscription$ = this.store
      .select('auth')
      .subscribe((loginUser) => {
        if (loginUser) {
          this.user = loginUser?.user;
        }
      });
    this.selectSuscription = this.store
      .select('clientSelect')
      .subscribe((clientSelect: any) => {
        this.dataConvertClientSelect = this.dataConvertClients.find(
          (item) => item.code == clientSelect.client.code
        );
        this.countBadgeCar();
        this.ref.detectChanges();
      });
  }
  initialData() {
    this.getCategoriesList(this.unitSelect);
  }
  getCategoriesList(data) {
    this.unsubscribeFromStore();
    this.categoriesSuscription$ = this.store
      .pipe(select(selectLoginUserInfo))
      .subscribe((value) => {
        this.unitSelect = value;
        this._categoriesService
          .getCategories(value)
          .pipe(take(1))
          .subscribe({
            next: (res: any) => {
              this.listCategories = res;
            },
            error: (err: Error) => {},
          });
      });
  }

  ngAfterContentInit() {
    this.urlActive = this.router.url;
  }
  changeValue(clientSelect: any) {
    // if (this.router.url.includes('/main/historial-compras/detalle-historial')) {
    //   this.router.navigate(['/main/historial-compras']);
    // }
    // if (this.router.url.includes('/main/checkout')) {
    //   this.router.navigate(['/main/inicio']);
    // }
    this.router.navigate(['/main/inicio']);

    let client = clientSelect.value;
    this.actualRoute = this.router.url;
    this.store.dispatch(
      clientsActions.setClients({ clients: client ? client : '' })
    );
  }

  disabledClient(){
    return this.orderChangeService.getButtonState();
  }
  clientSelect() {
    this.myMenu.closeMenu();
  }
  openMenu() {
    this.sidenav.toggle();
  }

  openProfile() {
    this.sidenavProfile.toggle();
  }
  closeProfile(event: string) {
    this.sidenavProfile.close();

    if (event === 'logout') {
      setTimeout(() => {
        this.openModalCloseSesion();
      }, 350);
    }
  }
  transformToTitleCase(value: any): any {
    return this.titlecasePipe.transform(value);
  }
  openModalCloseSesion() {
    const dialogRef = this.dialogCloseSesion.open(DialogCloseSesionComponent, {
      width: '31rem',
      data: {},
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data?.status) {
        this.store.dispatch(authActions.unsetUser());
        this.store.dispatch(shoppingActions.unsetCar());
        this.store.dispatch(clientsActions.unsetClients());
        this._authService.logout();
      }
    });
  }

  openCar() {
    if (this.badge !== 0 && this.router.url !== '/main/confirmacion') {
      if (!this.lockCart) {
        this.router.navigate(['/main/confirmacion']);
      }
    }
  }

  closeCar() {
    this.viewCar.close();
  }

  countBadgeCar() {
    this.carSubscription = this.store
      .select('carsState')
      .subscribe((carsState) => {
        this.carsState = carsState;

        if (carsState) {
          const tempCar = carsState.cars.find(
            (car) =>
              car.unitBussines?.toLowerCase() ==
              this.dataConvertClientSelect.name.toLowerCase()
          );
          this.car = tempCar ? tempCar : undefined;
          this.badge = this.car?.products ? this.car.products.length : 0;
          this.totalPriceSum();
        }
      });
  }

  //restricciones del input
  countProduct(e: any) {
    const replace = e.e.replace(/[^0-9-.]/g, '');
    let countFormat = parseFloat(replace);
    let tempCount: any = 0;
    if (isNaN(countFormat) || countFormat == 0) {
      tempCount = '';
      countFormat = 0;
    } else if (countFormat < 0) {
      countFormat = countFormat * -1;
      tempCount = UtilsService.formatNumber(countFormat);
    } else if (countFormat > 9999) {
      tempCount = '9,999';
      countFormat = 9999;
    } else {
      tempCount = UtilsService.formatNumber(countFormat);
    }
    this.searchAndModifyProduct(tempCount, countFormat, e.p, parseInt(e.e));
    this.ref.detectChanges();
  }
  searchAndModifyProduct(value: any, inputValue: any, p: any, count: number) {
    const cars = this.carsState.cars.map((car) => {
      const products = car.products.map((product) => {
        let totalPrice = product.totalPrice;
        if (count >= p.minimumPurchase && p.minimumPurchase != 0) {
          if (product.sku == p.sku) {
            totalPrice = UtilsService.formatTotalNumber(
              (inputValue * parseFloat(p.wholesalePriceDiscountVAT)).toFixed(2)
            );
            return {
              ...product,
              count: value,
              totalPrice: totalPrice,
            };
          } else {
            return { ...product };
          }
        } else {
          if (product.sku == p.sku) {
            totalPrice = UtilsService.formatTotalNumber(
              (inputValue * parseFloat(p.wholesalerPriceIva)).toFixed(2)
            );
            return {
              ...product,
              count: value,
              totalPrice: totalPrice,
            };
          } else {
            return { ...product };
          }
        }
      });
      return {
        ...car,
        products: products,
      };
    });
    this.totalPriceSum();
    this.store.dispatch(shoppingActions.setCar({ cars }));
  }
  totalPriceSum() {
    if (this.car && this.car.products) {
      const totalPriceAdd = this.car.products
        .reduce((acc: any, product: any) => {
          return (
            acc +
            parseFloat(String(product.totalPrice).replace(/[^0-9-.]/g, ''))
          );
        }, 0)
        .toFixed(2);
      this.totalPriceAdd = UtilsService.formatTotalNumber(totalPriceAdd);
    } else {
      this.totalPriceAdd = '0';
    }
  }
  deleteProduct(p: any) {
    const cars = this.carsState.cars.map((car: any) => {
      if (car.unitBussines === this.dataConvertClientSelect.name) {
        const products = car.products.filter((product) => {
          return product.sku != p.sku;
        });
        return {
          ...car,
          products: products,
        };
      } else {
        return car;
      }
    });
    this.store.dispatch(shoppingActions.setCar({ cars }));
    if (this.badge === 0) {
      this.closeCar();
    }
  }
  deleteCar() {
    const cars = this.carsState.cars.map((car: any) => {
      if (car.unitBussines === this.dataConvertClientSelect.name) {
        return {
          ...car,
          products: [],
        };
      } else {
        return {
          ...car,
        };
      }
    });

    this.store.dispatch(shoppingActions.setCar({ cars }));
  }
  categoriesEmit(a, value) {
    if (value === 'mobile') {
      this.sidenavSellerMobile.toggle();
    } else {
      this.sidenavSeller.toggle();
    }

    if (a.type == 'category') {
      this.router.navigate(['/tienda/busqueda'], {
        queryParams: {
          categoryCode: a.category.categoryCode,
          categoryName: a.category.categoryName,
        },
      });
    } else {
      this.router.navigate(['/tienda/busqueda'], {
        queryParams: {
          categoryCode: a.category.categoryCode,
          categoryName: a.category.categoryName,
          brandCode: a.brandCode,
        },
      });
    }
  }
  clickSearchBar() {
    this._gtmEventService.clickSearchBarEvent();
  }
  getList(text) {
    let data: {
      code: string;
      sector: string;
      zone: string;
      schema: string;
      segment: string;
      description?: string; // Agrega la propiedad 'description' como opcional
    } = {
      code: this.unitSelect.code,
      sector: this.unitSelect.sector,
      zone: this.unitSelect.zone,
      schema: this.unitSelect.schema,
      segment: this.unitSelect.segment,
    };

    data.description = text;
    if (text && text.length >= 3 && text.length <= 50) {
      this.searchResponse$ = this._categoriesService
        .getSearchCategories(data)
        .pipe(
          take(1),
          // debounceTime(500),
          map((res) => {
            this.saveInitialDate(res);
            if (res && res.length > 0) {
              this.addOpacityAutocomplete();
            } else {
              this.removeOpacityAutocomplete();
            }
            return res;
          })
        );
    } else {
      this.removeOpacityAutocomplete();
      this.searchResponse$ = null;
    }
  }
  mouseUp(event) {
    //vaciar data
    this.removeOpacityAutocomplete();
    if (event && event.length < 1) {
      this.searchResponse$ = null;
    }
  }
  mouseClickInput(text) {
    if (
      text &&
      this.initialDateSearch &&
      text.length >= 3 &&
      this.initialDateSearch.length > 0
    ) {
      this.addOpacityAutocomplete();
    } else {
      this.removeOpacityAutocomplete();
    }
  }
  clickUploadOrder() {
    this._gtmEventService.clickUploadOrderEvent();
  }
  selectConfirmation() {
    this.redirect();
  }
  clickMyAccount() {
    this._gtmEventService.clickMyAccountEvent();
  }
  searchEngineSelectedProduct(value, description) {
    this._gtmEventService.clickSearchResultEvent(description);
    this.initialDateSearch.map((item: any) => {
      item.select = item.sku === value ? true : false;
    });
    this._localStorageService.setItem(
      'listProductSearch',
      this.initialDateSearch
    );
    this.router.navigate(['/tienda/busqueda'], {
      queryParams: { productos: value },
    });
    this.removeOpacityAutocomplete();
  }
  saveInitialDate(response) {
    this.initialDateSearch = response;
  }
  openCategories(value: string) {
    if (value === 'mobile') {
      this.sidenavSellerMobile.toggle();
    } else {
      this.sidenavSeller.toggle();
    }

    this._gtmEventService.clickCategoriesEvent();
  }
  addOpacityAutocomplete() {
    this.renderer.addClass(
      this.search_opacity_seller.nativeElement,
      'class-opacity'
    );
    this.openedStart();
  }
  unsubscribeFromStore() {
    if (this.categoriesSuscription$) {
      this.categoriesSuscription$.unsubscribe();
    }
  }
  removeOpacityAutocomplete() {
    this.renderer.removeClass(
      this.search_opacity_seller.nativeElement,
      'class-opacity'
    );
    this.closedStart();
  }
  redirect() {
    this.router.navigate(['/tienda/mi-carrito']);
    this.closedStart();
  }
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
  ngOnDestroy(): void {
    this.loginSuscription$.unsubscribe();
    this.selectSuscription.unsubscribe();
    this.unsubscribeFromStore();
  }
}
