import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
  active = false;
  text = 'Hola *#AtencionClientes* quisiera hacer una consulta:'
  @Input() config: any = 0;
  constructor() { }

  ngOnInit() {
  }

  whatsapp() {
    this.active = true;
  }

  openWhatsapp(): void {
    this.active = false;
    window.open(`https://web.whatsapp.com/send?phone=51979297916&text=${this.text}`, "_blank");
  }

  close() {
    this.active = false;
  }
}
