<section class="py-3 container-show-car">
  <div class="container-header-car px-3">
    <div class="d-flex text-center align-items-center justify-content-between">
      <div class="row align-items-center">
        <mat-icon
          ><img
            src="assets/image/selected-client-list/car.svg"
            alt=""
            class="m-0 p-0"
        /></mat-icon>
        <div class="title-car">Carrito de compras</div>
      </div>
      <div>
        <input
          #hiddenInput
          type="text"
          class="hidden-input"
          (focus)="onHiddenInputFocus()"
        />
        <button
          mat-icon-button
          matTooltip="Basic"
          aria-label="close button"
          (click)="closeX()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="my-3">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div *ngIf="cart?.products && cart?.products.length > 0; else datanull">
    <div class="d-flex justify-content-between pb-3 align-items-center px-3">
      <div class="product-count title-car">
        Productos ({{ cart && cart.products ? cart.products.length : 0 }})
      </div>
      <div>
        <button
          mat-button
          color="accent"
          class="btn-enter p-0"
          [matMenuTriggerFor]="matMenuDelete"
        >
          Vaciar carrito
        </button>
      </div>
    </div>
    <div class="container-body-car px-3">
      <div
        class="container-card-car row mb-2 flex-column"
        *ngFor="let product of cart?.products; let i = index"
      >
        <div class="first-description">
          <div class="d-flex justify-content-between h-24">
            <div class="title-card pb-2">Fert {{ product.sku }}</div>
            <div>
              <mat-icon class="icon-card" (click)="deleteCardProduct(product)"
                >delete_outline</mat-icon
              >
            </div>
          </div>
          <div class="">
            <div class="description-card bold">
              {{ product.description }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="card-input">
            <div class="add">
              <div class="add-input">
                <input
                  #referenceInput
                  type="text"
                  spinners="false"
                  class="input-amount"
                  [ngModel]="product.count"
                  (blur)="evaluateValue(product, i)"
                  (ngModelChange)="changeCount($event, product, i)"
                  pattern="^[1-9]\d{0,5}$"
                  onkeydown="if (event.key.length === 1 && !/\d/.test(event.key)) event.preventDefault();"
                />
              </div>
            </div>
          </div>
          <div class="ml-12">
            <div class="subTitle-card pb-2">Unidad</div>
            <div
              class="subTitle-card"
              *ngIf="validatePrice(product.count,product?.minimumPurchase)"
            >
              S/ {{ product.wholesalePriceDiscountVAT }}
            </div>
            <div
              class="subTitle-card"
              *ngIf="!validatePrice(product.count,product?.minimumPurchase)"
            >
              S/ {{ product.wholesalerPriceIva }}
            </div>
          </div>
          <div class="ml-24">
            <div class="subTitle-card pb-2">Total Inc. IVA</div>
            <div class="description-card bold">S/ {{ product.totalPrice }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #datanull>
    <div
      class="d-flex container-body-car-null align-items-center justify-content-center"
    >
      <app-null-car (redirectPrice)="closeX()"></app-null-car>
    </div>
  </ng-template>
</section>
<div class="container-button-review-purchase p-20">
  <div class="d-flex align-items-center justify-content-between pb-2">
    <div class="total-text">Total Inc. IVA</div>
    <div
      [ngClass]="
        totalPriceAdd != 0 ? 'total-description bold' : 'total-description-disabled bold'
      "
    >
      S/ {{ totalPriceAdd != 0 ? totalPriceAdd : 0 }}
    </div>
  </div>
  <div>
    <button mat-flat-button class="btn-confirm" (click)="clickConfirmOrder()">Confirmar pedido</button>
  </div>
</div>
<mat-menu #matMenuDelete="matMenu">
  <div class="my-3 container-matmenu-delete">
    <div class="d-flex pb-3">
      <div class="icon-delete" emoji="⚠️">⚠️</div>
      <div class="description-text-delete">
        ¿Estás seguro de eliminar todos los productos de tu carrito?
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div>
        <button mat-raised-button color="accent" (click)="closeMenu()">
          No
        </button>
      </div>
      <div class="ml-2">
        <button mat-stroked-button color="accent" (click)="cleanAll()">
          Si
        </button>
      </div>
    </div>
  </div>
</mat-menu>
