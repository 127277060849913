<!-- Contenido principal -->
<div class="container-detail-client">
  <!-- Contenedor cliente y período -->
  <div class="container-client container-border-line p-24">
    <!-- Imagen del cliente -->
    <div
      class="container-border-line vw-100 content-img-client show-desktop-img_client"
    >
      <div class="vw-100 align-items-center">
        <ngx-skeleton-loader count="1" appearance="line" class="h-custom">
        </ngx-skeleton-loader>
      </div>
    </div>
    <!-- Detalles del cliente -->
    <div class="container-border-line mt-12 mb-12 p-12">
      <div class="d-flex">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader count="1" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <!-- Período -->
    <div class="container-border-line mt-12 mb-12 p-12">
      <div class="d-flex">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader count="1" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>

  <!-- Contenedor de información general -->
  <div class="container-border-line vw-100 p-24">
    <div class="d-flex justify-content-between align-items-center info-general">
      <div class="skeleton-size">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader count="1" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
      <div class="text-igv skeleton-size">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader count="1" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <!-- Línea divisoria -->
    <hr class="dividing-line-solid my-3 show-desktop" />
    <!-- Tabla de información general -->
    <div class="container-border-line">
      <div class="d-flex justify-content-between p-16 header-table-awards">
        <div class="fontw-600 skeleton-size">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader count="1" appearance="line">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="skeleton-size">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader count="1" appearance="line">
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <hr class="dividing-line-solid m-0" />

      <!-- Filas de la tabla generadas dinámicamente -->
      <ng-container *ngFor="let item of itemsSkeleton">
        <div class="d-flex justify-content-between pv-12 ph-16">
          <div class="skeleton-size">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader count="1" appearance="line">
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="skeleton-size">
            <div class="vw-100 align-items-center">
              <ngx-skeleton-loader count="1" appearance="line">
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <hr class="dividing-line-solid m-0" />
      </ng-container>

      <div
        class="d-flex justify-content-between p-16 total-profit align-items-center"
      >
        <div class="skeleton-size">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader count="1" appearance="line">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="skeleton-size">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader count="1" appearance="line">
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
