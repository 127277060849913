<div class="container-order">
  <div class="container-modal p-24">
    <div
      class="ta-c d-flex justify-content-center align-items-center img-container fts-48"
    >
      <div>⚠️</div>
    </div>
    <div class="message">
      <h1 class="fts-20 font-bold">{{ modals[dataRecieved.status].title }}</h1>
      <div>
        <p [innerHTML]="modals[dataRecieved.status].description"></p>
      </div>
    </div>
    <div class="aditional-info">
      <div class="d-flex container-header-cards">
        <div
          class="col-6 d-flex align-items-center pl-24 pr-24 fts-14 line-height-16 product"
        >
          Productos sin stock ({{ dataRecieved?.skuError?.length }})
        </div>
        <div
          class="d-flex align-items-center fts-14 line-height-16 justify-content-center amout"
        >
          Cantidad
        </div>
        <div
          class="d-flex align-items-center pl-16 pr-16 fts-14 justify-content-center line-height-16 value-igv"
        >
          Valor total <br />(con IGV)
        </div>
        <div class="d-flex align-items-center fts-14 line-height-16 free"></div>
      </div>
      <div class="container-body-info">
        <div
          class="container-with-suggested-product mt-16"
          *ngFor="let product of dataSourceSuggestedProducts; let i = index"
        >
          <div class="d-flex">
            <div class="suggested-product-style">
              <div class="d-flex">
                <div class="container-description pv-16 ph-24">
                  <div class="status out-stock fts-12 line-height-12">
                    Sin stock suficiente
                  </div>
                  <div class="code-text fts-14 line-height-16 pv-8">
                    Código: {{ product.sku }}
                  </div>
                  <div class="description-text fts-14 line-height-16">
                    {{ product.description }}
                  </div>
                </div>
                <div
                  class="amout d-flex justify-content-center align-items-center text-amout"
                >
                  {{ product.count }}
                </div>
                <div
                  class="value-igv d-flex justify-content-center align-items-center text-count"
                >
                  S/ {{ product.totalPrice }}
                </div>
              </div>
              <div class="dl-divider"></div>
              <div class="align-items-center d-flex">
                <div class="container-description pv-16 ph-24">
                  <div class="status order-suggested fts-12 line-height-12">
                    Producto sugerido
                  </div>
                  <div class="fts-14 code-text-defaul line-height-16 pv-8">
                    Código: {{ product.suggestedProducts[0].sku }}
                  </div>
                  <div class="fts-14 font-bold code-text-defaul line-height-16">
                    {{ product.suggestedProducts[0].description }}
                  </div>
                </div>
                <div
                  class="amout d-flex justify-content-center align-items-center"
                >
                  <div class="size-18 ta-c d-flex justify-content-center w-100">
                    <div>
                      <input
                        [ngClass]="{
                          'error-border':
                            stringToNumber(
                              product.suggestedProducts[0]?.count
                            ) > stringToNumber(product.count)
                        }"
                        #referenceInput
                        type="text"
                        class="ta-c h-40 p-0 input-car fts-14 text-amout line-height-16"
                        appFormatInput
                        [ngModel]="product.suggestedProducts[0]?.count"
                        (blur)="evaluateValue(product, i)"
                        (ngModelChange)="changeCount($event, product, i)"
                      />
                      <div
                        *ngIf="
                          stringToNumber(product.suggestedProducts[0]?.count) >
                          stringToNumber(product.count)
                        "
                        class="error-message"
                      >
                        Máx. {{ product.count }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="value-igv d-flex justify-content-center align-items-center fts-14 line-height-16 font-bold code-text-defaul"
                >
                  S/
                  {{ product.suggestedProducts[0].totalPrice }}
                </div>
              </div>
            </div>
            <div
              class="free check-suggested d-flex justify-content-center align-items-center"
            >
              <div class="ta-c">
                <mat-checkbox
                  class="custom-checkbox"
                  [(ngModel)]="product.acceptSuggestion"
                  (ngModelChange)="updateAcceptSuggestion($event, i)"
                  [disabled]="
                    stringToNumber(product.suggestedProducts[0]?.count) >
                    stringToNumber(product.count)
                  "
                ></mat-checkbox>
                <div class="fts-14 line-height-16 mt-8">
                  Aceptar <br />
                  sugerencia
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container-without-suggested-product mt-16"
          *ngIf="noDataSourceSuggestedProducts.length > 0"
        >
          <ng-container
            *ngFor="let value of noDataSourceSuggestedProducts; let last = last"
          >
            <div class="d-flex container-out-stock">
              <div class="product pv-16 ph-24">
                <div class="status out-stock fts-12 line-height-12">
                  Sin stock suficiente
                </div>
                <div class="code-text fts-14 line-height-16 pv-8">
                  Código: {{ value.sku }}
                </div>
                <div class="description-text fts-14 line-height-16">
                  {{ value.description }}
                </div>
              </div>
              <div
                class="amout d-flex justify-content-center align-items-center fts-14 line-height-16 text-amout"
              >
                {{ value.count }}
              </div>
              <div
                class="value-igv d-flex justify-content-center align-items-center fts-14 line-height-16 text-count text-nowrap"
              >
                S/ {{ value.totalPrice }}
              </div>
              <div
                class="d-flex align-items-center fts-14 free line-height-16"
              ></div>
            </div>
            <div class="dl-divider" *ngIf="!last"></div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="show-mobile">
      <div class="container-body-info">
        <div
          class="container-with-suggested-product mt-16"
          *ngFor="
            let productMobile of dataSourceSuggestedProducts;
            let i = index
          "
        >
          <div class="">
            <div class="suggested-product-style">
              <div class="container-description ph-24 pt-24">
                <div class="status out-stock fts-12 line-height-12">
                  Sin stock suficiente
                </div>
                <div class="code-text fts-14 line-height-16 pv-8">
                  Código: {{ productMobile.sku }}
                </div>
                <div class="description-text fts-14 line-height-16">
                  {{ productMobile.description }}
                </div>
                <div class="dl-divider mv-16"></div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center ph-24 pb-24"
              >
                <div class="align-items-center text-amout">
                  <div class="mb-2">Cantidad:</div>
                  <span class="font-bold">{{ productMobile.count }}</span>
                </div>
                <div class="align-items-center text-count">
                  <div class="mb-2">Valor total (con IGV):</div>
                  <span class="font-bold">
                    S/ {{ productMobile?.totalPrice }}</span
                  >
                </div>
              </div>
              <div class="dl-divider"></div>
              <div class="align-items-center">
                <div class="container-description ph-16 pt-24">
                  <div class="status order-suggested fts-12 line-height-12">
                    Producto sugerido
                  </div>
                  <div class="fts-14 code-text-defaul line-height-16 pv-8">
                    Código: {{ productMobile.suggestedProducts[0].sku }}
                  </div>
                  <div class="fts-14 font-bold code-text-defaul line-height-16">
                    FID.DON VITTORIO SPAGH. EXP.400G 24BOL
                  </div>
                  <div class="dl-divider mv-16"></div>
                </div>

                <div
                  class="d-flex justify-content-between align-items-center ph-16 pb-24"
                >
                  <div class="size-18 ta-c w-100">
                    <div class="mb-2">Cantidad:</div>
                    <div>
                      <input
                        [ngClass]="{
                          'error-border':
                            stringToNumber(
                              productMobile.suggestedProducts[0]?.count
                            ) > stringToNumber(productMobile.count)
                        }"
                        #referenceInput
                        type="text"
                        class="ta-c h-40 p-0 input-car fts-14 text-amout line-height-16"
                        appFormatInput
                        [ngModel]="productMobile.suggestedProducts[0].count"
                        (blur)="evaluateValue(productMobile, i)"
                        (ngModelChange)="changeCount($event, productMobile, i)"
                      />
                      <div
                        *ngIf="
                          stringToNumber(
                            productMobile.suggestedProducts[0].count
                          ) > stringToNumber(productMobile.count)
                        "
                        class="error-message"
                      >
                        Máx. {{ productMobile.count }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="align-items-center fts-14 line-height-16 code-text-defaul"
                  >
                    <div class="mb-16">Valor total (con IGV):</div>
                    <span class="font-bold"
                      >S/
                      {{ productMobile.suggestedProducts[0].totalPrice }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="dl-divider"></div>
            </div>
            <div
              class="check-suggested d-flex justify-content-center align-items-center"
            >
              <div
                class="ta-c d-flex justify-content-between align-items-center p-16"
              >
                <div class="fts-14 line-height-16 mr-16 font-bold">
                  Aceptar sugerencia
                </div>
                <mat-checkbox
                  class="custom-checkbox"
                  [(ngModel)]="productMobile.acceptSuggestion"
                  (ngModelChange)="updateAcceptSuggestion($event, i)"
                  [disabled]="
                    stringToNumber(productMobile.suggestedProducts[0].count) >
                    stringToNumber(productMobile.count)
                  "
                ></mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container-without-suggested-product mt-16"
          *ngIf="noDataSourceSuggestedProducts.length > 0"
        >
          <ng-container
            *ngFor="
              let valuemobile of noDataSourceSuggestedProducts;
              let last = last
            "
          >
            <div class="container-out-stock pv-16 ph-24">
              <div class="product w-100">
                <div class="status out-stock fts-12 line-height-12">
                  Sin stock suficiente
                </div>
                <div class="code-text fts-14 line-height-16 pv-8">
                  Código: {{ valuemobile.sku }}
                </div>
                <div class="description-text fts-14 line-height-16">
                  {{ valuemobile.description }}
                </div>
                <div class="dl-divider mv-16"></div>
              </div>

              <div
                class="d-flex justify-content-between align-items-center count-total"
              >
                <div
                  class="amout align-items-center fts-14 line-height-16 text-amout"
                >
                  <div class="mb-2">Cantidad:</div>
                  <span class="font-bold">{{ valuemobile.count }}</span>
                </div>
                <div
                  class="align-items-center fts-14 line-height-16 text-count"
                >
                  <div class="mb-2">Valor total (con IGV):</div>

                  <span class="font-bold"
                    >S/ {{ valuemobile?.totalPrice }}</span
                  >
                </div>
              </div>
              <div
                class="d-flex align-items-center fts-14 free line-height-16"
              ></div>
            </div>
            <div class="dl-divider" *ngIf="!last"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons" *ngIf="modals[dataRecieved.status].actions">
    <button
      *ngFor="let action of modals[dataRecieved.status].actions"
      class="mat-button fts-16"
      [ngClass]="isCancelButton(action.name) ? action.class : (shouldDisableButton ? 'disabled-button' : action.class)"
      (click)="action.event()"
      [disabled]="isCancelButton(action.name) ? false : shouldDisableButton"
      >
      {{ action.name }}
    </button>
  </div>
</div>
