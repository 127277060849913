<div class="container-modal">
  <div class="modal-title">
    <p>
      Pega aquí tus productos:  <img (click)="pasteEvent()" src="/assets/image/icon-file-xls.svg" alt="">
    </p>
  </div>
  <div class="modal-body">
    <div class="table-content">
      <div class="table-header">
        <div class="th th1">
          <p>
            Item
          </p>
        </div>
        <div class="th th2">
          <p>
            SKU
          </p>
        </div>
        <div class="th th3">
          <p>
            Cantidad
          </p>
        </div>
      </div>
      <div class="table-body" [formGroup]="dataForm">
        <ng-container formArrayName="products">
          <ng-container  *ngFor="let productForm of products.controls; let i = index">
              <div class="tb-row"[formGroup]="productForm">
                <div class="tb tb1">
                  <p>
                    {{i + 1}}
                  </p>
                </div>
                <div class="tb tb2">
                  <input matInput
                  formControlName="sku">
                </div>
                <div class="tb tb3">
                  <input matInput #referenceInput (blur)="deactiveFlag()" (click)="activeFlag()"
                  formControlName="quantity">
                </div>
              </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="fbx-button btn-green" (click)="sendData()" >
      Continuar
    </button>
    <button class="fbx-button btn-gray" (click)="exit()">
      Cancelar
    </button>
  </div>
</div>
