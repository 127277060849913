import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Routines } from '@core/interfaces/selected-client';
import * as moment from 'moment';
import { RoutineModalComponent } from '../modals/routine-modal/routine-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {
  MessageRoutinesError,
  MessageRoutinesNull,
} from 'src/assets/data/errors-message';
import { Router } from '@angular/router';
@Component({
  selector: 'app-card-routines',
  templateUrl: './card-routines.component.html',
  styleUrls: ['./card-routines.component.scss'],
})
export class CardRoutinesComponent implements OnInit {
  @Input() routinesMessage!: string;
  @Input() suggestionsDescription!: string;
  @Input() routinesListLoading!: boolean;
  @Input() routinesMessageError!: boolean;
  @Input() headerShow!: boolean;
  @Input() routinesList: Routines | null = null;
  @Output() errorRoutines = new EventEmitter<any>();
  @Output() detailRoutines = new EventEmitter<any>();
  repeatArray = Array(4);
  messageError = [MessageRoutinesError];
  messageClientNull = [MessageRoutinesNull];
  @Input() selectedRoutine: any = null; // Propiedad para almacenar la rutina seleccionada
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectarMovil();
  }
  esMovil: boolean = false;
  constructor(private dialog: MatDialog,
    private router: Router,
  ) { 
    
  }
  ngOnInit() {
    this.detectarMovil();
  }

  formatDate(emitDate: string): string {
    
    moment.locale('es');
    const date = moment(emitDate, 'DD/MM/YYYY');
    let formattedDate = date.format('ddd - DD/MM/YYYY').toUpperCase();

    // Remove the period from the day abbreviation if it exists
    formattedDate = formattedDate.replace(/\./g, '');

    return formattedDate;
  }

  routineRedirect(routine) {
    this.openModal(routine, this.routinesList);
  }
  
  initDataRoutines(){
    this.errorRoutines.emit();
  }
  openModal(routine, routines) {
    this.dialog.open(RoutineModalComponent, {
      data: { 
        routine: routine,
        routines: routines 
      }
    });
  } 
  navigateToDetails() {
    
    this.router.navigate(['/detalle-rutinas'], {
      state: { data: this.routinesList }
    });
    
  }
  routineRedirectDetail(routine){
    this.selectedRoutine = routine;
    this.detailRoutines.emit(routine); 
  } 
  
  detectarMovil() {
    this.esMovil = window.innerWidth < 767;
  }
}
