import {
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Location } from '@angular/common';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-order-modal',
  templateUrl: './manage-order-modal.component.html',
  styleUrls: ['./manage-order-modal.component.scss']
})
export class ManageOrderModalComponent implements OnInit {
  dataRecieved: any;
  modals = [
  
    {
      img: '⚠️',
      title: '¿Estás seguro que deseas cambiar la Condición de expedición?',
      description:
        'Los precios pueden cambiar y tu pedido se simulará<br class="show-desktop-tablet"> automáticamente.',
      actions: [
        {
          name: 'Si, cambiar',
          class: 'btn-back-new',
          event: () => this.dialogRef.close(true)  // Cambiado a true
        },
        {
          name: 'No, regresar',
          class: 'btn-red-new',
          event: () => this.dialogRef.close(false) // Cambiado a false
        },
      ],
    },
    {
      img: '⚠️',
      title: '¿Estás seguro que deseas cambiar el Método de pago?',
      description:
        'Los precios pueden cambiar y tu pedido se simulará<br class="show-desktop-tablet"> automáticamente.',
      actions: [
        {
          name: 'Si, cambiar',
          class: 'btn-back-new',
          event: () => this.dialogRef.close(true)  // Cambiado a true
        },
        {
          name: 'No, regresar',
          class: 'btn-red-new',
          event: () => this.dialogRef.close(false) // Cambiado a false
        },
      ],
    }
    ,
    {
      img: '⚠️',
      title: 'No es posible Administrar tu pedido en este momento',
      description:
        'Por favor vuelve a intentarlo en unos minutos.',
      actions: [
        {
          name: 'Entiendo',
          class: 'btn-red-new',
          event: () => {
            this.dialogRef.close(true);
            this.location.back(); // Redirecciona a la pantalla anterior
          }
        }
      ],
    }
  ];
  displayedColumns: string[] = ['sku', 'description'];
  constructor(
    public dialogRef: MatDialogRef<ManageOrderModalComponent>,
    private location: Location,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.dataRecieved = data;
  }

  ngOnInit(): void {
    this.openedStart();
  }

  ngOnDestroy(): void {
    this.closedStart();
  }

  closeDialog() {
    this.dialogRef.close();
    const customButton = document.querySelector('.custom-button');
    if (customButton) {
      customButton.classList.add('no-focus-outline');
    }
  }

  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
