import { Injectable } from '@angular/core';
import { Router, NavigationStart, Event } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OrderDetailModalComponent } from '@shared/components/modals/order-detail-modal/order-detail-modal.component';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrderChangeService } from './orderChange.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationInterceptorService {
  private previousUrl: string | undefined;
  private currentUrl: string | undefined;
  private modalStateCallback: (isActive: boolean) => void = () => {};

  private routesThatTriggerModal = [
    'main/inicio',
    'main/subir-pedido',
    'main/cuenta-corriente',
    'seleccionar-cliente',
    'main/cliente-seleccionado',
    'main/confirmacion',
    'tienda/busqueda',
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private orderChangeService: OrderChangeService
  ) {
    this.router.events
      .pipe(
        filter(
          (event: Event): event is NavigationStart =>
            event instanceof NavigationStart
        )
      )
      .subscribe((event: NavigationStart) => {
        this.previousUrl = this.currentUrl || this.router.url;
        this.currentUrl = event.url;

        if (this.shouldShowModal()) {
          this.modalStateCallback(true); // Establece el estado a "activo" cuando se debe mostrar el modal
          this.router.navigateByUrl(this.previousUrl!, {
            skipLocationChange: true,
          });

          this.openModalAndNavigate().subscribe((result) => {
            this.modalStateCallback(false); // Establece el estado a "inactivo" cuando el modal se cierra
            if (result) {
              this.router.navigateByUrl(this.currentUrl!);
            } else {
              this.currentUrl = this.previousUrl;
            }
          });
        } else {
          this.modalStateCallback(false); // Asegura que el estado esté "inactivo" si no se debe mostrar el modal
        }
      });
  }

  setModalStateCallback(callback: (isActive: boolean) => void): void {
    this.modalStateCallback = callback;
  }

  private shouldShowModal(): boolean {
    const comingFromGestionaPedido =
      this.previousUrl ===
      '/main/historial-compras/detalle-historial/gestiona-tu-pedido';

    const baseCurrentUrl = this.currentUrl ? this.currentUrl.split('?')[0] : '';

    const isTiendaBusqueda = baseCurrentUrl === 'tienda/busqueda';

    const goingToTriggerModal =
      (isTiendaBusqueda &&
        this.routesThatTriggerModal.includes('tienda/busqueda')) ||
      this.routesThatTriggerModal.includes(baseCurrentUrl.substring(1));

    const buttonState = this.orderChangeService.getButtonState();

    return comingFromGestionaPedido && goingToTriggerModal && buttonState;
  }

  private openModalAndNavigate(): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      status: 5,
      redirect: () => this.router.navigateByUrl(this.currentUrl!),
    };
    dialogConfig.width = '31rem';

    return this.dialog
      .open(OrderDetailModalComponent, dialogConfig)
      .afterClosed()
      .pipe(
        map((result) => result === true),
        take(1)
      );
  }
}
