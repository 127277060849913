<section class="container-show-car scroll-hidden static-height">
  <div #scrollableContainer class="container-car-info pt-16 pl-16 pr-16">
    <div class="car-info">
      <p class="font-medium text-line-credit fontw-400 fts-16 mb-8 mt-0">Mi línea de crédito</p>
      <div *ngIf="dataCurrentAccount; else squeleton">
        <div *ngFor="let item of dataCurrentAccount">

          <div>
            <div class="d-flex ai-center pb-16">
              <img class="icon-credit-line mr-8" src="assets/image/business-unities/{{unit.image}}-hover.svg">
              <p class="fts-12 text-line-credit font-bold m-0">S/ {{item?.creditLine|number:'1.2'}}
              </p>
            </div>
            <mat-progress-bar mode="determinate" value="40" class="car-progress-bar"></mat-progress-bar>
          </div>
          <div class="d-flex jc-sb fts-12 pt-6">
            <div class="text-count">
              <div class="font-book">Consumido</div>
              <div class="text-line-credit font-medium">S/ {{item?.amountConsumed|number:'1.2'}}</div>
            </div>
            <div class="text-count ta-r">
              <div class="font-book">Disponible</div>
              <div class="text-line-credit font-medium">S/ {{item?.amountAvailable|number:'1.2'}}</div>
            </div>
          </div>
          <div class="container-late-payment d-flex jc-sb ph-8 pv-2 mv-8">
            <div class="fts-12 font-book">Monto vencido</div>
            <div class="fts-12 font-medium"> S/ 0.00 </div>
          </div>
          <div class="more-details d-flex jc-fe">
            <a class="font-medium" [routerLink]="['/cuenta/facturacion']" (click)="closeModal.emit();">Ver más detalles</a>
          </div>
        </div>
      </div>
      <ng-template #squeleton>

      </ng-template>
      <div *ngIf="carLength > 0" class="pt-16 ta-c button-purchase-mobile" (click)="orderConfirmation()">
        <button class="btn-add-checkout activeAddCar font-medium">REVISAR COMPRA</button>
      </div>
      <hr class="dividing-line mv-16">
      <div *ngIf="carLength > 0 && car">
        <div class="mb-8">
          <button class="btn-clear-gray" (click)="clean()">Vaciar carrito</button>
        </div>
        <div class="" *ngFor="let product, let i = index of car.products">
          <div class="container-shopping-cart d-flex jc-sb">
            <div class="w-55 p-8">
              <p class="text-title-shopping-cart mt-8 fts-12">{{ product.description }}</p>
              <p class="price-prod-car fts-14 mt-24 mb-0">S/ <span>{{ product.totalPrice }}</span></p>
            </div>
            <div class="w-45 ta-r p-8">
              <img src="assets/svg/button_x.svg" (click)="delete(product)">
              <div class="mt-24">
                <span class="unit fts-12 pr-8">{{ product.buyUnd }}</span>
                <input #referenceInput type="text" class="input-car ta-r" [ngModel]="product.count"
                  (blur)="evaluateValue(product, i)" (ngModelChange)="changeCount($event, product, i)">
              </div>
            </div>
          </div>
          <hr class="dividing-line mv-8">
        </div>
      </div>
      <div [hidden]="carLength != 0">
        <div class="empty-car d-flex pt-48">
          <img class="mb-16" src="assets/image/icon-cart-empty.svg" alt="">
          <p class="mb-4 mt-0 font-bold color-gray">Tu carrito está vacío</p>
          <p class="m-0">Empieza a llenarlo buscando en las categorías...</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="carLength > 0" class="container-button-review-purchase p-16 ta-c button-purchase-pc" (click)="orderConfirmation()">
    <button class="btn-add-checkout activeAddCar font-medium">REVISAR COMPRA</button>
  </div>
</section>