import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  DetailRoutines,
  Routines,
  SelectedClient,
} from '@core/interfaces/selected-client';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SelectedClientService } from '@core/services/selected-client/selected-client.service';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import { DialogCloseSesionComponent } from '@shared/components/modals/dialog-close-sesion/dialog-close-sesion.component';
import { take } from 'rxjs';
import {
  MessageActivitiesNull,
  MessageRoutinesError,
  MessageRoutinesNull,
} from 'src/assets/data/errors-message';
import * as authActions from '@core/store/auth/auth.actions';
import * as shoppingActions from '@core/store/shoppingCar/shoppingCar.actions';
import * as clientsActions from '@core/store/selectClient/selectClient.actions';
import { AuthService } from '@core/services/authentication/auth.service';

@Component({
  selector: 'app-routines-detail',
  templateUrl: './routines-detail.component.html',
  styleUrls: ['./routines-detail.component.scss'],
})
export class RoutinesDetailComponent implements OnInit {
  routinesMessage: string =
    'Cumple todas tus rutinas de la semana y logra tus objetivos.';
  routinesList: Routines | null = null;
  routinesListLoading: boolean = false;
  routinesMessageError: boolean = false;
  user: Array<any> | any;
  selectRoutines?: DetailRoutines;
  messageClientNull = [MessageActivitiesNull];
  messageError = [MessageRoutinesError];
  messageRoutinesNull = [MessageRoutinesNull];
  repeatArray = Array(4);
  repeatArrayFormat = Array(2);
  showRoutinesList: boolean = true;
  dateClients: Array<SelectedClient> = [];
  @ViewChild('drawerProfileOptions')
  sidenavProfile!: MatSidenav;
  @ViewChild(MatMenuTrigger) myMenu!: MatMenuTrigger;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectarMovil();
  }
  esMovil: boolean = false;

  options = [
    { label: 'Inicio', link: '/main/inicio', icon: 'home' },
    { label: 'Subir pedido', link: '/main/subir-pedido', icon: 'downloads' },
    {
      label: 'Cuenta corriente',
      link: '/main/cuenta-corriente',
      icon: 'account',
    },
  ];
  optionsResponsive = [
    { show: true, label: 'Inicio', link: '/main/inicio', icon: 'home' },
    {
      show: true,
      label: 'Datos del cliente',
      link: '/main/inicio',
      icon: 'storefront',
    },
    {
      show: true,
      label: 'Subir pedido',
      link: '/main/subir-pedido',
      icon: 'downloads',
    },
    {
      show: true,
      label: 'Avance de ventas',
      link: '/main/avances-ventas',
      icon: 'advance',
    },
    {
      show: true,
      label: 'Reconocimientos',
      link: '/main/reconocimientos',
      icon: 'recognitions',
    },
    {
      show: true,
      label: 'Cuenta corriente',
      link: '/main/cuenta-corriente',
      icon: 'account',
    },
    {
      show: true,
      label: 'Historial de compras',
      link: '/main/historial-compras',
      icon: 'history',
    },
    { show: true, label: 'Facturas', link: '', icon: 'bills' },
    { show: true, label: 'Aliados', link: '/main/aliados', icon: 'allies' },
  ];
  labelVisible: boolean = true;
  constructor(
    private localStorageService: LocalStorageService,
    private selectedClientService: SelectedClientService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public dialogCloseSesion: MatDialog,
    private store: Store<AppState>,
    private _authService: AuthService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.routinesList = navigation?.extras?.state?.['data'] || null;
    if (this.routinesList && this.routinesList.routines) {
      this.selectRoutines = this.routinesList?.routines[0];
    }
  }

  ngOnInit() {
    this.detectarMovil();
    this.user = this.localStorageService.getItem('user');
    if (!this.routinesList) {
      // Si no hay datos pasados por el state, es una recarga de página
      this.initDataRoutines();
    }
    this.labelVisible = true;
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      console.warn('-----> usuario volvio a la web');

      // El usuario ha vuelto a la pantalla
      const lastFetchDate = localStorage.getItem('lastFetchDate');
      const today = new Date().toDateString();
      if (lastFetchDate !== today) {
        this.initDataRoutines();
      } else {
      }
    } else {
      console.warn('********* usuario se movio de la web :( *********');
      // El usuario se ha movido fuera de la pantalla
    }
  };

  initDataRoutines() {
    this.routinesList = null;
    this.routinesListLoading = true;
    this.routinesMessageError = false;
    this.selectedClientService
      .getRoutinesList(this.user?.code)
      .pipe(take(1))
      .subscribe(
        (res: Routines) => {
          this.routinesList = res || null;
          this.selectRoutines = this.routinesList?.routines[0];
          localStorage.setItem('lastFetchDate', new Date().toDateString());
          this.routinesListLoading = false;
        },
        () => {
          this.routinesListLoading = false;
          this.routinesMessageError = true;
        }
      );
  }

  detailRoutines(routine: DetailRoutines) {
    this.selectRoutines = routine;
    this.showRoutinesList = false;
  }

  processText(text: string): SafeHtml {
    const html = text.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getLastValidIndex(activities: any[]): number {
    for (let i = activities.length - 1; i >= 0; i--) {
      if (activities[i].formatName && activities[i].formatName.trim() !== '') {
        return i;
      }
    }
    return -1; // Si no hay elementos válidos
  }
  goToUrl(url: string) {
    window.open(url, '_blank');
  }
  backRoutines() {
    this.showRoutinesList = true;
  }
  clientSelect() {
    this.myMenu.closeMenu();
  }
  detectarMovil() {
    this.esMovil = window.innerWidth < 767;
  }
  closeProfile(event: string) {
    this.sidenavProfile.close();
    if (event === 'logout') {
      setTimeout(() => {
        this.openModalCloseSesion();
      }, 350);
    }
  }
  openProfile() {
    this.sidenavProfile.toggle();
  }
  openModalCloseSesion() {
    const dialogRef = this.dialogCloseSesion.open(DialogCloseSesionComponent, {
      width: '31rem',
      data: {},
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data?.status) {
        this.store.dispatch(authActions.unsetUser());
        this.store.dispatch(shoppingActions.unsetCar());
        this.store.dispatch(clientsActions.unsetClients());
        this._authService.logout();
      }
    });
  }
}
