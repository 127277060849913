import { LoginClient } from '@core/interfaces/login';
import { payload, User } from '@core/interfaces/user.model';
import { createAction, props } from '@ngrx/store';

export const logInListClient  = createAction(
  '[Auth] logInListClient',
  props<{data: LoginClient}>()
);

export const logInListClientError  = createAction(
  '[Auth] logInListClient Error',
  props<{payload: any}>()
);

export const setUserListClient = createAction(
  '[Auth] setUser',
  props<{user: User}>()
);

export const unsetUserListClient = createAction(
  '[Auth] unsetUser'
);