import { AuthState } from '@core/interfaces/states';
import { createReducer, on } from '@ngrx/store';
import * as authListClientActions from './auth-list-client.actions';

export const initialState: AuthState = {
  user: localStorage.getItem(`alicorp-user`)
    ? JSON.parse(localStorage.getItem(`alicorp-user`) || '{}')
    : null,
  isLoading: false,
  errorLogin: false,
};

const _authListClientReducer = createReducer(
  initialState,

  on(authListClientActions.logInListClient, (state) => ({
    ...state,
    errorLogin: false,
    isLoading: true,
  })),
  on(authListClientActions.logInListClientError, (state) => ({
    ...state,
    user: null,
    errorLogin: true,
    isLoading: false,
  })),
  on(authListClientActions.setUserListClient, (state, { user }) => ({
    ...state,
    user: { ...user },
    isLoading: false,
  })),
  on(authListClientActions.unsetUserListClient, (state) => ({
    ...state,
    user: null,
    isLoading: false,
  }))
);

export function authListClientReducer(state, action) {
  return _authListClientReducer(state, action);
}
