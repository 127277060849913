import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import { AuthService } from '@core/services/authentication/auth.service';

@Injectable()
export class BusinessUnitGuard implements CanActivate {

  permiso: any = {};

  constructor(
    private router: Router,
    private _authService: AuthService,
  ) {
  }
  canActivate(_route: ActivatedRouteSnapshot): boolean{
    if (this._authService.isSectorSelect()) {
        this.router.navigate(['/tienda']);
      return true;
    } else{
      // this.router.navigate(['/unidades-de-negocio']);
      return true;
    }
  }

}
