<header>
  <div class="border-bottom-header">
    <nav class="navbar navbar-expand-md bg-body-tertiary content-header">
      <div
        class="row vw-100 justify-content-between align-items-center header-row container"
      >
        <div class="ml-0 pl-0 d-md-none">
          <div class="content-menu">
            <mat-drawer-container class="hp-100 vw-100 fullscreen">
              <mat-drawer #drawer mode="over" position="start">
                <app-menu-responsive
                  [optionsMenu]="optionsResponsive"
                  (closeDialogemit)="openMenu()"
                ></app-menu-responsive>
              </mat-drawer>

              <mat-drawer-content>
                <div>
                  <div class="button-menu m-0" (click)="openMenu()">
                    <mat-icon
                      ><img
                        src="assets/image/selected-client-list/menu.svg"
                        alt=""
                        class="m-0 p-0"
                    /></mat-icon>
                  </div>
                </div>
              </mat-drawer-content>
            </mat-drawer-container>
          </div>
        </div>
        <div
          class="d-flex text-center align-items-center col-lg-5 col-md-6 pl-0 justify-content-logotype row"
        >
          <div class="d-flex pl-0">
            <a class="navbar-brand" [routerLink]="'/seleccionar-cliente'">
              <a class="navbar-brand mr-16 d-flex align-items-center">
                <img src="assets/svg/logo-new.svg" alt="" />
              </a>
            </a>
          </div>
          <div
            class="border-left select-client collapse navbar-collapse col-lg-7 col-md-7 col-sm-9 pl-0"
          >
            <div class="navbar-nav me-auto d-flex align-items-center vw-100">
              <p class="p-text">Estás en:</p>
              <div class="p-0 d-flex justify-content-start">
                <!-- (change)="changeValue($event)" -->
                <div class="p-0 content-nav-logo d-flex justify-content-start">
                  <mat-form-field
                    appearance="outline"
                    class="clientSelect"
                    color="accent"
                  >
                    <mat-select
                      [(ngModel)]="dataConvertClientSelect"
                      name="client"
                      [ngClass]="{ 'hide-select-arrow': true }"
                      [disabled]="dataConvertClients?.length === 1"
                      (selectionChange)="changeValue($event)"
                    >
                      <mat-option
                        *ngFor="let client of dataConvertClients"
                        [value]="client"
                        [disabled]="disabledClient()"
                      >
                        <span>{{ client.name }}</span>
                        <mat-icon
                          *ngIf="dataConvertClientSelect.code === client.code"
                          class="icon-check"
                        >
                          <img
                            src="assets/image/home-seller/check_regular.svg"
                            alt=""
                          />
                        </mat-icon>
                      </mat-option>
                    </mat-select>
                    <span class="material-symbols-rounded">expand_more</span>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="justify-content-end d-flex col-lg-7 col-md-6 col-7">
          <div class="collapse navbar-collapse">
            <ul
              class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll d-flex justify-content-end vw-100"
            >
              <li *ngFor="let option of options" class="nav-item">
                <a
                  class="nav-link font-header"
                  [routerLink]="option.link"
                  routerLinkActive="active"
                  >{{ option.label }}</a
                >
              </li>
            </ul>
          </div>

          <li class="nav-item d-flex align-items-center ml-4">
            <div class="content-img-perfil d-none d-md-block">
              <div class="img-user d-flex" [matMenuTriggerFor]="menu">
                <mat-icon
                  ><img
                    src="assets/image/selected-client-list/user-select.svg"
                    alt=""
                    class="m-0 p-0"
                /></mat-icon>
              </div>
            </div>

            <div class="content-profile d-md-none">
              <mat-drawer-container class="hp-100 vw-100" autosize>
                <mat-drawer #drawerProfile mode="over" position="end">
                  <app-profile-responsive
                    [optionsMenu]="options"
                    [user]="user"
                    [dateClients]="dataConvertClients?.length ? true : false"
                    (closeModalProfile)="closeProfile($event)"
                  ></app-profile-responsive>
                </mat-drawer>

                <mat-drawer-content>
                  <div class="background-color">
                    <div class="img-user d-flex" (click)="openProfile()">
                      <mat-icon
                        ><img
                          src="assets/image/selected-client-list/user-select.svg"
                          alt=""
                          class="m-0 p-0"
                      /></mat-icon>
                    </div>
                  </div>
                </mat-drawer-content>
              </mat-drawer-container>
            </div>
          </li>
          <li class="nav-item d-flex align-items-center ml-4">
            <div class="d-flex ai-center content-car">
              <mat-drawer-container class="hp-100 nw-100" autosize>
                <mat-drawer #drawerCar mode="over" position="end">
                  <app-show-seller-car
                    #appShowCar
                    [car]="car"
                    [totalPriceAdd]="totalPriceAdd"
                    (emitCloseX)="closeCar()"
                    (countProduct)="countProduct($event)"
                    (deleteProduct)="deleteProduct($event)"
                    (deleteCar)="deleteCar()"
                  ></app-show-seller-car>
                </mat-drawer>

                <mat-drawer-content>
                  <div (click)="openCar()">
                    <div
                      class="d-flex justify-content-between align-items-center ml-3"
                    >
                      <div class="{{ classCar }} d-flex">
                        <mat-icon
                          ><img
                            src="assets/image/selected-client-list/car.svg"
                            alt=""
                            class="m-0 p-0"
                        /></mat-icon>
                      </div>
                      <div
                        class="counter-icon-cart align-items-center d-flex ml-1 badge-color"
                        [ngClass]="
                          this.badge > 0 ? 'color-active' : 'color-disabled'
                        "
                      >
                        {{ this.badge }}
                      </div>
                    </div>
                  </div>
                </mat-drawer-content>
              </mat-drawer-container>
            </div>
          </li>
        </div>
      </div>

      <div
        class="border-left select-client d-md-none container select-client-mobile"
      >
        <!--(change)="changeValue($event)" -->
        <mat-form-field
          appearance="outline"
          class="clientSelect"
          color="accent"
        >
          <mat-select
            [(ngModel)]="dataConvertClientSelect"
            name="client"
            [ngClass]="{ 'hide-select-arrow': true }"
            [disabled]="dataConvertClients?.length === 1"
            (selectionChange)="changeValue($event)"
          >
            <mat-option
              *ngFor="let client of dataConvertClients"
              [value]="client"
              [disabled]="disabledClient()"
            >
              <span>{{ client.name }}</span>
              <mat-icon
                *ngIf="dataConvertClientSelect.code === client.code"
                class="icon-check"
              >
                <img src="assets/image/home-seller/check_regular.svg" alt="" />
              </mat-icon>
            </mat-option>
          </mat-select>
          <span class="material-symbols-rounded">expand_more</span>
        </mat-form-field>
      </div>
    </nav>
    <section class="navbar-section">
      <mat-toolbar color="accent" class="vw-100 jc-center hidden-xms">
        <div class="vw-100 nav-container container p-0">
          <div class="logo-welcome d-flex jc-sb ai-center">
            <div
              class="d-flex jc-sb ai-center button-categories-responsive-mobile"
            >
              <div class="d-flex ai-center vw-100">
                <mat-drawer-container class="example-container">
                  <mat-drawer
                    #drawerSeller
                    class="categories-sidenav"
                    mode="over"
                    (openedStart)="openedStart()"
                    (closedStart)="closedStart()"
                  >
                    <app-categories-list
                      [list]="listCategories"
                      (onRedirect)="categoriesEmit($event,'')"
                    >
                    </app-categories-list>
                  </mat-drawer>

                  <mat-drawer-content class="example-sidenav-content">
                    <a
                      mat-button
                      (click)="openCategories('desktop')"
                      class="vw-100 mat-icon-button-categories container-section-categories nav-button ph-16 d-flex jc-center ai-center"
                    >
                      <img
                        class="icon-nav-white"
                        src="assets/image/home/nav/Icon-list.svg"
                      />
                      <span class="font-book fts-16 pl-5 hidden-text-cat-car"
                        >Categorías</span
                      >
                    </a>
                  </mat-drawer-content>
                </mat-drawer-container>
              </div>
              <div class="d-flex ai-center pl-16 hidden-xms">
                <form class="pos-re">
                  <input
                    type="text"
                    #autoCompleteInputSeller
                    placeholder="Buscar productos"
                    class="input-search pl-16"
                    [matAutocomplete]="autoSeller"
                    (ngModelChange)="getList($event)"
                    [(ngModel)]="searchTextValue"
                    (click)="clickSearchBar()"
                    [ngModelOptions]="{ standalone: true }"
                    (blur)="mouseUp(searchTextValue)"
                    (focus)="mouseClickInput(searchTextValue)"
                    maxlength="50"
                  />
                  <i class="icon-search">
                    <img
                      class="icon-nav-white"
                      src="assets/image/home/nav/icon-search-green.svg"
                      alt=""
                  /></i>
                  <mat-autocomplete
                    #autoSeller="matAutocomplete"
                    class="container-autocomplete-search"
                  >
                    <mat-option
                      class="text-option-autocomplete font-medium fts-12"
                      *ngFor="let search of this.searchResponse$ | async"
                      (click)="
                        searchEngineSelectedProduct(
                          search.sku,
                          search.description
                        )
                      "
                    >
                      {{ search.description }}
                    </mat-option>
                  </mat-autocomplete>
                </form>
              </div>
            </div>
          </div>
        </div>
      </mat-toolbar>
      <mat-toolbar
        color="accent"
        class="vw-100 d-flex ai-center search-mobile show-xms"
      >
        <div class="d-flex ai-center vw-100 container_categories_mobile">
          <mat-drawer-container class="example-container">
            <mat-drawer
              #drawerSellerMobile
              class="categories-sidenav"
              mode="over"
              (openedStart)="openedStart()"
              (closedStart)="closedStart()"
            >
              <app-categories-list
                [list]="listCategories"
                (onRedirect)="categoriesEmit($event,'mobile')"
              >
              </app-categories-list>
            </mat-drawer>

            <mat-drawer-content class="example-sidenav-content">
              <a
                mat-button
                (click)="openCategories('mobile')"
                class="vw-100 mat-icon-button-categories  nav-button ph-16 d-flex jc-fs ai-center p-0"
              >
                <img
                  class="icon-nav-white"
                  src="assets/image/home/nav/Icon-list.svg"
                />
              </a>
            </mat-drawer-content>
          </mat-drawer-container>
        </div>
        <div class="d-flex ai-center vw-100">
          <form class="pos-re vw-100 container-autocomplete-search-mobile">
            <input
              type="text"
              placeholder="Buscar productos"
              class="vw-100 input-search-mobile pl-16"
              [matAutocomplete]="autoMobileSeller"
              (ngModelChange)="getList($event)"
              [(ngModel)]="searchTextValue"
              [ngModelOptions]="{ standalone: true }"
              (blur)="mouseUp(searchTextValue)"
              (focus)="mouseClickInput(searchTextValue)"
              maxlength="50"
            />
            <i class="icon-search">
              <img src="assets/image/home/nav/icon-search-green.svg"
            /></i>
            <mat-autocomplete #autoMobileSeller="matAutocomplete" class="">
              <mat-option
                class="text-option-autocomplete-mobile font-medium fts-12"
                *ngFor="let search of this.searchResponse$ | async"
                (click)="searchEngineSelectedProduct(search.sku)"
              >
                {{ search.description }}
              </mat-option>
            </mat-autocomplete>
          </form>
        </div>
      </mat-toolbar>
    </section>
  </div>

  <mat-menu #menu="matMenu">
    <app-profile [user]="user"    [dateClients]="dataConvertClients?.length ? true : false" (clientSelect)="clientSelect()"></app-profile>
  </mat-menu>
</header>
<div #search_opacity_seller (click)="removeOpacityAutocomplete()"></div>
