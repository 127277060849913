import { Directive, Input, ElementRef, OnInit, HostListener, EventEmitter, Output } from '@angular/core';
import { UtilsService } from '@core/services/utils/utils.service';

@Directive({
  selector: '[appFormatInput]'
})
export class FormatInputDirective{
  @Input ('inputText') inputText!: string;
  @Output() ngModelChange = new EventEmitter();
  constructor(
    private elementRef: ElementRef
  ) {
   }
  @HostListener('input', ['$event'])
  onInputChange(event): void {
    let initialValue = this.elementRef.nativeElement.value;
    const replace = initialValue.replace(/[^0-9-.]/g, '');
    let countFormat = parseFloat(replace) < 0 ? parseFloat(replace) * -1 : parseFloat(replace)
    if (isNaN(countFormat)) { countFormat = 0}
    if (isNaN(countFormat) || countFormat == 0) {
      this.elementRef.nativeElement.value = ''
    } else if (countFormat > 999999) {
      this.elementRef.nativeElement.value = '999,999';
    } else {
      this.elementRef.nativeElement.value = UtilsService.formatNumber(countFormat);
    }
    this.elementRef.nativeElement.selectionEnd;
  }
  // @HostListener('blur', ['$event'])
  // onInputBlur(event): void {
  //   let initialValue = this.elementRef.nativeElement.value;
  //   if(initialValue == '' || initialValue == 0){
  //     this.elementRef.nativeElement.classList.add('animation') 
  //     this.elementRef.nativeElement.value = 1
  //     setTimeout(() => {
  //       this.elementRef.nativeElement.classList.remove('animation') 
  //     }, 1000)
  //   }
  // }
}