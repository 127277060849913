<section>
  <div class="m-24">
   <div class="d-flex justify-content-between">
    <div
    class="container-icon d-flex align-items-center justify-content-center"
  >
  <div class="icon-user-profile">
    <img src="assets/image/home-seller/avatar.svg" alt="" />
  </div>
  </div>
  <div class="button-x">
    <div class="button-x ">
      <div class="icon-style-x d-flex" (click)="closeDialog('close')">
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </div>
   </div>
   <div class="text-profile">
    <div class="text-profile__name pt-2">{{ user?.fullName }}</div>
    <div class="text-profile__position pt-3">Cargo</div>
    <div class="text-profile__seller pt-2">{{ user?.position }}</div>
    <div class="text-profile__position pt-3">Correo</div>
    <div class="text-profile__seller pt-2">{{ user?.email }}</div>
  </div>
  </div>
  <div class="dl-divider"></div>
  <div class="button-profile pv-16 ph-24">
    
    <div  class="button-client d-flex align-items-center" [routerLink]="'/seleccionar-cliente'" (click)="selectClient('selectClient')">
      <img src="assets/image/home-seller/icon-dashboard.svg" alt="" class="pr-10">
      <span class="font-semiBold fts-16">Mi Dashboard</span>
    </div>
  </div>
  <div class="dl-divider"></div>
  <div class="button-profile pv-16 ph-24"  [class.disabled]="!dateClients">
    
    <div  class="button-client d-flex align-items-center" [routerLink]="'/main/cliente-seleccionado'" (click)="selectClient('selectClient')">
      <img src="assets/image/home-seller/groupBigger.svg"  alt="" class="pr-10">
      <span class="font-semiBold fts-16">Mis clientes</span>
    </div>
  </div>
  <div class="dl-divider"></div>
  <div class="button-profile" style="display: none">
    <!-- <dl-button
      disabled="true"
      iconName="key"
      hasIcon="left"
      variant="tertiary"
      iconWeight="bold"
      label="Cambiar contraseña"
      size="md"
      class="button-client"
    ></dl-button> -->
  </div>
  <!-- <div class="dl-divider"></div> -->
  <div class="button-profile pv-16 ph-24">
    <div  class="button-out d-flex align-items-center"  (click)="logout('logout')">
      <img src="assets/image/home-seller/signOut.svg"  alt="" class="pr-10">
      <span class="font-semiBold fts-16">Cerrar sesión</span>
    </div>
  </div>
</section>
