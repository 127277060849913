import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-close-sesion',
  templateUrl: './dialog-close-sesion.component.html',
  styleUrls: ['./dialog-close-sesion.component.scss'],
})
export class DialogCloseSesionComponent implements OnInit {
  
  constructor(public dialogRef: MatDialogRef<DialogCloseSesionComponent>,
    private router: Router,) {}

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }
  closeSesion() {
    this.dialogRef.close({ status: true });
  }
}
