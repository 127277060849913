<section class="d-flex">
  <div class="content-dialog">
    <div class="button-x justify-content-end d-flex">
      <a mat-icon-button

          class="button-x-style custom-button m-0 p-0 d-flex align-items-center justify-content-center"
          (click)="closeDialog()"
        >
          <span class="material-symbols-rounded  d-flex align-items-center justify-content-center fst-32"> close </span>
        </a>
    </div>
    <div class="ta-c d-flex justify-content-center align-items-center img-container fts-56">
      <div  *ngIf="dataRecieved.status == 4">  <img  [src]="modals[dataRecieved.status].img" alt=""></div>
     <div  *ngIf="dataRecieved.status !== 4 && dataRecieved.status !== 7">⚠️</div>
     <div  *ngIf="dataRecieved.status === 7">☝️</div>
    </div>
    <div class="title-suport ta-c p-2">
      <h1 class="font-bold line-height-40 mt-24 mb-16 h1-title">{{this.dataRecieved.message?this.dataRecieved.message: modals[dataRecieved.status].title }}</h1>
    </div>
    <div class="ta-c">
      <p class="m-0" [innerHTML]="modals[dataRecieved.status].description"></p>
    </div>
    <div
      class="content-button-close d-flex justify-content-center vvw-100 mt-24 mb-8"
    >
      <div
        class="d-flex justify-content-between content-button-close_button w-100 align-items-center"
      > <button
          mat-flat-button
          *ngIf="dataRecieved.status == 4 || dataRecieved.status == 5 || dataRecieved.status == 6"
          class="font-bold btn-enter ph-24 color-button_error"
          (click)="closeDialog()"
        >
          Entendido
        </button>
        <button
        *ngIf="dataRecieved.status !== 4 && dataRecieved.status !== 5 && dataRecieved.status !== 6"
          mat-flat-button
          class="font-bold btn-enter ph-24"
          color="accent"
          (click)="closeDialog()"
        >
          Entendido
        </button>
      </div>
    </div>
  </div>
</section>
