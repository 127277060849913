<app-header *ngIf="hiddenHeader && user.type==='Cliente'"></app-header>
<app-header-seller *ngIf="hiddenHeaderSeller"></app-header-seller>
<router-outlet></router-outlet>
<div *ngIf="isLoading" class="loader">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>