import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, Renderer2  } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface errors {
  sku: string;
  description: string;
}

@Component({
  selector: 'app-loader-suggested-order-modal',
  templateUrl: './loader-suggested-order-modal.component.html',
  styleUrls: ['./loader-suggested-order-modal.component.scss']
})
export class LoaderSuggestedOrderComponent implements OnInit, OnDestroy {

  dataRecieved: any ;
  modals =
    [
      {
        // img: 'assets/image/modal/loader-upload.svg',
       img: 'assets/image/modal/loader-spin.svg',
        title: 'Estamos comprobando la disponibilidad de tu pedido',
        description: 'Esto puede tardar unos minutos.',
      },
      {
        img: 'assets/image/modal/icon-check.svg',
        title: 'Genial, stock comprobado',
        description: 'Hemos realizado la comprobación de tu carrito y contamos con todo el stock de tus productos agregados.',
        actions: [{
          name: 'OK! ENTIENDO',
          class: 'btn-green font-medium',
          event: () => this.save(1, "success")
        }],
      }]
  dataSource : errors[] = []
  displayedColumns: string[] = ['sku', 'description'];
  constructor(
    public dialogRef: MatDialogRef<LoaderSuggestedOrderComponent>,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.dataRecieved = data;
      if(data.status == 2 || data.status == 3){
        this.dataSource = data.skuError
      }
    }

  ngOnInit(): void {
    this.openedStart()
  }
  
  ngOnDestroy(): void {
    this.closedStart()
  }

  
  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  save(status, message){
    this.dialogRef.close({status:status, message: message});
  }
  onNoClick(){
    this.dialogRef.close(false);
  }
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
