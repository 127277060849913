import { Login } from '@core/interfaces/login';
import { User } from '@core/interfaces/user.model';
import { createAction, props } from '@ngrx/store';

export const logIn  = createAction(
  '[Auth] logIn',
  props<{data: Login}>()
);

export const logInError  = createAction(
  '[Auth] logIn Error',
  props<{payload: any}>()
);

export const setUser = createAction(
  '[Auth] setUser',
  props<{user: User}>()
);

export const unsetUser = createAction(
  '[Auth] unsetUser'
);
//

export const logInListClient  = createAction(
  '[Auth List Client] logInListClient',
  props<{data: Login}>()
);

export const logInListClientError  = createAction(
  '[Auth List Client] logInListClient Error',
  props<{payload: any}>()
);

export const setUserListClient = createAction(
  '[Auth List Client] setUser',
  props<{user: User}>()
);

export const unsetUserListClient = createAction(
  '[Auth List Client] unsetUser'
);