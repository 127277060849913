import { Directive, Input, ElementRef, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appImageBroke]'
})
export class imageBrokeDirective implements OnInit{
  @Input() urlCustom!: string
  
  constructor(private elementRef: ElementRef) { }
  ngOnInit(): void {
    const img = this.elementRef.nativeElement
  }

   @HostListener('error')
  onError() {
    const element = this.elementRef.nativeElement
    element.src = this.urlCustom || `assets/image/search/photo-empty.svg`
  }
}