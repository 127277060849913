<!-- message.component.html -->
<div class="message mt-16" *ngIf="type === 'loading'">
  <div class="icon">
    <div class="spinner-custom"></div>
  </div>

  <div class="text font-regular text-green">
    <span class="font-bold-dali ">{{ messageLoading }}</span
    >&nbsp;{{ boldMessageLoading }}
  </div>
</div>
<div class="message mt-16" *ngIf="type === 'update'">
  <div class="icon">{{ alertIcon }}</div>
  <div class="text font-regular">
    <span class="font-semiBold text-green">{{ boldMessage }}</span
    >
  </div>
</div>
<div class="message-alert mt-16" *ngIf="type === 'alert'">
  <div class="icon">{{ alertIcon }}</div>
  <div class="text">
    {{ message }}
    <span class="font-semiBold">{{ boldMessage }}</span>
    <div class="text" *ngIf="extraMessage">
      **Recuerda que te mostramos el
      <span class="font-semiBold"> stock disponible en este momento.</span>
    </div>
  </div>
</div>
