import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {
  private url = environment.apiURL;
  constructor(private _http: HttpClienteService) { }

  getCustomerByCode(id: any, sector:any) {
   const query = `${this.url}/wcl-seller/customer-by-code?code=${id}&sector=${sector}`;
    // const query = `${this.url}/wcl-seller/customer-by-code?code=1000021678&sector=0007' \`;
    return this._http.get(query);
  }
}
