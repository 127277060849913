<div class="content-image-login input-content">
  <div class="hp-100">
    <div class="row ml-0 mr-0 hp-100">
      <div class="col-xs-0 col-sm-6 col-xl-7 section-img"></div>
      <div class="col-xs-12 col-sm-6 col-xl-5 pl-0 pr-0 hp-100">
        <div class="content-form align-middle d-flex align-content-center flex-wrap justify-content-center">
          <div class="align-content-center content-body-login">
            <img src="./../../../assets/image/login/img-logo-login.svg" alt="" />
            <div class="title-h">
              <p class="font-medium fts-24 fontw-400">Una nueva manera de gestionar tus actividades.</p>
            </div>
            <div class="">
              <form [formGroup]="loginForm" action="/form">
                <div>
                  <mat-form-field appearance="outline" class="vw-100">
                    <mat-label class="label-username">Usuario</mat-label>
                    <input id="username" type="text" matInput formControlName="username" maxlength="50" autocomplete="off"  (keydown)="onUsernameInputKeydown($event)"
                    required/>
                    <mat-error *ngIf="loginForm.get('username')?.hasError('required')">
                      Escribe un usuario válido.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mt-8 mb-8">
                  <mat-form-field appearance="outline" class="vw-100">
                    <mat-label class="label-username">Contraseña</mat-label>
                    <input matInput id="password" [type]="hidePaswordLogin ? 'password' : 'text'"
                      formControlName="password" maxlength="25" autocomplete="off" 
                      (keydown)="onPasswordInputKeydown($event)" required />
                    <a mat-icon-button matSuffix (click)="typeChangePassword()" [attr.aria-pressed]="hidePaswordLogin">
                      <div [ngClass]="hidePaswordLogin ? 'icon-visibility' : 'icon-visibility-off'">
                      </div>
                    </a>
                    <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
                      Por favor, introduce una contraseña.
                    </mat-error>
                  </mat-form-field>
                </div>
                
                <div *ngIf="logInError" class="mb-24">
                  <div class="negative-variant">
                    <span class="emoji">⚠️</span> Usuario y/o contraseña inválidos.
                  </div>
                </div>
                
                <div>
                  <button mat-flat-button class="button-signIn" (click)="onEnterSignIn()">
                    <span *ngIf="!loginInProcess">Ingresar</span>
                    <span *ngIf="loginInProcess">
                      <mat-icon>
                        <mat-spinner diameter="24"></mat-spinner>
                      </mat-icon>
                    </span>
                  </button>
                </div>
                
              </form>
              <div class="mt-4 ta-c" style="display: none">
                <!-- <dl-text
                    [size]="'paragraph'"
                    class="color-text p-title-option"
                    (click)="openDialog()"
                    >¿Olvidaste tu contraseña?</dl-text
                  > -->
              </div>   
              <div class="ta-c pt-4 mt-5 ta-c">
                <div class="p-title-option ta-s ">
                  Si tienes problemas para ingresar a nuestra plataforma comunícate al siguiente correo electrónico:
                  <strong class="font-weight-bold font-medium">
                    atencionclientes@alicorp.com.pe</strong>
                    o llámanos al número:
                    <strong class="font-weight-bold font-medium">
                      01 708-9300 Opción 2.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>