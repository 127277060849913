import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-awards',
  templateUrl: './skeleton-awards.component.html',
  styleUrls: ['./skeleton-awards.component.scss'],
})
export class SkeletonAwardsComponent implements OnInit {
  @Input() itemsSkeleton;
  constructor() {}

  ngOnInit() {}
}
