<div class="container-modal" [ngClass]="dataRecieved.status == 2 || dataRecieved.status == 3 ? 'max-size':''">
  <div class="img-modal">
    <img [ngClass]="dataRecieved.status == 1 ? 'check' :''" [src]="modals[dataRecieved.status].img" alt="">
  </div>
  <div class="message">
    <h1>{{modals[dataRecieved.status].title}}</h1>
    <p [innerHTML]="modals[dataRecieved.status].description"></p>
  </div>
  <div *ngIf="dataRecieved.status == 2 ||dataRecieved.status == 3" class="aditional-info">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-check-stock">
        <!-- sku Column -->
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef class="col-2"> Código </th>
          <td mat-cell *matCellDef="let element"> <p class="font-book"> {{element.sku}} </p></td>
        </ng-container>
        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="col-3"> Descripción </th>
          <td mat-cell *matCellDef="let element" class="text-message"> 
            <p [innerHTML]="element.description"></p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <div class="buttons">
    <button *ngFor="let action of modals[dataRecieved.status].actions" class="fbx-button" [ngClass]="action.class" (click)="action.event()">{{action.name}}</button>
  </div>
</div>
