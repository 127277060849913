import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, take } from 'rxjs';
import { ProductsService } from '@core/services/products/products.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertModalComponent } from '@shared/components/modals/alert-modal/alert-modal.component';
import { User } from '@core/interfaces/user.model';
import { LocalStorageService } from '@core/services/local-storage.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { AppState } from '@core/store/app.reducer';
import { Store, props } from '@ngrx/store';
import { Car, CarsState } from '@core/interfaces/cars';
import * as ui from '@core/store/ui/ui.actions';
import * as ShoppingActions from '@core/store/shoppingCar/shoppingCar.actions';
import { Router } from '@angular/router';
import { GtmEventService } from '@core/analytics/gtm-events.service';

@Component({
  selector: 'app-excel-table-modal',
  templateUrl: './excel-table-modal.component.html',
  styleUrls: ['./excel-table-modal.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvent($event)'
  }
})
export class ExcelTableModalComponent implements OnInit, OnDestroy{
  subscription!: Subscription;
  dataRecieved: any ;
  navigator = window.navigator;
  dataForm!: FormGroup;
  limit: number = 0;
  actualLimit: number = 100;
  user!: User;
  sectorSelect: any;
  uploadProducts:any;
  @ViewChildren("referenceInput", { read: ElementRef }) referenceInput!: QueryList<
    ElementRef<HTMLInputElement>
  >;
  flagQuantity = false;

  errors = [{
    type:1, message: 'El valor de la cantidad debe ser mayor a 0.'
  },{
    type:2, message: 'El SKU o la cantidad no son números.'
  },{
    type:3, message: 'La cantidad máxima del producto es de 999,999.'
  },{
    type:4, message: 'SKU no habilitado.'
  },{
    type:5, message: 'SKU no encontrado.'
  },{
    type:6, message: 'SKU no disponible.'
  },{
    type:7, message: 'La cantidad no puede estar vacía.'
  }]
  isLoading: Boolean = false;
  uiSubscription!: Subscription;
  carsSubscription!: Subscription;
  carsState: CarsState = { cars: [] };

  constructor(
    private store: Store<AppState>,
    public formB: FormBuilder,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private productService: ProductsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private _gtmEventService: GtmEventService,
    public dialogRef: MatDialogRef<ExcelTableModalComponent>,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.openedStart()
      this.dataRecieved = data;
    }
  ngOnInit(): void {
    this.loadData();
    this.initStore();
    this.initForm();
  }
  ngOnDestroy(): void {
    this.closedStart()
  }


  loadData(){
    // Cargar información de localstorage
    if(this.router.url !== "/main/subir-pedido"){
      this.user  = this.localStorageService.getItem("user").code
      this.sectorSelect  = this.localStorageService.getItem("sectorSelect")
    }
    else {
      this.user  = this.localStorageService.getItem("clientSelect").code
      this.sectorSelect  = this.localStorageService.getItem("clientSelect")
    }
  }

  initStore(){
    // Inicializar Store
    this.uiSubscription = this.store.select('ui').subscribe(ui => this.isLoading = true)
    this.carsSubscription = this.store.select('carsState').subscribe(carsState => {
      if (carsState) {
        this.carsState.cars = [...carsState.cars]
      }
    })
  }

  initForm(){
    // Inicializar formulario con un form Builder
    this.dataForm = this.formB.group({
      products: this.formB.array([])
    });
    // Agregar 100 celdas al formulario
    for(let i = 0; i< 100; i++){
      this.addProduct()
    }
  }

  get products() {
    return this.dataForm.controls["products"] as FormArray;
  }

  addProduct(){
    const productForm = this.formB.group({
      sku: [''],
      quantity: [''],
    });

    this.products.push(productForm);
  }

  handleKeyboardEvent(e: KeyboardEvent) {
    // console.log(e)
    let key = e.key;
    let cmd_held = e.metaKey;
    let ctrl_held = e.ctrlKey;
    if((cmd_held || ctrl_held) && key.toLowerCase() == "v") {
      this.pasteEvent()
    }
  }

  async pasteEvent(){
    // console.log(e)
    const text = await navigator.clipboard.readText();
    this.setData(text)
  }

  setData(s){
    let flagSecondColum = false
    const rowArray = s.trim().split(/\n/);
    let transforArr: any[] = [];
    rowArray.map((row)=> {
      const temp = row.split(/\t/);
      let tempArr: any[] = []
      if(this.flagQuantity){
        tempArr.push('')
        temp[0] ? tempArr.push(temp[0]) : tempArr.push('')
        flagSecondColum = true
      }else{
        temp[0] ? tempArr.push(temp[0]) : tempArr.push('')
        temp[1] ? tempArr.push(temp[1]) : tempArr.push('')
      }
      transforArr.push(tempArr)
    })
    this.limit = transforArr.length
    for(let i= 0; i < this.limit; i++){
      if(i >= this.actualLimit && i >= 100){
        this.addProduct()
      }
      if(!flagSecondColum){
        transforArr[i][0] != ''
        ? this.products.controls[i].get('sku')?.setValue(transforArr[i][0])
        :''
      }
      transforArr[i][1] != "" ? this.products.controls[i].get('quantity')?.setValue(transforArr[i][1]) : ''
    }
    if(this.actualLimit > this.limit){
      this.cleanOthers()
    }
    this.actualLimit = this.limit;
    flagSecondColum= false;
  }
  
  cleanOthers(){
    for(let i = this.actualLimit-1; i>= this.limit; i--){
      this.products.controls[i].get('sku')?.setValue('')
      this.products.controls[i].get('quantity')?.setValue('')
    }
  }

  checkData(){
    for(let i = 0; i< 100; i++){
      if(this.products.controls[i].value.sku != '' ||this.products.controls[i].value.quantity != ''){
        this.limit = i+1;
      }
    }
  }

  sendData(){
    this._gtmEventService.clickContinueButtonEvent();
    this.checkData();
    this.dialogRef.close();
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    this.activeLoading();
    // Evaluando que haya almenos uno producto
    if(this.limit == 0){
      // La columna cantidad no debe estar vacia
      this.deactiveLoading();
      dialogConfig.panelClass = 'check-stock'
      dialogConfig.data = {status:1};
      let dialogRef = this.dialog.open(AlertModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        (data: any) => {
          return;
        }
      );
    }else{
      
      let posibleProducts: any[] = []
      let skuProducts: any[] = []
      let skuError:any[] = []
      let products: any[] = this.checkProducts()
      products.forEach((product, index) => {
        const cantidad = product.quantity;
        // if(cantidad){
          // console.log('entra cantidad')
          const {skuFormat, quantityFormat, sku, quantity} = product
          // console.log(index, skuFormat, quantityFormat, sku, quantity)
          if(isNaN(skuFormat)){
            skuError.push({...product, position: product.originalPosition, error: this.errors[1]})
          }else if(quantity == ''){
            skuError.push({...product, position: product.originalPosition, error: this.errors[6]})
          }
          else if (skuFormat == 0) {
            skuError.push({...product, position: product.originalPosition, error: this.errors[4]})
          }
          else if(quantityFormat == 0){
            skuError.push({...product, position: product.originalPosition, error: this.errors[0]})
          }else if (isNaN(skuFormat) || String(skuFormat).trim() == '' || skuFormat < 0 || this.searchSpecialCharacter(sku)) {
            skuError.push({...product, position: product.originalPosition, error: this.errors[1]})
          }
          else if(isNaN(quantityFormat) || !Number.isInteger(quantityFormat) ) {
            // si hay skus con 0 se muestra un mensaje de error
            skuError.push({...product, position: product.originalPosition, error: this.errors[1]})
          }else if(quantityFormat <= 0) {
            // si hay skus con 0 se muestra un mensaje de error
            skuError.push({...product, position: product.originalPosition,  error: this.errors[0]})
          }else if(quantityFormat > 999999 ){
            // preparando skus para validar
            skuError.push({...product, position: product.originalPosition,  error: this.errors[2]})
          }else{
            // damos formato a los productos
            posibleProducts.push(product)
            // Añadimos lista de sku validos
            skuProducts.push({sku: skuFormat, count: quantityFormat})
          }
        // }
      });
      if(products.length > 0){
        let data = {
          "code": this.user,
          "sector":this.sectorSelect.sector,
          "zone":this.sectorSelect.zone,
          "schema": this.sectorSelect.schema,
          "segment": this.sectorSelect.segment,
          "products": skuProducts
        }
        // console.log(data)
        let skuAvailable: any[] = []
        // Consultamos los SKUs disponibles:
        this.productService.setValidateSKU(data).pipe(take(1)).subscribe({
          next: (res: any) => {
            // console.log(res)
            if(!res && skuError.length == 0 && posibleProducts.length == 0 ){
              dialogConfig.panelClass = 'check-stock';
              dialogConfig.data = {status:3};
              let dialogRef = this.dialog.open(AlertModalComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(
                (data: any) => {
                  this.deactiveLoading()
                  return;
                }
              );
            }else{
              posibleProducts.map(product => {
                if(!res){
                  skuError.push({...product, position: product.originalPosition, error: this.errors[4]})
                }else{
                  const productFind = res.filter((productSearch) => product.sku == productSearch.sku)
                  if(!productFind[0]){
                    skuError.push({...product, position: product.originalPosition, error: this.errors[4]})
                  }else if (productFind[0].available) {
                    skuAvailable.push(this.formatProduct(productFind[0], product.quantity))
                  }else{
                    skuError.push({...productFind[0], position: product.originalPosition, error: this.errors[5]})
                  }
                }
              })
              if(skuError.length !== 0 ){
                let warnings: string[] = [];
                for (const warning of skuError) {
                  warnings.push(warning.error.message)
                }
                this._gtmEventService.viewWarningDisplaySkuEvent(warnings);
                dialogConfig.panelClass = 'excel-table-check'
                dialogConfig.data = {
                  status:2,
                  skuError: this.orderForPosition(skuError)
                };
                let dialogRef = this.dialog.open(AlertModalComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                  (data: any) => {
                    if(data.status == 1){
                      if(skuAvailable.length > 0){
                        this.addProductsInStore(skuAvailable)
                      }else{
                        this._gtmEventService.viewErrorDisplaySkuEvent();
                        dialogConfig.data = {status:7};
                        dialogConfig.panelClass='quantity-zero'
                        let dialogRef2 = this.dialog.open(AlertModalComponent, dialogConfig);
                        this.exit();
                      }
                    }
                  }
                );
              }else{
                this.deactiveLoading();
                if(skuAvailable.length > 0){
                  this._gtmEventService.viewVerificationOkEvent();
                  this.addProductsInStore(skuAvailable)
                }else{
                  this._gtmEventService.viewErrorDisplaySkuEvent();
                  // flujo donde nada paso.
                  dialogConfig.data = {status:7};
                  this.dialog.open(AlertModalComponent, dialogConfig);
                  this.exit();
                }
              }
            }
          },
          error: (e) => {
            console.log(e)
            this.exit()
          },
          complete: () => {
            this.exit()
          }
        })
        
      }else{
        this.deactiveLoading();
        dialogConfig.panelClass = 'check-stock'
        dialogConfig.data = {status:1};
        let dialogRef = this.dialog.open(AlertModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          (data: any) => {
            return;
          }
        );
      }
    }

  }
  checkProducts(){
    const listSkus: any = []
    const checkProducts:any  = []
    for(let i= 0; i < this.limit; i++){
      const quantity = this.products.controls[i].value.quantity;
      listSkus.push({
        sku: this.products.controls[i].value.sku,
        skuFormat: parseInt(this.products.controls[i].value.sku),
        quantity: quantity.trim(),
        quantityFormat: parseInt(quantity),
        position: i+1,
        originalPosition: i+1
      })
    }
    let position = 1
    
    listSkus.forEach((product, index) => {
      const filterProduct = checkProducts.find(localProduct => localProduct.sku == product.sku)
      if(!filterProduct){
        let tempQuantity = 0
        for(let i = index ; i < this.limit; i++){
          if(product.sku == listSkus[i].sku){
            if(!isNaN(listSkus[i].quantityFormat)){
              tempQuantity = tempQuantity + listSkus[i].quantityFormat
            }
          }
        }
        checkProducts.push({
          ...product,
          quantity: tempQuantity == 0 ? product.quantity : String(tempQuantity),
          quantityFormat: tempQuantity == 0 ? product.quantityFormat : tempQuantity,
          position: position,
        })
        position ++;
      }
    });
    this.limit = position - 1
    return checkProducts
  }
  orderForPosition(products){
    products = products.sort(this.compare_lname)
    return products
  }

  compare_lname( a, b ){
    if ( a.position < b.position){
      return -1;
    }
    if ( a.position > b.position){
      return 1;
    }
    return 0;
  }

  formatProduct(product, count){
    const sale_price = Number.isInteger(product.sale_price) ? product.sale_price: product.sale_price.replace(/[^0-9-.]/g, '');
    return {
      ...product,
      cashPrice: product.cash_price,
      creditPrice: product.credit_price,
      count: UtilsService.formatNumber(count),
      salePrice: UtilsService.formatTotalNumber(sale_price),
      buyUnd: product.buy_und,
      totalPrice: UtilsService.formatTotalNumber((count * parseFloat(product.sale_price)).toFixed(2)),
      perceptionFlag: product.categoryName == 'Harinas' || product.category_name == 'Harinas'? 1 : 0
    }
  }


  addProductsInStore(products){
    this.uploadProducts = products;
    this.activeLoading()
    if (this.carsState.cars.length == 0) {
      // buscar nuevos productos
      // crear carro nuevo
      const car: Car = {
        unitBussines: this.sectorSelect.name,
        products: [...products]
      }
      this.carsState.cars.push(car)
      this.store.dispatch(ShoppingActions.setCar({ cars: this.carsState.cars }))
      this.redirect()
    } else {
      // buscar la unidad de negocio actual
      let ifBusinessCarExist = false
      const tempCars = this.carsState.cars.map(car => {
        if (car.unitBussines == this.sectorSelect.name) {
          // Buscar productos activos en carrito actual, sino existe agregar sino editar
          car = {
            ...car,
            products: this.searchProductInExistingCar(products,car)
          }
          ifBusinessCarExist = true
          return car
        }
        return car
        // console.log("carrito:", car)
      })
      if (!ifBusinessCarExist) {
        // sino existe
        const car: Car = {
          unitBussines: this.sectorSelect.name,
          products: [...products]
        }
        tempCars.push(car)
      }
      this.carsState.cars = [...tempCars]
      this.store.dispatch(ShoppingActions.setCar({ cars: this.carsState.cars }))
      this.redirect()
    }
  }

  searchProductInExistingCar(products, car) {
    let newListInCarProducts: any = []
    let newListInActiveProducts: any = [...products]
    let flagExist = false;
    car.products.forEach((productInCar) => {
      flagExist = false
      newListInActiveProducts.forEach((activeProduct, index) => {
        if (activeProduct.sku == productInCar.sku) {
          const replace = Number.isInteger(activeProduct.count) ? activeProduct.count: String(activeProduct.count).replace(/[^0-9]/g, '');
          const replace2 =  Number.isInteger(productInCar.count) ? productInCar.count: String(productInCar.count).replace(/[^0-9]/g, '');
          // producto existe
          let tempCount = parseFloat(replace) + parseFloat(replace2) > 999999
                          ? 999999
                          : parseFloat(replace) + parseFloat(replace2)
          let tempTotalprice = UtilsService.formatTotalNumber((tempCount * parseFloat(productInCar.salePrice)).toFixed(2))
          const tempProduct = {
            ...productInCar,
            count: UtilsService.formatNumber(tempCount),
            totalPrice: `${tempTotalprice}`
          }
          newListInCarProducts.push(tempProduct)
          newListInActiveProducts.splice(index, 1)
          flagExist = true
        }
      })
      if (!flagExist) {
        newListInCarProducts.push(productInCar)
      }
    })
    return newListInCarProducts.concat(newListInActiveProducts)
  }
  exit(){
    this.deactiveLoading();
    this.dialogRef.close();
  }

  redirect(){
    this.exit()
    this.deactiveLoading()

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {status:8};
    dialogConfig.panelClass='quantity-zero'
    let dialogRef = this.dialog.open(AlertModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        (data: any) => {
          if(this.router.url === "/main/subir-pedido"){
            this.router.navigate(['/main/confirmacion']);
            return
          } else {
            this._gtmEventService.addProductCartEvent("ENTENDIDO", this.uploadProducts);
            this.router.navigate(['/tienda/mi-carrito']);
            return;
          }
        }
      );
    return;
  }
  activeLoading(){
    // console.log('active loading')
    this.store.dispatch(ui.isLoading())
  }

  deactiveLoading(){
    this.store.dispatch(ui.stopLoading())
  }

  activeFlag(){
    this.flagQuantity = true;
  }
  deactiveFlag(){
    this.flagQuantity = false;
  }
  searchSpecialCharacter(text: string){
    const Patron = "[!\"·$%&/()=¿¡?'_:;,|@#€*+.]";
    return RegExp(Patron).test(text);
  }
  
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}

