import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface errors {
  sku: string;
  description: string;
}

@Component({
  selector: 'app-error-suggested-order',
  templateUrl: './error-suggested-order.component.html',
  styleUrls: ['./error-suggested-order.component.scss'],
})
export class ErrorSuggestedOrderComponent implements OnInit, OnDestroy {
  dataRecieved: any;
  modals = [
    {
      // img: 'assets/image/modal/loader-upload.svg',
      img: 'assets/image/modal/loader-spin.svg',
      title: 'No pudimos comprobar la disponibilidad de tu pedido',
      description: 'Por favor vuelve a intentarlo en unos minutos.',
    },
    {
      img: 'assets/image/modal/icon-check.svg',
      title: 'Genial, stock comprobado',
      description:
        'Hemos realizado la comprobación de tu carrito y contamos con todo el stock de tus productos agregados.',
      actions: [
        {
          name: 'OK! ENTIENDO',
          class: 'btn-green font-medium',
          event: () => this.dialogRef.close(),
        },
      ],
    },
    {
      img: 'assets/image/modal/icon-alert-new.svg',
      title: 'Algunos productos no están disponibles',
      description:
        'Hemos encontrado que los siguientes productos se encuentran <span class="red-alert">SIN STOCK</span>, de momento se actualizará la lista de tu carrito para continuar tu pedido.',
      actions: [
        {
          name: 'OK! ENTIENDO',
          class: 'btn-green font-medium',
          event: () => this.save(1, 'success'),
        },
      ],
    },
    {
      img: 'assets/image/modal/icon-alert-new.svg',
      title: 'Algunos productos no están disponibles',
      description:
        'Hemos encontrado los siguientes productos ya no se encuentran disponibles, puedes omitirlos y <span class="bold-alert">continuar</span> o <span class="bold-alert">cancelar</span> para hacer seguir comprando.',
      actions: [
        {
          name: 'CANCELAR',
          class: 'btn-back-new fts-16 font-medium',
          event: () => this.save(2, 'cancel'),
        },
        {
          name: 'CONTINUAR',
          class: 'btn-green font-medium',
          event: () => this.save(1, 'success'),
        },
      ],
    },
    {
      img: 'assets/image/modal/cart.svg',
      title: 'No superaste el monto mínimo',
      description:
        'Comunícate con tu asesor de ventas para saber el monto exacto que debes superar.',
      actions: [
      ],
    },
    {
      img: '',
      title: 'Pedido no válido',
      description:
        'Asegúrate de haber pagado tus facturas, si ya lo hiciste comunícate con tu asesor de ventas para regularizar tu línea de crédito.',
      actions: [
      ],
    },
    {
      img: '',
      title: 'No cuentas con saldo disponible',
      description:
        'Comunícate con tu asesor de ventas para conocer las condiciones de tu línea de crédito.',
      actions: [
      ],
    },
    {
      img: '',
      title: 'Asegúrate que se realizo tu pedido revisando tu Historial de compras.',
      description: 'Tu pedido tardó más de lo normal pero es posible que se haya generado, por favor revisa tu Historial de compras.',
      actions: [{
        name: 'Entendido',
        class: 'btn-red font-medium',
        event: () => this.save(1, "success")
      }],
    },
  ];
  dataSource: errors[] = [];
  displayedColumns: string[] = ['sku', 'description'];
  constructor(
    public dialogRef: MatDialogRef<ErrorSuggestedOrderComponent>,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.dataRecieved = data;
    
    if (data.status == 2 || data.status == 3) {
      this.dataSource = data.skuError;
    }
  }

  ngOnInit(): void {
    this.openedStart();
  }

  ngOnDestroy(): void {
    this.closedStart();
  }

  closeDialog() {
    this.dialogRef.close();
    const customButton = document.querySelector('.custom-button');
    if (customButton) {
      customButton.classList.add('no-focus-outline');
    }
  }
  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  save(status, message) {
    this.dialogRef.close({ status: status, message: message });
  }
  onNoClick() {
    this.dialogRef.close(false);
  }
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
