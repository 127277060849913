<div class="container-modal">
  <div class="ta-c d-flex justify-content-center align-items-center img-container fts-48">
    <div>🙌</div>
  </div>
  <div class="message mt-16">
    <h1 class="fts-20 font-bold">{{modals[dataRecieved.status].title}}</h1>
    <p *ngIf="modals[dataRecieved.status].description" [innerHTML]="modals[dataRecieved.status].description"></p>
  </div>
  <div *ngIf="this.dataRecieved.status===1">
    <div class="d-flex justify-content-between mt-32" *ngIf="acceptedProducts"><span class="font-bold">Productos sugeridos aceptados</span>
      <div class="status order-suggested fts-12 line-height-12">
        Producto sugerido
      </div>
    </div>
    <ng-container *ngFor="let valueNew of dataSource">
      <div *ngIf="valueNew.acceptSuggestion" class="mt-16 text-description d-flex justify-content-between">
        <div class="mr-8">Código: {{valueNew.suggestedProducts[0].sku}}</div>
       <span *ngIf="valueNew.acceptSuggestion" class="d-flex ta-r">{{valueNew.suggestedProducts[0].description}}</span></div>
    </ng-container>
  </div>
  <div class="buttons" *ngIf="modals[dataRecieved.status].actions">
    <button *ngFor="let action of modals[dataRecieved.status].actions" class="mat-button" color="primary"
      [ngClass]="action.class" (click)="action.event()">
      {{ action.name }}
    </button>
  </div>
</div>
