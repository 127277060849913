import { AuthState } from '@core/interfaces/states';
import { User } from '@core/interfaces/user.model';
import { createReducer, on } from '@ngrx/store';
import * as authActions from './auth.actions';

export const initialState: AuthState = {
  user: localStorage.getItem(`alicorp-user`)
  ? JSON.parse( localStorage.getItem(`alicorp-user`) || '{}') : null,
  isLoading: false,
  errorLogin: false,
}

const _authReducer = createReducer(initialState,

  on(authActions.logIn, (state) => ({ ...state, errorLogin: false, isLoading: true })),
  on(authActions.logInError, state => ({ ...state, user: null, errorLogin: true, isLoading: false})),
  on(authActions.setUser, (state, {user}) => ({ ...state, user: { ...user }, isLoading: false })),
  on(authActions.unsetUser, state => ({ ...state, user: null, isLoading: false })),

);

export function authReducer(state, action) {
    return _authReducer(state, action);
}