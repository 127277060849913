<section class="content-list-select scroll-hidden">
  <nav class="navbar navbar-light bg-light p-0 content-nav">
    <div class="d-flex justify-content-between container-list vw-100 align-items-center container">
      <a class="navbar-brand" [routerLink]="'/seleccionar-cliente'">
        <img src="assets/svg/logo-new.svg" alt />
      </a>
      <div>
        <div class="content-img-perfil d-none d-md-block">
          <div class="img-user d-flex" [matMenuTriggerFor]="menuClientsRoutines">
            <mat-icon><img src="assets/image/selected-client-list/user-select.svg" alt class="m-0 p-0" /></mat-icon>
          </div>
        </div>

        <div class="content-profile d-md-none">
          <mat-drawer-container class="hp-100 vw-100" autosize>
            <mat-drawer #drawerProfileOptions mode="over" position="end">
              <app-profile-responsive [optionsMenu]="options" [user]="user"
                [dateClients]="dateClients?.length ? true : false"
                (closeModalProfile)="closeProfile($event)"></app-profile-responsive>
            </mat-drawer>

            <mat-drawer-content>
              <div class="background-color">
                <div class="img-user d-flex" (click)="openProfile()">
                  <mat-icon><img src="assets/image/selected-client-list/user-select.svg" alt
                      class="m-0 p-0" /></mat-icon>
                </div>
              </div>
            </mat-drawer-content>
          </mat-drawer-container>
        </div>
      </div>
    </div>
  </nav>
  <div class="py-3 container">
    <div class="container-routines">
      <div class="d-flex align-items-center">
        <img src="assets/image/selected-client-list/icon-way.png" alt class="pr-8 image-custom_way" />
      </div>
      <ng-container *ngIf="!routinesListLoading; else buttonsLisSqueleton">
        <div class="d-flex align-items-center repository-links">
          <div class="link-1">
            <button class="btn-green p-12 font-book fts-14" pButton (click)="goToUrl(routinesList?.linkPlaybook)"
              [disabled]="routinesMessageError">
              Playbook
              <img
                [src]="routinesMessageError?'assets/image/home-seller/icon-redirect-disabled.svg':'assets/image/home-seller/icon-redirect.svg'"
                class="ml-8" />
            </button>
          </div>
          <div class="link-2">
            <button class="btn-green p-12 font-book fts-14" pButton (click)="goToUrl(routinesList?.linkSite)"
              [disabled]="routinesMessageError">
              Portal AlicorpWay
              <img
                [src]="routinesMessageError?'assets/image/home-seller/icon-redirect-disabled.svg':'assets/image/home-seller/icon-redirect.svg'"
                class="ml-8" />
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #buttonsLisSqueleton>
        <div class="d-flex align-items-center repository-links">
          <div class="link-1 show-desktop-tablet">
            <ngx-skeleton-loader count="1" [theme]="{
              height: '40px',
              width: '155px',
              'border-radius': '8px',
              'margin-bottom': '0px',
              'background':' #FCFCFC'
            }"></ngx-skeleton-loader>
          </div>
          <div class="link-2 show-desktop-tablet">
            <ngx-skeleton-loader count="1" [theme]="{
              height: '40px',
              width: '155px',
              'border-radius': '8px',
              'margin-bottom': '0px',
              'background':' #FCFCFC'
            }"></ngx-skeleton-loader>
          </div>
          <div class="link-1 show-mobil">
            <ngx-skeleton-loader count="1" [theme]="{
              height: '40px',
              'border-radius': '8px',
              'margin-bottom': '0px',
              'background':' #FCFCFC'
            }"></ngx-skeleton-loader>
          </div>
          <div class="link-2 show-mobil">
            <ngx-skeleton-loader count="1" [theme]="{
              height: '40px',
              'border-radius': '8px',
              'margin-bottom': '0px',
              'background':' #FCFCFC'
            }"></ngx-skeleton-loader>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="margin-custom" [ngClass]="esMovil && routinesMessageError ?'':'dl-divider'"></div>
    <ng-container *ngIf="!routinesListLoading; else routinesLisSqueleton">
      <section class="show-desktop-tablet">
        <div class="d-flex container-cards " *ngIf="routinesList?.routines?.length > 0; else bodyErrorOrNull">
          <div class="list">
            <app-card-routines [routinesMessage]="routinesMessage" [routinesList]="routinesList"
              [routinesListLoading]="routinesListLoading" [routinesMessageError]="routinesMessageError"
              (detailRoutines)="detailRoutines($event)" [headerShow]="false" (errorRoutines)="initDataRoutines()"
              [selectedRoutine]="selectRoutines"></app-card-routines>
          </div>
          <div class="show-box-shadow pt-24 vw-100">
            <ng-container *ngIf="selectRoutines?.activities.length != 0; else nullRoutine">
              <div class=" pl-24 pr-24 container-activity-format" *ngIf="selectRoutines">
                <div class="font-bold-dali fts-20">
                  {{ selectRoutines.position }}. {{ selectRoutines.name }}
                </div>

                <div class="d-flex justify-content-between container-header-routines">
                  <p class="activities-count">
                    Esta rutina tiene
                    <span class="font-bold-dali">{{ selectRoutines.activities.length }}</span>
                    <span class="font-bold-dali">
                      {{selectRoutines.activities.length == 1 ?'actividad.':'actividades.'}}</span>
                  </p>
                  <div class="repository-link d-flex align-items-center">
                    <div><span class="font-bold-dali pr-16">Mi repositorio:</span></div>
                    <button class="btn-green p-12 font-book fts-14" pButton
                      (click)="goToUrl(routinesList?.linkSharepoint)">
                      Ir al sharepoint
                      <img src="assets/image/home-seller/icon-redirect.svg" class="ml-8" />
                    </button>
                  </div>
                </div>
              </div>

              <div class="dl-divider"></div>
              <div class="vw-100 container-activity overflow-auto">
                <div class="p-24">
                  <div class="d-flex align-items-center"> <img src="assets/image/modal/activities.svg" alt="activities"
                      width="16px" />
                    <span class="font-bold-dali pl-12 fts-14 line-height-16">
                      Actividades
                    </span>
                  </div>
                  <div class="dl-divider mt-16 mb-16"></div>
                  <div class="activitie-list" *ngFor="let activitie of selectRoutines?.activities; let i = index">
                    <div>
                      <span class="font-bold-dali">{{i+1}}. </span><span [innerHTML]="processText(activitie.name)"
                        class="font-regular fts-14 line-height-16"></span>
                    </div>
                    <div class="dl-divider mt-16 mb-16" *ngIf="i !== selectRoutines?.activities.length - 1"></div>
                  </div>
                </div>
                <div class="p-24 container-format">
                  <div>
                    <div class="d-flex align-items-center">
                      <img src="assets/image/modal/format.svg" alt="format" width="16px" />

                      <span class="font-bold-dali pl-12 fts-14 line-height-16">
                        Formatos para esta rutina
                      </span>
                    </div>
                    <div class="dl-divider mt-16 mb-16"></div>
                    <!-- Contenido del contenedor de rutinas -->
                    <ng-container *ngFor="let activitie of selectRoutines.activities; let i = index">
                      <!-- Filtra solo los elementos con formatName definido y no vacío -->
                      <ng-container *ngIf="activitie.formatName && activitie.formatName.trim() !== ''">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="font-regular fts-14 line-height-16 max-value">{{ activitie.formatName }}</div>
                          <button class="btn-red p-12 fts-14 alicorp-font-bold" pButton (click)="goToUrl(activitie.formatLink)">
                            Abrir
                            <svg class="ml-8" width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.04262 12.7445H11.0098C11.5344 12.7445 12.0066 12.3642 12.0066 11.7609V6.85597H10.4984V11.3674H1.5541V2.63302H6.23606V1.25597H1.04262C0.51803 1.25597 0.0458984 1.6363 0.0458984 2.23958V11.7609C0.0458984 12.3642 0.51803 12.7445 1.04262 12.7445ZM12.3607 1.58384L11.8492 4.95433L10.6164 4.7445C10.7738 3.82646 10.7607 3.35433 11.3508 2.47565L7.6 6.22647L6.76065 5.38712L10.5115 1.6363C9.63278 2.22647 9.17377 2.22647 8.30819 2.39696L8.09836 1.16417L11.4033 0.626465L12.3607 1.58384Z" />
                            </svg>
                          </button>
                        </div>
                        <!-- Mostrar línea divisoria solo si no es el último elemento con formatName -->
                        <div class="dl-divider mb-16 mt-16" *ngIf="i !== getLastValidIndex(selectRoutines.activities)"></div>
                      </ng-container>
                    </ng-container>
                    
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #nullRoutine>

              <div class="container header-container-error">
                <div class="font-bold-dali fts-20">
                  {{ selectRoutines?.position }}. {{ selectRoutines?.name }}
                </div>
              </div>
              <div class="dl-divider mt-16"></div>
              <div class="vw-100 d-flex align-items-center justify-content-center container-null">
                <div class="null-error-activities ta-c pb-16 pt-16">
                  <app-message-empty-error [messageError]="messageClientNull"></app-message-empty-error>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </section>
      <section class="show-mobile">
        <div class="d-flex container-cards">
          <div class="list" *ngIf="showRoutinesList">
            <app-card-routines [routinesMessage]="routinesMessage" [routinesList]="routinesList"
              [routinesListLoading]="routinesListLoading" [routinesMessageError]="routinesMessageError"
              (detailRoutines)="detailRoutines($event)" [headerShow]="false" (errorRoutines)="initDataRoutines()"
              [selectedRoutine]="selectRoutines"></app-card-routines>
          </div>
          <div class="show-box-shadow pt-24 vw-100 border-radio-custom" *ngIf="!showRoutinesList">
            <div class=" pl-24 pr-24 d-flex align-items-center mb-16" (click)="backRoutines()"> <img
                src="assets/image/home-seller/icon-arrow-routines.svg" class="mr-8" /><span class="text-go-back">
                Regresar</span></div>
            <ng-container *ngIf="selectRoutines?.activities.length != 0; else nullRoutineMobile">
              <div class=" pl-24 pr-24 container-activity-format" *ngIf="selectRoutines">
                <div class="font-bold-dali fts-20">
                  {{ selectRoutines.position }}. {{ selectRoutines.name }}
                </div>

                <div class="d-flex justify-content-between container-header-routines">
                  <p class="activities-count">
                    Esta rutina tiene
                    <br>
                    <span class="font-bold-dali">{{ selectRoutines.activities.length }}</span>

                    <span class="font-bold-dali">
                      {{selectRoutines.activities.length == 1 ?'actividad.':'actividades.'}}</span>
                  </p>
                  <div class="repository-link">
                    <button class="btn-green p-12 font-book fts-14" pButton
                      (click)="goToUrl(routinesList?.linkSharepoint)">
                      Ir al sharepoint
                      <img src="assets/image/home-seller/icon-redirect.svg" class="ml-8" />
                    </button>
                  </div>
                </div>
              </div>

              <div class="dl-divider mb-16"></div>
              <div class="container-mobile m-24">
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
                  <mat-tab label="Actividades">
                    <div class="cont">
                      <div class="d-flex align-items-center"> <img src="assets/image/modal/activities.svg"
                          alt="activities" width="16px" />
                        <span class="font-bold-dali pl-12 fts-14 line-height-16">
                          Actividades
                        </span>
                      </div>
                      <div class="dl-divider mt-16 mb-16"></div>
                      <!-- Contenido del contenedor de actividades -->
                      <div class="activitie-list" *ngFor="let activitie of selectRoutines.activities; let i = index">
                       
                        <div>
                          <span class="font-bold-dali">{{i+1}}. </span><span [innerHTML]="processText(activitie.name)"
                            class="font-regular fts-14 line-height-16"></span>
                        </div>
                        <div class="dl-divider mt-16 mb-16" *ngIf="i !== selectRoutines?.activities.length - 1"></div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab label="Formatos">
                    <div class="cont">
                      <div class="d-flex align-items-center">
                        <img src="assets/image/modal/format.svg" alt="format" width="16px" />

                        <span class="font-bold-dali pl-12 fts-14 line-height-16">
                          Formatos para esta rutina
                        </span>
                      </div>
                      <div class="dl-divider mt-16 mb-16"></div>
                      <!-- Contenido del contenedor de rutinas -->
                      <ng-container *ngFor="let activitie of selectRoutines.activities; let i = index">
                        <!-- Filtra solo los elementos con formatName definido y no vacío -->
                        <ng-container *ngIf="activitie.formatName && activitie.formatName.trim() !== ''">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="font-regular fts-14 line-height-16 max-value">{{ activitie.formatName }}</div>
                            <button class="btn-red p-12 fts-14 alicorp-font-bold" pButton (click)="goToUrl(activitie.formatLink)">
                              Abrir
                              <svg class="ml-8" width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.04262 12.7445H11.0098C11.5344 12.7445 12.0066 12.3642 12.0066 11.7609V6.85597H10.4984V11.3674H1.5541V2.63302H6.23606V1.25597H1.04262C0.51803 1.25597 0.0458984 1.6363 0.0458984 2.23958V11.7609C0.0458984 12.3642 0.51803 12.7445 1.04262 12.7445ZM12.3607 1.58384L11.8492 4.95433L10.6164 4.7445C10.7738 3.82646 10.7607 3.35433 11.3508 2.47565L7.6 6.22647L6.76065 5.38712L10.5115 1.6363C9.63278 2.22647 9.17377 2.22647 8.30819 2.39696L8.09836 1.16417L11.4033 0.626465L12.3607 1.58384Z" />
                              </svg>
                            </button>
                          </div>
                          <!-- Mostrar línea divisoria solo si no es el último elemento con formatName -->
                          <div class="dl-divider mb-16 mt-16" *ngIf="i !== getLastValidIndex(selectRoutines.activities)"></div>
                        </ng-container>
                      </ng-container>
                      
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </ng-container>
            <ng-template #nullRoutineMobile>
              <div class="container header-container-error">
                <div class="font-bold-dali fts-20">
                  {{ selectRoutines?.position }}. {{ selectRoutines?.name }}
                </div>
              </div>
              <div class="dl-divider mt-16"></div>
              <div class="vw-100 d-flex align-items-center justify-content-center container-null">
                <div class="null-error-activities ta-c pb-16 pt-16">
                  <app-message-empty-error [messageError]="messageClientNull"></app-message-empty-error>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </section>

      <ng-template #bodyErrorOrNull>
        <div class="show-box-shadow container-error-null">
          <div class="text-null ta-c d-flex" *ngIf="routinesMessageError; else nullClient">
            <app-message-empty-error [messageError]="messageError" (retryDataError)="initDataRoutines()">
            </app-message-empty-error>
          </div>
          <ng-template #nullClient>
            <div class="text-null ta-c pb-16 pt-16">
              <app-message-empty-error [messageError]="messageRoutinesNull"></app-message-empty-error>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #routinesLisSqueleton>
      <div class="d-flex container-cards">
        <div class="list">
          <div class="show-box-shadow p-24 vw-100 container-way">

            <div class="d-flex align-items-center mb-16 mt-16">
              <ngx-skeleton-loader count="1" [theme]="{
              height: '56px',
              width: '48px',
              'border-radius': '8px',
              'margin-bottom': '0px'
            }"></ngx-skeleton-loader>
              <div class="fts-16 font-semiBold ml-16">
                <div>
                  <ngx-skeleton-loader count="1" [theme]="{
                    height: '16px',
                    width: '200px',
                    'border-radius': '8px',
                    'margin-bottom': '0px'
                  }"></ngx-skeleton-loader>
                </div>
                <div>
                  <ngx-skeleton-loader count="1" [theme]="{
                  height: '16px',
                  width: '120px',
                  'border-radius': '8px',
                  'margin-bottom': '0px'
                }"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center container-routines-date">
              <div class="container-border-line calendar-custom p-12">
                <div class="d-flex">
                  <div class="name-client">
                    <div class="fts-14 line-height-16 fontw-600">
                      <ngx-skeleton-loader count="1" [theme]="{
                      height: '16px',
                      width: '88px',
                      'border-radius': '8px',
                      'margin-bottom': '0px'
                    }"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-border-line calendar-custom p-12 ml-8">
                <ngx-skeleton-loader count="1" [theme]="{
                    height: '16px',
                    width: '106px',
                    'border-radius': '8px',
                    'margin-bottom': '0px'
                  }"></ngx-skeleton-loader>
              </div>
            </div>
            <div class="container-border-line mt-16 p-20 rutines-list-skeleton">
              <ng-container *ngFor="let routine of repeatArray; let i = index">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="font-regular fts-14">
                    <ngx-skeleton-loader count="1" [theme]="{
                    height: '16px',
                    width: '64px',
                    'border-radius': '8px',
                    'margin-bottom': '0px'
                  }"></ngx-skeleton-loader>
                  </div>
                  <div> <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                    height: '32px',
                    width: '32px',
                    'border-radius': '50%',
                    'margin-bottom': '0px'
                  }"></ngx-skeleton-loader></div>
                </div>
                <div class="dl-divider mt-16 mb-16" *ngIf="i !== 3"></div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="show-box-shadow pt-24 vw-100 show-desktop-tablet">
          <div class=" pl-24 pr-24 container-activity-format">
            <div class="font-bold-dali fts-20">
              <div class="font-regular fts-14">
                <ngx-skeleton-loader count="1" [theme]="{
                  height: '16px',
                  width: '16px',
                  'border-radius': '8px',
                  'margin-bottom': '0px'
                }"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [theme]="{
                  height: '16px',
                  width: '200px',
                  'border-radius': '8px',
                  'margin-bottom': '0px',
                  'margin-left': '8px'
                }"></ngx-skeleton-loader>
              </div>
            </div>

            <div class="d-flex justify-content-between container-header-routines align-items-center">
              <div class="activities-count">
                <ngx-skeleton-loader count="1" [theme]="{
                    height: '16px',
                    width: '100px',
                    'border-radius': '8px',
                    'margin-bottom': '0px'
                  }"></ngx-skeleton-loader>
              </div>
              <div class="repository-link">
                <ngx-skeleton-loader count="1" [theme]="{
                    height: '40px',
                    width: '155px',
                    'border-radius': '8px',
                    'margin-bottom': '0px'
                  }"></ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="dl-divider"></div>
          <div class="vw-100 container-activity">
            <div class="p-24">
              <div class="d-flex align-items-center"> <img src="assets/image/modal/activities.svg" alt="activities"
                  width="16px" />
                <span class="font-bold-dali pl-12 fts-14 line-height-16">
                  Actividades
                </span>
              </div>
              <div class="dl-divider mt-16 mb-24"></div>
              <div class="activitie-list" *ngFor="let routine of repeatArrayFormat; let i = index">
                <div class="mb-16">
                  <ngx-skeleton-loader count="1" [theme]="{
                    height: '16px',
                    width: '160px',
                    'border-radius': '8px',
                    'margin-bottom': '0px'
                  }"></ngx-skeleton-loader>
                </div>
                <div class="mb-16">
                  <ngx-skeleton-loader count="1" [theme]="{
                      height: '16px',
                      width: '100px',
                      'border-radius': '8px',
                      'margin-bottom': '0px'
                    }"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="p-24 container-format">
              <div>
                <div class="d-flex align-items-center">
                  <img src="assets/image/modal/format.svg" alt="format" width="16px" />

                  <span class="font-bold-dali pl-12 fts-14 line-height-16">
                    Formatos para esta rutina
                  </span>
                </div>
                <div class="dl-divider mt-16 mb-24"></div>
                <!-- Contenido del contenedor de rutinas -->
                <div class="format-list" *ngFor="let routine of repeatArrayFormat; let i = index">
                  <div class="mb-16">
                    <ngx-skeleton-loader count="1" [theme]="{
                       height: '16px',
                       width: '160px',
                       'border-radius': '8px',
                       'margin-bottom': '0px'
                     }"></ngx-skeleton-loader>
                  </div>
                  <div class="mb-16">
                    <ngx-skeleton-loader count="1" [theme]="{
                         height: '16px',
                         width: '100px',
                         'border-radius': '8px',
                         'margin-bottom': '0px'
                       }"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</section>
<mat-menu #menuClientsRoutines="matMenu">
  <app-profile [user]="user" [dateClients]="dateClients?.length ? true : false"
    (clientSelect)="clientSelect()"></app-profile>
</mat-menu>