import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private url = environment.apiURL;
  constructor(
    private _http: HttpClienteService,
    ) { }

  getAccounts(data: string, update: boolean){
    const query = `${this.url}/wcl-customer/account-status?code=${data}&update=${update}`
    return this._http.get(query);
  }

  getbalanceDebt(code: string){
    const query = `${this.url}/wcl-order/v2/order-balance?code=${code}`
    return this._http.get(query);
  }
}
