<section class="container-overflow-auto-custom">
    <div class="overflow-auto-custom">
  <div class="d-flex justify-content-between p-16 header-table-advanceSales row">
    <div class="fontw-600 fts-12 col-2">Categoría</div>
    <div class="fontw-600 fts-12 col-2 d-flex justify-content-center">Plan</div>
    <div class="fontw-600 fts-12 col-2 d-flex justify-content-center">Real</div>
    <div class="fontw-600 fts-12 col-4 d-flex justify-content-center">
      Cumplimiento
    </div>
    <div class="fontw-600 fts-12 col-2 d-flex justify-content-end">Faltante</div>
  </div>
  <hr class="dividing-line-solid m-0" />
  <!-- Filas de la tabla -->
  <ng-container *ngFor="let detail of itemsSkeletonDetail; let i = index">
    <div class="row pv-12 ph-16">
      <div class="fontw-400 fts-14 col-2">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '118px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div class="fontw-400 fts-14 col-2 d-flex justify-content-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '80px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div class="fontw-400 fts-14 col-2 d-flex justify-content-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '80px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div class="row col-4">
        <div class="fontw-400 fts-14 col-10 d-flex justify-content-center vw-100">
          <div class="vw-100 container-progress-line">
            <ngx-skeleton-loader
            count="1"
            appearance="line"
            [theme]="{
              height: '8px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
          </div>
        </div>
        <div class="fontw-400 fts-14 col-2 d-flex justify-content-center ml-16">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            [theme]="{
              height: '16px',
              width: '46px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
      <div class="fontw-400 fts-14 col-2 d-flex justify-content-end">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '80px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <hr class="dividing-line-solid m-0" *ngIf="i !== itemsSkeletonDetail.length - 1" />
  </ng-container>
  </div>
</section>