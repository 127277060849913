<div>
  <div
    class="d-flex justify-content-between p-16 align-items-center container-menu"
  >
    <a class="navbar-brand mr-16 d-flex align-items-center">
      <!-- <dl-logotype variant="alicorp" size="md"></dl-logotype> -->

      <img src="assets/image/menu-responsive/logo-responsive.svg" alt="" />
    </a>
    <div class="button-x">
      <div class="icon-style-x d-flex" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </div>
  <div class="justify-content-start d-flex">
    <ul class="navbar-nav navbar-nav-scroll vw-100 p-0 m-0">
      <li *ngFor="let option of optionsMenu" class="nav-item">
        <a
          class="nav-link font-header ustify-content-start d-flex p-0"
          routerLinkActive="active"
          (click)="closeDialog(); action(option)"
          *ngIf="option.show"
        >
          <div class="color-button vw-100 d-flex align-items-center image-text">
            <div class="d-flex align-items-center">
              <img
                src="assets/image/menu-responsive/{{ option.icon }}.svg"
                alt=""
              />
            </div>
            <span class="pl-8 font-semiBold"> {{ option.label }}</span>
          </div>
        </a>
        <div class="dl-divider"  *ngIf="option.show"></div>
      </li>
    </ul>
  </div>
</div>
