import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientDataService } from '@core/services/client-data/client-data.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-client-data-modal',
  templateUrl: './client-data-modal.component.html',
  styleUrls: ['./client-data-modal.component.scss'],
})
export class ClientDataModalComponent implements AfterViewInit {
  @ViewChild('hiddenInput') hiddenInput!: ElementRef;
  public user: any;
  public sector: any;
  public name:any;
  public clientData: any;
  public clientSelect: any;
  public errorDialog: boolean = false;
  public charactersPerLine: number = 30;

  constructor(
    public dialogRef: MatDialogRef<ClientDataModalComponent>,
    private router: Router,
    private _localStorageService: LocalStorageService,
    private _clientDataService: ClientDataService
  ) {
    this.getClientData();
    this.onResize(null);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.hiddenInput.nativeElement.focus();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (window.innerWidth < 768) {
      this.charactersPerLine = 20; // Valor para mobile
    } else {
      this.charactersPerLine = 30; // Valor para desktop
    }
  }

  splitIntoLines(text: string | undefined, charactersPerLine: number): string {
    if (!text) {
      return '';
    }
    const lines: string[] = [];
    for (let i = 0; i < text.length; i += charactersPerLine) {
      lines.push(text.slice(i, i + charactersPerLine));
    }
    return lines.join('<br>');
  }

  onHiddenInputFocus() {
    const customButton = document.querySelector('.custom-button');
    if (customButton) {
      customButton.classList.remove('no-focus-outline');
    }
  }

  closeDialog() {
    this.dialogRef.close();
    const customButton = document.querySelector('.custom-button');
    if (customButton) {
      customButton.classList.add('no-focus-outline');
    }
  }

  getClientData() {
    this.errorDialog = false;
    this.user = this._localStorageService.getItem('clientSelect').code;
    this.sector = this._localStorageService.getItem('clientSelect').sector;
    this.name = this._localStorageService.getItem('clientSelect').name;
    this._clientDataService
      .getCustomerByCode(this.user, this.sector)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.clientData = res;
          this.clientSelect = res.stores[0];
        },
        (error: any) => {
          this.errorDialog = true;
          console.log(error);
        }
      );
  }

  formatPhoneNumber(phone: string): string {
    const formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
    return formattedPhone;
  }
}
