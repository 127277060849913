<ng-container *ngIf="!routinesListLoading; else routinesLisSqueleton">
  <div class="p-24 vw-100 container-way border-radio-custom" [ngClass]="(esMovil && !headerShow && routinesMessageError)?'container-error-detail':'show-box-shadow'">
    <ng-container *ngIf="headerShow">
      <div class="d-flex justify-content-between align-items-start container-routines">
        <div class="d-flex align-items-center">
          <img src="assets/image/selected-client-list/icon-way.png" alt class="pr-8 image-custom_way" />
        </div>
        <div class="d-flex align-items-center img-user" (click)="navigateToDetails()" *ngIf="!routinesMessageError">
          <span  class="pr-8 text-show font-semiBold fts-16">Ver detalle</span>
          <img src="assets/image/home-seller/caretRight_regular.svg" alt />
        </div>
      </div>
      <div class="dl-divider"></div>
    </ng-container>
    <ng-container *ngIf="routinesList?.routines?.length > 0; else bodyErrorOrNull">
      <div class="d-flex align-items-center mb-16 mt-16">
        <img src="assets/image/selected-client-list/icon-check.svg" alt class="pr-16" height="56" width="48" />
        <div class="fts-16 font-semiBold">
          {{ routinesMessage }}
        </div>
      </div>
      <div class="d-flex align-items-center container-routines-date">
        <div class="container-border-line calendar-custom p-12">
          <div class="d-flex">
            <div class="name-client">
              <div class="fts-14 line-height-16 fontw-600">
                {{
                routinesList?.routines ? routinesList?.routines?.length : "0"
                }}
                rutinas
              </div>
            </div>
          </div>
        </div>
        <div class="container-border-line calendar-custom p-12">
          <img src="assets/svg/awards/icon-calendar.svg" alt  width="16px"/>
       
            <span class=" name-client pl-12 fts-14 line-height-16">
              {{
              routinesList?.date
              ? formatDate(routinesList?.date)
              : "- - --/--/----"
              }}
            </span>
        </div>
      </div>
      <div class="container-border-line mt-16 p-20 rutines-list">
        <ng-container *ngFor="let routine of routinesList?.routines; let i = index">
          <div class="d-flex align-items-center justify-content-between" [class.selected]="selectedRoutine === routine">
            <div class="font-regular fts-14 line-height-17 mr-10">
              <span class="font-bold">{{ i + 1 }}.&nbsp;</span>{{ routine?.name
              }}
            </div>

            <img   *ngIf="headerShow" (click)="routineRedirect(routine)" src="assets/image/selected-client-list/icon-arrow-way.svg" alt class="pr-8" />
            <img   *ngIf="!headerShow" (click)="routineRedirectDetail(routine)" [src]="selectedRoutine === routine ? 'assets/image/selected-client-list/icon-way-select.svg' : 'assets/image/selected-client-list/icon-arrow-way.svg'" alt class="pr-8 show-desktop-tablet" />
            <img   *ngIf="!headerShow" (click)="routineRedirectDetail(routine)" [src]="'assets/image/selected-client-list/icon-arrow-way.svg'" alt class="pr-8 show-mobile" />
        
          </div>
          <div class="dl-divider mt-16 mb-16" *ngIf="i !== routinesList?.routines?.length - 1"></div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #bodyErrorOrNull>
      <div class="text-error ta-c d-flex" *ngIf="routinesMessageError; else nullClient">
        <app-message-empty-error [messageError]="messageError"
          (retryDataError)="initDataRoutines()">
        </app-message-empty-error>
      </div>
      <ng-template #nullClient>
        <div class="text-error ta-c pb-16 pt-16">
          <app-message-empty-error [messageError]="messageClientNull"></app-message-empty-error>
        </div>
      </ng-template>
    </ng-template>
  </div>
</ng-container>
<ng-template #routinesLisSqueleton>
  <div class="show-box-shadow p-24 vw-100 container-way">
    <div
      class="d-flex justify-content-between align-items-start container-routines"
      *ngIf="headerShow"
    >
      <div class="d-flex align-items-center">
        <img
          src="assets/image/selected-client-list/icon-way.png"
          alt
          class="pr-8 image-custom_way"
        />
      </div>
      <div class="d-flex align-items-center img-user">
       
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              height: '16px',
              width: '64px',
              'border-radius': '8px',
              'margin-bottom': '0px'
            }"
          ></ngx-skeleton-loader>
      
      </div>
    </div>
    <div class="dl-divider" *ngIf="headerShow"></div>
    <div class="d-flex align-items-center mb-16 mt-16">
      <ngx-skeleton-loader
      count="1"
      [theme]="{
        height: '56px',
        width: '48px',
        'border-radius': '8px',
        'margin-bottom': '0px'
      }"
    ></ngx-skeleton-loader>
      <div class="fts-16 font-semiBold ml-16">
        <div>
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              height: '16px',
              width: '200px',
              'border-radius': '8px',
              'margin-bottom': '0px'
            }"
          ></ngx-skeleton-loader>
        </div>
         <div>
          <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '16px',
            width: '120px',
            'border-radius': '8px',
            'margin-bottom': '0px'
          }"
        ></ngx-skeleton-loader>
         </div>
      </div>
    </div>
    <div class="d-flex align-items-center container-routines-date">
      <div class="container-border-line calendar-custom p-12">
        <div class="d-flex">
          <div class="name-client">
            <div class="fts-14 line-height-16 fontw-600">
              <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '16px',
                width: '88px',
                'border-radius': '8px',
                'margin-bottom': '0px'
              }"
            ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
      <div class="container-border-line calendar-custom p-12 ml-8">
        <ngx-skeleton-loader
            count="1"
            [theme]="{
              height: '16px',
              width: '106px',
              'border-radius': '8px',
              'margin-bottom': '0px'
            }"
          ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="container-border-line mt-16 p-20 rutines-list-skeleton">
      <ng-container *ngFor="let routine of repeatArray; let i = index">
        <div class="d-flex align-items-center justify-content-between">
          <div class="font-regular fts-14">
            <ngx-skeleton-loader
            count="1"
            [theme]="{
              height: '16px',
              width: '64px',
              'border-radius': '8px',
              'margin-bottom': '0px'
            }"
          ></ngx-skeleton-loader>
          </div>
          <div> <ngx-skeleton-loader
            count="1"
            appearance="circle"
            [theme]="{
              height: '32px',
              width: '32px',
              'border-radius': '50%',
              'margin-bottom': '0px'
            }"
          ></ngx-skeleton-loader></div>
        </div>
        <div class="dl-divider mt-16 mb-16" *ngIf="i !== 3"></div>
      </ng-container>
    </div>
  </div>
</ng-template>
