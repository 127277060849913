import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Routines, SelectedClient } from '@core/interfaces/selected-client';
import { AuthService } from '@core/services/authentication/auth.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SelectedClientService } from '@core/services/selected-client/selected-client.service';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import { DialogCloseSesionComponent } from '@shared/components/modals/dialog-close-sesion/dialog-close-sesion.component';
import { take } from 'rxjs';
import * as authActions from '@core/store/auth/auth.actions';
import * as authClientActions from '@core/store/auth/auth-list-client.actions';
import * as shoppingActions from '@core/store/shoppingCar/shoppingCar.actions';
import * as clientsActions from '@core/store/selectClient/selectClient.actions';
import {
  ErrorsMessageClient,
  NullMessageClient,
} from 'src/assets/data/errors-message';
import { MatSelect } from '@angular/material/select';
import { MatSidenav } from '@angular/material/sidenav';
import { MatMenuTrigger } from '@angular/material/menu';
import 'moment/locale/es';
@Component({
  selector: 'app-selected-client-list',
  templateUrl: './selected-client-list.component.html',
  styleUrls: ['./selected-client-list.component.scss'],
})
export class SelectedClientListComponent implements OnInit {
  welcomeSubMessage: string =
    'Te damos la bienvenida a este espacio pensado para ti.';
  routinesMessage: string =
    'Cumple todas tus rutinas de la semana y logra tus objetivos.';
  clientSelectMessage: string = 'Elige un cliente';
  clientDescription: string =
    'Despliega la casilla y elige el cliente al que deseas dar seguimiento hoy.';
  suggestionsDescription: string =
    'Ayúdanos a mejorar la experiencia de nuestra plataforma, envíanos tus sugerencias y recomendaciones.';
  trainingDescription: string =
    'Revisa las capacitaciones y conoce el uso de nuestras herramientas para clientes y vendedores.';
  dateClients: Array<SelectedClient> = [];
  clientSearch: Array<SelectedClient> = [];
  messageError = [ErrorsMessageClient];
  messageClientNull = [NullMessageClient];
  user: Array<any> | any;
  booleanMessageError = false;
  loadingSkeleton = true;
  searchValue: string = '';
  counter = Array;
  cantColm = 8;
  listResponseNull: any;
  labelVisible: boolean = true;
  dataConvertClientSelect: any;
  isOpen: boolean = false;
  @ViewChild('selectClients') selectClients!: MatSelect;
  options = [
    { label: 'Inicio', link: '/main/inicio', icon: 'home' },
    { label: 'Subir pedido', link: '/main/subir-pedido', icon: 'downloads' },
    {
      label: 'Cuenta corriente',
      link: '/main/cuenta-corriente',
      icon: 'account',
    },
  ];
  optionsResponsive = [
    { show: true, label: 'Inicio', link: '/main/inicio', icon: 'home' },
    {
      show: true,
      label: 'Datos del cliente',
      link: '/main/inicio',
      icon: 'storefront',
    },
    {
      show: true,
      label: 'Subir pedido',
      link: '/main/subir-pedido',
      icon: 'downloads',
    },
    {
      show: true,
      label: 'Avance de ventas',
      link: '/main/avances-ventas',
      icon: 'advance',
    },
    {
      show: true,
      label: 'Reconocimientos',
      link: '/main/reconocimientos',
      icon: 'recognitions',
    },
    {
      show: true,
      label: 'Cuenta corriente',
      link: '/main/cuenta-corriente',
      icon: 'account',
    },
    {
      show: true,
      label: 'Historial de compras',
      link: '/main/historial-compras',
      icon: 'history',
    },
    { show: true, label: 'Facturas', link: '', icon: 'bills' },
    { show: true, label: 'Aliados', link: '/main/aliados', icon: 'allies' },
  ];
  @ViewChild('drawerProfile')
  sidenavProfile!: MatSidenav;
  @ViewChild(MatMenuTrigger) myMenu!: MatMenuTrigger;
  routinesList: Routines | null | {} = null;
  routinesListLoading: boolean = false;
  routinesMessageError: boolean = false;
  constructor(
    private _authService: AuthService,
    private _selectedClientService: SelectedClientService,
    private router: Router,
    private _localStorageService: LocalStorageService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    public dialogCloseSesion: MatDialog
  ) {}

  ngOnInit() {
    this.initDataSelectclient();
    this.initDataRoutines();
    this.labelVisible = true;
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }
 
  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      // El usuario ha vuelto a la pantalla
      const lastFetchDate = localStorage.getItem('lastFetchDate');
      const today = new Date().toDateString();
      if (lastFetchDate !== today) {
        this.initDataRoutines();
      } else {
      }
    } else {
      // El usuario se ha movido fuera de la pantalla
    }
  }

  initDataSelectclient() {
    this.loadingSkeleton = true;
    this.user = this._localStorageService.getItem('user');
    this._selectedClientService
      .getSelectClient(this.user?.code)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.loadingSkeleton = false;
          this.booleanMessageError = false;
          this.listResponseNull = res;
          if (this.listResponseNull) {
            this.dateClients = res.map((element: any) => {
              const phoneNumber = element.phone ? element.phone : '_';
              const countryCode = phoneNumber?.slice(0, 3);
              const firstGroup = phoneNumber?.slice(3, 6);
              const secondGroup = phoneNumber?.slice(6, 9);
              const thirdGroup = phoneNumber?.slice(9);

              const separatedPhoneNumber = `${countryCode} ${firstGroup} ${secondGroup} ${thirdGroup}`;

              console.log(separatedPhoneNumber);
              let temp: any = {
                ...element,
                isSelected: false,
                phone: separatedPhoneNumber,
              };
              return temp;
            });
          }

          this.getFilterData('');
          this._localStorageService.setItem('clients', this.dateClients);
          this.dataConvertClientSelect = this.dateClients[0]?.code;
          this.changeValue(this.dateClients[0]?.code, true);
        },
        (error: any) => {
          this.booleanMessageError = true;
          this.loadingSkeleton = false;
        }
      );
  }
  initDataRoutines() {
    this.routinesList = null;
    this.routinesListLoading = true;
    this.routinesMessageError = false;
    this.user = this._localStorageService.getItem('user');
    this._selectedClientService
      .getRoutinesList(this.user?.code)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          if (res) {
            this.routinesList = res;
          } else {
            this.routinesList = {};
          }
          const today = new Date().toDateString();
          localStorage.setItem('lastFetchDate', today);
          this.routinesListLoading = false;
        },
        (error: any) => {
          this.routinesListLoading = false;
          this.routinesMessageError = true;
        }
      );
  }

  changeValue(clientSelect, initialSelect) {
    let clientSelectInitial = this.dateClients.find(
      (item) => item?.code == clientSelect
    );
    this.clienteSelected(clientSelectInitial, initialSelect);
  }
  clienteSelected(clientSelect: any, initialSelect) {
    this.dataConvertClientSelect = clientSelect?.code;
    let client = {
      ...clientSelect,
      selected: true,
    };
    this.store.dispatch(
      clientsActions.setClients({ clients: client ? client : '' })
    );
    if (initialSelect) {
    } else {
      this.router.navigate(['/main/inicio']);
    }
  }

  search(value: any): void {
    const trimmedValue = value.trim(); // Eliminar espacios en blanco al inicio y al final
    this.getFilterData(trimmedValue);
  }
  getFilterData(value: string) {
    if (value && value.length > 0) {
      this.clientSearch = this.dateClients.filter((client) =>
        String(client.name.toLowerCase()).includes(value.toLowerCase())
      );
    } else {
      this.clientSearch = [...this.dateClients];
    }
  }
  onSelectPanelOpened() {
    this.isOpen = true;
    this.labelVisible = false;
  }
  onSelectPanelClosed() {
    this.isOpen = false;
    this.labelVisible = true;
  }
  clickIcon() {
    this.searchValue = '';
    this.getFilterData('');
  }
  onInputKeydown(event: KeyboardEvent) {
    if (event.key === ' ' && this.searchValue.trim() === '') {
      event.preventDefault(); // Evitar la entrada de espacios al inicio
    }
  }
  redirectToUrlSuggestions() {
    const externalUrl = ' https://alicorpdigital.typeform.com/to/gqMGsl54';
    window.open(externalUrl, '_blank');
  }
  redirectToUrl() {
    const externalUrl =
      'https://grupoalicorp-my.sharepoint.com/:f:/g/personal/lpuertasb_alicorp_com_pe/ElKItpqofvhIjM0-SvJLfsIBaPa3q-QsS2MiLwI3DRutmA?e=ZZ0THi';

    window.open(externalUrl, '_blank');
  }
  logout() {
    const dialogRef = this.dialog.open(DialogCloseSesionComponent, {
      width: '31rem',
      data: {},
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data?.status) {
        this.store.dispatch(authActions.unsetUser());
        this.store.dispatch(authClientActions.unsetUserListClient());
        this.store.dispatch(shoppingActions.unsetCar());
        this.store.dispatch(clientsActions.unsetClients());
        this._authService.logout();
      }
      //
    });
  }
  closeProfile(event: string) {
    this.sidenavProfile.close();
    if (event === 'logout') {
      setTimeout(() => {
        this.openModalCloseSesion();
      }, 350);
    }
  }
  openProfile() {
    this.sidenavProfile.toggle();
  }
  openModalCloseSesion() {
    const dialogRef = this.dialogCloseSesion.open(DialogCloseSesionComponent, {
      width: '31rem',
      data: {},
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data?.status) {
        this.store.dispatch(authActions.unsetUser());
        this.store.dispatch(shoppingActions.unsetCar());
        this.store.dispatch(clientsActions.unsetClients());
        this._authService.logout();
      }
    });
  }
  clientSelect() {
    this.myMenu.closeMenu();
  }
  ngOnDestroy(): void {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}
