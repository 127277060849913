import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GtmEventService } from '@core/analytics/gtm-events.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {
  selectValidarClick = true;
  @Input() list: any;
  @Output() onRedirect = new EventEmitter<any>();
  marcas: any = null;
  constructor(
    private _gtmEventService: GtmEventService,
  ) { }

  ngOnInit() {
  }
  
  ngOnChanges(){
    this.selectValidarClick = true;
  }

  select_category(value) {
    this._gtmEventService.clickCategoryEvent(value.categoryName);
    this.selectValidarClick = false;
    this.marcas = value;
  }

  select_brand(type: any, category, brandCode?, brandName?) {
    brandName ? this._gtmEventService.clickBrandEvent(brandName) : null;
    this.selectValidarClick = true;
    this.onRedirect.emit({type, category, brandCode});
  }

  back_to_category(value) {
    this.selectValidarClick = value;
  }
}
