

import { Client } from '@core/interfaces/selectclient';
import { createAction, props } from '@ngrx/store';

export const setClients  = createAction(
  '[Select Client] set Client',
  props<{clients: Client}>()
);

export const unsetClients = createAction(
  '[Select Client] unset Client',
);