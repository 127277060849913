import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-detail-modal',
  templateUrl: './order-detail-modal.component.html',
  styleUrls: ['./order-detail-modal.component.scss'],
})
export class OrderDetailModalComponent implements OnInit {
  dataRecieved: any;
  modals = [
    {
      img: '⚠️',
      title: 'No es posible Administrar tu pedido en este momento',
      description: 'Por favor vuelve a intentarlo en unos minutos.',
      actions: [
        {
          name: 'Entendido',
          class: 'font-semiBold btn-enter ph-24',
          color: 'accent',
          event: () => this.dialogRef.close(),
        },
      ],
    },
    {
      img: '☝️',
      title: 'Superaste la fecha límite para liberar tu pedido',
      description: 'Recuerda liberar tus pedidos en las fechas indicadas.',
      actions: [
        {
          name: 'Entendido',
          class: 'font-semiBold btn-enter ph-24',
          color: 'accent',
          event: () => this.dialogRef.close(),
        },
      ],
    },
    {
      img: '☝️',
      title: 'No fue posible administrar tu pedido',
      description: '',
      actions: [
        {
          name: 'Entendido',
          class: 'font-semiBold btn-enter ph-24',
          color: 'accent',
          event: () => this.dialogRef.close(),
        },
      ],
    },
    {
      img: '🙌',
      title: 'Tus cambios fueron guardados exitosamente.',
      description: '',
      actions: [
        {
          name: 'Entendido',
          class: 'font-semiBold btn-enter ph-24',
          color: 'accent',
          event: () => {
            this.dataRecieved.redirect();
            this.dialogRef.close();
          },
        },
      ],
    },
    {
      img: '☝️',
      title:
        'Tus cambios fueron guardados, no olvides liberar tu pedido luego.',
      description: '',
      actions: [
        {
          name: 'Entendido',
          class: 'font-semiBold btn-enter ph-24',
          color: 'accent',
          event: () => {
            this.dataRecieved.redirect();
            this.dialogRef.close();
          },
        },
      ],
    },
    {
      img: '⚠️',
      title: '¿Estás seguro que quieres salir sin guardar tus cambios?',
      description:
        '',
      actions: [
        {
          name: 'Si, salir',
          class: 'btn-exit',
          event: () => {
            this.dataRecieved.redirect();
            this.dialogRef.close();
          },
        },
        {
          name: 'No, regresar',
          class: 'font-semiBold btn-enter ph-24',
          color: 'accent',
          event: () => this.dialogRef.close(),
        },
      ],
    },
    {
      img: '⚠️',
      title: 'Algo salió mal, no pudimos guardar tus cambios',
      description: 'Por favor vuelve a intentarlo en unos minutos.',
      actions: [
        {
          name: 'Entendido',
          class: 'font-semiBold btn-enter ph-24',
          color: 'accent',
          event: () => this.dialogRef.close(),
        },
      ],
    },
  ];
  displayedColumns: string[] = ['sku', 'description'];
  constructor(
    public dialogRef: MatDialogRef<OrderDetailModalComponent>,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.dataRecieved = data;
  }

  ngOnInit(): void {
    this.openedStart();
  }

  ngOnDestroy(): void {
    this.closedStart();
  }

  closeDialog() {
    this.dialogRef.close();
    const customButton = document.querySelector('.custom-button');
    if (customButton) {
      customButton.classList.add('no-focus-outline');
    }
  }

  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  save(status, message) {
    this.dialogRef.close({ status: status, message: message });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
