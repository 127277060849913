import { Car } from '@core/interfaces/cars';
import { createAction, props } from '@ngrx/store';

export const setCar  = createAction(
  '[Auth] set Car',
  props<{cars: Car[]}>()
);

export const unsetCar  = createAction(
  '[Auth] unset Car',
);

export const setProduct  = createAction(
  '[Auth] set Product',
  props<{unitBussines: string, product: any,  dateCheckout?:any,
    paymentType? :number}>()
);
