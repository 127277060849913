<section class="d-flex jc-c">
  <div class="ta-c">
    <img src="/assets/image/icon-poll.svg" width="48" height="56" class="icon-modal">
    <p class="font-medium fts-18 account-title">Cuenta Corriente</p>
    <p class="font-book fts-14 line-height-0 m-0 mb-24 text-description">Estimado cliente, recuerda descargar tu estado de cuenta al menos una vez al mes y
      hacer seguimiento a tus documentos.</p>
    <div>
      <button class="btn-green font-medium mr-10 ph-32 pv-10" (click)="download()">Descargar</button>
      <button class="btn-back-new font-medium ph-32 pv-10 fts-17" (click)="exit()">Ok</button>
    </div>
  </div>
</section>