import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-profile-responsive',
  templateUrl: './profile-responsive.component.html',
  styleUrls: ['./profile-responsive.component.scss'],
})
export class ProfileResponsiveComponent implements OnInit {
  @Input() user: any;
  @Input() dateClients: any;
  @Input() optionsMenu: any;
  @Output() closeModalProfile = new EventEmitter<string>();
  constructor( ) {}

  ngOnInit() {}
  
  closeDialog(value:string) {
    this.closeModalProfile.emit(value);
  }
  logout(value:string) {
    this.closeModalProfile.emit(value);
 
  }
  selectClient(value:string) {
    this.closeModalProfile.emit(value);
  }
}
