<div *ngIf="!errorDialog ; else showError">
  <section class="d-flex">
    <div class="content-dialog">
      <div class="button-x justify-content-end d-flex">
        <input
          #hiddenInput
          type="text"
          class="hidden-input"
          (focus)="onHiddenInputFocus()"
        />
        <button
          mat-button
          class="button-x-style custom-button"
          (click)="closeDialog()"
        >
          <span class="material-symbols-rounded fst-32"> close </span>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <div class="img-client-data">
          <img src="assets/image/home-seller/1.storefront.svg" alt="" />
        </div>
        <p class="title-client-data mat-title mb-0">
          {{name}}
        </p>
      </div>
      <div class="container-select">
        <mat-form-field
          appearance="outline"
          class="clientSelect"
          color="accent"
        >
          <mat-select
            [(ngModel)]="clientSelect"
            name="client"
            [ngClass]="{ 'hide-select-arrow': true }"
            [disabled]="clientData?.stores.length === 1"
          >
            <mat-option
              *ngFor="let client of clientData?.stores"
              [value]="client"
            >
              <span>Sucursal {{ client.addressCode }}</span>
              <mat-icon
                *ngIf="clientSelect.addressCode === client.addressCode"
                class="icon-check"
              >
                <img src="assets/image/home-seller/check_regular.svg" alt="" />
              </mat-icon>
            </mat-option>
          </mat-select>
          <span *ngIf="clientData" class="material-symbols-rounded" [ngClass]="{'': clientData?.stores.length !== 1, 'disabled': clientData?.stores.length === 1}">expand_more</span>
        </mat-form-field>
      </div>
      <div *ngIf="clientData; else squeletonList">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column container-list">
              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <p class="d-flex align-items-center mat-title title">Código</p>
                <p *ngIf="clientSelect.code" class="d-flex align-items-center mb-0 description"
                [innerHTML]="splitIntoLines(clientSelect?.code, charactersPerLine)"
                >
                  {{ clientSelect?.code }}
                </p>
                <p *ngIf="!clientSelect.code" class="d-flex align-items-center mb-0 description">
                  ---- ----- -----
                </p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <p class="d-flex align-items-center mat-title title">RUC</p>
                <p
                  *ngIf="clientData.ruc"
                  class="d-flex align-items-center mb-0 description"
                  [innerHTML]="splitIntoLines(clientData?.ruc, charactersPerLine)"
                >
                  {{ clientData?.ruc  }}
                </p>
                <p
                  *ngIf="!clientData.ruc"
                  class="d-flex align-items-center mb-0 description">
                  ---- ----- -----
                </p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <p class="d-flex align-items-center mat-title title">
                  Segmento
                </p>
                <p
                  *ngIf="clientData.segment" 
                  class="d-flex align-items-center mb-0 description"
                  [innerHTML]="splitIntoLines(clientData?.segment, charactersPerLine)"
                >
                  {{ clientData?.segment }}
                </p>
                <p
                  *ngIf="!clientData.segment" 
                  class="d-flex align-items-center mb-0 description"
                >
                  ---- ----- -----
                </p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <p class="d-flex align-items-center mat-title title">
                  Grupo de precios
                </p>
                <p 
                  *ngIf="clientData.schema"
                  class="d-flex align-items-center mb-0 description"
                  [innerHTML]="splitIntoLines(clientData?.schema, charactersPerLine)"
                >
                  {{ clientData?.schema }}
                </p>
                <p 
                  *ngIf="!clientData.schema"
                  class="d-flex align-items-center mb-0 description"
                >
                  ---- ----- -----
                </p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <p class="d-flex align-items-center mat-title title">
                  Provincia
                </p>
                <p
                  *ngIf="clientSelect.region" 
                  class="d-flex align-items-center mb-0 description"
                  [innerHTML]="splitIntoLines(clientSelect?.region, charactersPerLine)"
                >
                  {{ clientSelect?.region  }}
                </p>
                <p
                  *ngIf="!clientSelect.region"
                  class="d-flex align-items-center mb-0 description"
                >
                  ---- ----- -----
                </p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <p class="d-flex align-items-center mat-title title">
                  Distrito
                </p>
                <p
                  *ngIf="clientSelect.district" 
                  class="d-flex align-items-center mb-0 description"
                  [innerHTML]="splitIntoLines(clientSelect?.district, charactersPerLine)"
                >
                  {{ clientSelect?.district }}
                </p>
                <p
                  *ngIf="!clientSelect.district" 
                  class="d-flex align-items-center mb-0 description"
                >
                  ---- ----- -----
                </p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <p class="d-flex align-items-center mat-title title">
                  Dirección
                </p>
                <p
                  *ngIf="clientSelect.address" 
                  class="d-flex align-items-center mb-0 description"
                  [innerHTML]="splitIntoLines(clientSelect?.address, charactersPerLine)"
                >
                  {{ clientSelect?.address ? clientSelect?.address : "---- ----- -----" }}
                </p>
                <p
                  *ngIf="!clientSelect.address" 
                  class="d-flex align-items-center mb-0 description"
                >
                  ---- ----- -----
                </p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <p class="d-flex align-items-center mat-title title">Correo</p>
                <p 
                  *ngIf="clientData.email"
                  class="d-flex align-items-center mb-0 description"
                  [innerHTML]="splitIntoLines(clientData?.email, charactersPerLine)"
                >
                  {{ clientData?.email }}
                </p>
                <p
                  *ngIf="!clientData.email"
                  class="d-flex align-items-center mb-0 description"
                >
                  ---- ----- -----
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #squeletonList>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column container-list">
              <div class="d-flex justify-content-between my-2">
                <p class="mat-title title">Código</p>
                <ngx-skeleton-loader
                  *ngIf="!contentLoaded"
                  [theme]="{ width: '64px', 'border-radius': '5', height: '8px', 'margin-bottom': '0px' }"
                ></ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-between my-2">
                <p class="mat-title title">RUC</p>
                <ngx-skeleton-loader
                  *ngIf="!contentLoaded"
                  [theme]="{ width: '64px', 'border-radius': '5', height: '8px', 'margin-bottom': '0px' }"
                ></ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-between my-2">
                <p class="mat-title title">Segmento</p>
                <ngx-skeleton-loader
                  *ngIf="!contentLoaded"
                  [theme]="{ width: '64px', 'border-radius': '5', height: '8px', 'margin-bottom': '0px' }"
                ></ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-between my-2">
                <p class="mat-title title">Grupo de precios</p>
                <ngx-skeleton-loader
                  *ngIf="!contentLoaded"
                  [theme]="{ width: '64px', 'border-radius': '5', height: '8px', 'margin-bottom': '0px' }"
                ></ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-between my-2">
                <p class="mat-title title">Provincia</p>
                <ngx-skeleton-loader
                  *ngIf="!contentLoaded"
                  [theme]="{ width: '64px', 'border-radius': '5', height: '8px', 'margin-bottom': '0px' }"
                ></ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-between my-2">
                <p class="mat-title title">Distrito</p>
                <ngx-skeleton-loader
                  *ngIf="!contentLoaded"
                  [theme]="{ width: '64px', 'border-radius': '5', height: '8px', 'margin-bottom': '0px' }"
                ></ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-between my-2">
                <p class="mat-title title">Correo</p>
                <ngx-skeleton-loader
                  *ngIf="!contentLoaded"
                  [theme]="{ width: '64px', 'border-radius': '5', height: '8px', 'margin-bottom': '0px' }"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <div *ngIf="clientData; else squeletonCard" class="contacto-container">
        <div class="mat-title contacto-title d-flex align-items-center">
          <mat-icon class="contacto-icon">account_circle</mat-icon>
          Contacto
        </div>
        <div *ngIf="clientSelect.contact" class="mat-title contacto-name mb-0">
          {{clientSelect?.contact}}
        </div>
        <div *ngIf="!clientSelect.contact" class="mat-title contacto-name mb-0">
          ---- ----- -----
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p class="mat-title title mb-0">Teléfono</p>
            </div>
            <div *ngIf="clientSelect.phone">
              <p>{{ formatPhoneNumber(clientSelect?.phone) }}</p>
            </div>
            <div *ngIf="!clientSelect.phone">
              <p>---- ----- -----</p>
            </div>
          </div>
        </div>
      </div>
      <ng-template #squeletonCard>
        <div class="contacto-container">
          <div class="mat-title contacto-title d-flex align-items-center">
            <mat-icon class="contacto-icon">account_circle</mat-icon>
            Contacto
          </div>
          <ngx-skeleton-loader
            *ngIf="!contentLoaded"
            [theme]="{ width: '64px', 'border-radius': '5', height: '16px', 'margin-bottom': '0px' }"
          ></ngx-skeleton-loader>
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mat-title title mb-0">Teléfono</p>
              </div>
              <div>
                <ngx-skeleton-loader
                  *ngIf="!contentLoaded"
                  [theme]="{ width: '64px', 'border-radius': '5', height: '8px', 'margin-bottom': '0px' }"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
</div>
<ng-template #showError>
  <section class="d-flex">
    <div class="content-dialog">
      <div class="button-x justify-content-end d-flex">
        <button mat-button class="button-x-style" (click)="closeDialog()">
          <span class="material-symbols-rounded fst-32"> close </span>
        </button>
      </div>
      <div class="d-flex align-items-center pb-3">
        <div class="img-client-data">
          <img src="assets/image/home-seller/1.storefront.svg" alt="" />
        </div>
        <p class="title-client-data mat-title mb-0">
          {{name}}
        </p>
      </div>
      <mat-divider></mat-divider>
      <div class="error-container ta-c">
        <p class="mat-title error-title mb-5">Algo salió mal</p>
        <p>
          Lo sentimos, no pudimos mostrar los datos del cliente, vuelve a
          intentarlo en unos minutos.
        </p>
        <div class="ta-c">
          <button
            mat-flat-button
            class="font-bold btn-enter ph-24"
            color="accent"
            (click)="getClientData()"
          >
            Reintentar
          </button>
        </div>
      </div>
    </div>
  </section>
</ng-template>
