import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';


/****** Interceptors ******/
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HeaderInterceptor } from './interceptors/header.interceptor';


/****** Services ******/
import { LocalStorageService } from './services/local-storage.service';
import { HttpClienteService } from './services/http-cliente.service';
import { AuthService } from './services/authentication/auth.service';
import { AppErrorHandler } from '@core/services/app-error-handler.service';


/****** Guards ******/
import { LoginGuard } from './guards/login.guard.service';

/****** Other Modules ******/
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CategoriesService } from './services/categories/categories.service';
import { ProductsService } from './services/products/products.service';
import { BusinessUnitGuard } from './guards/businessUnit.guard.service';
import { UtilsService } from './services/utils/utils.service';
import { GtmEventService } from './analytics/gtm-events.service';
import { AuthListClientService } from './services/authentication/authListClient.service';
import { SelectedClientService } from './services/selected-client/selected-client.service';
import { SummaryGuard } from './guards/summary.guard.service';
import { SalesProgressService } from './guards/sales-progress.guard.service';

const SERVICES = [
  LocalStorageService,
  HttpClienteService,
  AuthService,
  AuthListClientService,
  CategoriesService,
  ProductsService,
  UtilsService,
  GtmEventService,
  SelectedClientService
];

const GUARDS = [
  LoginGuard,
  BusinessUnitGuard,
  SummaryGuard,
  SalesProgressService
]

const MODULES = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule
]
const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
];
@NgModule({
  // declarations: [...COMPONENTS],
  imports: [
    ...MODULES
  ],
  providers: [
    ...SERVICES,
    ...INTERCEPTORS,
    ...GUARDS,
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
  declarations: [
    
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
