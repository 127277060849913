<div id="WAButton" [ngClass]="config == 0 ? '' : 'floating-wpp-2'"  class="floating-wpp">
  <button mat-fab class="whatsapp-button" (click)="whatsapp()">
    <i class="pi pi-whatsapp" style="font-size: 2rem"></i>
  </button>
  <div class="floating-wpp-popup" [ngClass]="active ? 'active': ''">
      <div class="floating-wpp-head"><span>
              Atención a clientes en WhatsApp</span>
          <mat-icon class="close" (click)="close()">close</mat-icon>
      </div>
      <div class="floating-wpp-message show-box-shadow m-16 p-8">¡Bienvenido a nuestro canal virtual Atención
          Clientes Directos! ¿Cómo podemos ayudarte?</div>
      <div class="floating-wpp-input-message">
          <div class="d-flex jc-sb ai-center mh-8 pv-8">
              <textarea class="p-8" [(ngModel)] = "text"
                  id="textWhatsapp">{{text}}</textarea>
              <div class="floating-wpp-btn-send d-flex jc-sb ai-center ta-c pl-16">
                  <mat-icon (click)="openWhatsapp()" class="send">send</mat-icon>
              </div>
          </div>

      </div>
  </div>
</div>
