import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@core/store/app.reducer';
import { selectLoginUserInfo } from '@core/store/app.selectors';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ClientDataModalComponent } from '../modals/client-data-modal/client-data-modal.component';

@Component({
  selector: 'app-menu-responsive',
  templateUrl: './menu-responsive.component.html',
  styleUrls: ['./menu-responsive.component.scss'],
})
export class MenuResponsiveComponent implements OnInit {
  @Input() optionsMenu: any;
  @Output() closeDialogemit = new EventEmitter<string>();
  settingClientSuscription$!: Subscription;
  settingClient: any;
  constructor(
    public clientDataModal: MatDialog,
    private router: Router,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.getSettingClient();
  }
  closeDialog() {
    this.closeDialogemit.emit('');
  }
  getSettingClient() {
    this.unsubscribeFromStore();
    this.settingClientSuscription$ = this.store
      .pipe(select(selectLoginUserInfo))
      .subscribe((value) => {
        this.settingClient = value.setting;
        this.optionsMenu.find(
          (option) => option.label === 'Avance de ventas'
        ).show = this.settingClient.isAdvance;

        // Verifica el valor de isBenefit y cambia show en consecuencia
        this.optionsMenu.find(
          (option) => option.label === 'Reconocimientos'
        ).show = this.settingClient.isBenefit;
        this.optionsMenu.find((option) => option.label === 'Aliados').show =
          this.settingClient.isAlly;
      });
  }
  action(option: any) {
    switch (option.label) {
      case 'Datos del cliente':
        const dialogRef = this.clientDataModal.open(ClientDataModalComponent, {
          width: '398px',
          height: '44rem',
          maxWidth: '90vw',
          data: {},
        });
        dialogRef.afterClosed().subscribe((data: any) => {
          if (data?.status) {
            console.log(data);
          }
        });
        break;

      case 'Historial de compras':
        this.router.navigate(['/main/historial-compras']);
        break;
      case 'Cuenta corriente':
        this.router.navigate(['/main/cuenta-corriente']);
        break;
      case 'Facturas':
        window.open('http://asp401r.paperless.com.pe/PortalAlicorp/', '_blank');
        break;

      case 'Subir pedido':
        this.router.navigate(['/main/subir-pedido']);
        break;
      case 'Avance de ventas':
        this.router.navigate(['/main/avances-ventas']);
        break;
      case 'Reconocimientos':
        this.router.navigate(['/main/reconocimientos']);
        break;
        case 'Inicio':
          this.router.navigate(['/main/inicio']);
          break;
      case 'Aliados':
        this.router.navigate(['/main/aliados']);
        break;
      default:
        break;
    }
  }
  unsubscribeFromStore() {
    if (this.settingClientSuscription$) {
      this.settingClientSuscription$.unsubscribe();
    }
  }
  ngOnDestroy(): void {
    this.settingClientSuscription$.unsubscribe();
  }
}
