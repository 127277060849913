import * as SelectClientActions from './selectClient.actions';
import { createReducer, on } from '@ngrx/store';
import { SelectClientState } from '@core/interfaces/selectclient';

export const clientState: SelectClientState = {
  client: localStorage.getItem(`alicorp-clientSelect`)
    ? JSON.parse(localStorage.getItem(`alicorp-clientSelect`) || '{}')
    : null,
};

const _selectReducer = createReducer(
  clientState,
  on(SelectClientActions.setClients, (state, client) =>  {
    localStorage.setItem('alicorp-clientSelect', JSON.stringify(client.clients))
    return {...state, client:client.clients}
  }),
);

export function selectReducer(state: any, action: any) {
  return _selectReducer(state, action);
}
