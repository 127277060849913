import { Injectable } from "@angular/core";
import { AuthService } from "../authentication/auth.service";

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  constructor(
    private _authService: AuthService,
    ) { }
  // Función que formatea numeros
  static formatNumber(n) {
    var numberFormat = new Intl.NumberFormat('en-US');
    var formatted = (numberFormat.format(n));
    return formatted.toString();
  }

  // Función que formatea numeros de forma exacta
  static formatTotalNumber(n) {
    var numberFormat = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    var formatted = (numberFormat.format(n));
    return formatted.toString();
  }
  // Lista blanca que devuelve el monto de percepción
  getPerceptionPrice () {
    const {code} = this._authService.getUser();
    const whiteList = [1000019353,1000019342,1000018787,1000018661,1000010265,1000010223,1000010141,1000009790,1000008768,1000008601,1000007736,1000001166];
    const codeFilter = whiteList.filter(whiteCode => whiteCode == code)
    if ( codeFilter[0] )
      return 0.005
    else
      return 0.02
  }
}

