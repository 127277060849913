import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotFoundGuard implements CanLoad {
  public allowedRoutesForVendedor = [
    'main',
    'main/inicio',
    'tienda',
    'main/cliente-seleccionado',
    'main/historial-compras',
    'main/historial-compras/detalle-historial',
    'main/subir-pedido',
    'main/cuenta-corriente',
    'main/confirmacion',
    'main/checkout',
    'main/avances-ventas',
    'main/aliados',
    'main/reconocimientos',
    'seleccionar-cliente',
    'detalle-rutinas',
    'tienda/busqueda',
    'main/historial-compras/detalle-historial/gestiona-tu-pedido'
  ];

  // Lista de rutas permitidas para el usuario tipo 'Cliente'
  public allowedRoutesForCliente = [
    'unidades-de-negocio',
    'tienda',
    'tienda/mi-carrito',
    'tienda/pedido-realizado',
    'herramientas',
    'tienda/busqueda',
    'herramientas/subir',
    'cuenta',
    'cuenta/historial',
    'cuenta/facturacion',
    'cuenta/perfil/credenciales',
    'cuenta/avances-ventas',
    'cuenta/premios',
    'cuenta/historial/detalle',
  ];

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  private isAllowedRoute(requestedRoute: string, allowedRoutes: string[]): boolean {
    // Remueve los parámetros de consulta de la ruta solicitada
    const baseRoute = requestedRoute.split('?')[0];
    return allowedRoutes.includes(baseRoute);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const user = this.localStorageService.getItem('user');
    const requestedRoute = segments.map(segment => segment.path).join('/');
    if (!requestedRoute) {
      this.router.navigate(['/login']);
      return true;
    }
    if (user.type === 'Vendedor') {
      if (!this.isAllowedRoute(requestedRoute, this.allowedRoutesForVendedor)) {
        window.history.pushState({}, '', '/seleccionar-cliente');
        this.router.navigate(['/404']);
        return false; // Impide la carga del módulo
      }
    } else if (user.type === 'Cliente') {
      if (!this.isAllowedRoute(requestedRoute, this.allowedRoutesForCliente)) {
        // window.history.pushState({}, '', '/unidades-de-negocio');
        this.router.navigate(['/404']);
        return false; // Impide la carga del módulo
      }
    }

    return true; // Permite la carga de módulos para rutas válidas
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const user = this.localStorageService.getItem('user');
    const requestedRoute = route.url.map(segment => segment.path).join('/');
    if (!requestedRoute) {
      this.router.navigate(['/login']);
      return true;
    }
    if (user.type === 'Vendedor') {
      if (!this.isAllowedRoute(requestedRoute, this.allowedRoutesForVendedor)) {
        window.history.pushState({}, '', '/seleccionar-cliente');
        this.router.navigate(['/404']);
        return false; // Impide la carga del módulo
      }
    } else if (user.type === 'Cliente') {
      if (!this.isAllowedRoute(requestedRoute, this.allowedRoutesForCliente)) {
        window.history.pushState({}, '', '/unidades-de-negocio');
        this.router.navigate(['/404']);
        return false; // Impide la carga del módulo
      }
    }
    return true; // Permite la carga de módulos para rutas válidas
  }
}
