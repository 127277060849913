import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-list-manage-products',
  templateUrl: './skeleton-list-manage-products.component.html',
  styleUrls: ['./skeleton-list-manage-products.component.scss']
})
export class SkeletonListManageProductsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
