<section class="container-cards">
  <ng-container *ngFor="let item of [1, 2, 3]">
    <div class="container-border-line pv-24 ph-20 show-desktop-tablet">
      <div class="d-flex ai-center justify-content-between">
        <div class="align-items-center d-flex justify-content-center">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '32px',
                width: '100px',
                'border-radius': '8px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="p-0 m-0 fts-14">
          <div class="vw-100 align-items-center">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              class="loader-unit"
              [theme]="{
                height: '16px',
                width: '64px',
                'border-radius': '16px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="vw-100">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            [theme]="{
              height: '8px',
              width: '100%',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
      <div class="d-flex ai-center justify-content-between vw-100">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '48px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
        <div class="vw-100 align-items-center d-flex justify-content-end">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '48px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
      <div class="d-flex ai-center justify-content-between">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '64px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
        <div class="vw-100 align-items-center d-flex justify-content-end">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '64px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
      <!-- Línea divisoria -->
      <hr class="dividing-line-solid my-2 show-desktop" />
      <div class="d-flex ai-center justify-content-between">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '128px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
        <div class="vw-100 align-items-center d-flex justify-content-end">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '64px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
      <div class="d-flex ai-center justify-content-between">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '128px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
        <div class="vw-100 align-items-center d-flex justify-content-end">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '64px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="show-mobile">
    <div class="d-flex justify-content-start">
      <div class="d-flex align-items-center mr-8">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '32px',
            width: '117px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>

      <div class="d-flex align-items-center mr-8">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '32px',
            width: '92px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>

      <div class="d-flex align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '32px',
            width: '61px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="container-border-line pv-24 ph-20 show-mobile">
    <div class="d-flex ai-center justify-content-between">
      <div class="align-items-center d-flex justify-content-center">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '32px',
              width: '100px',
              'border-radius': '8px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
      <div class="p-0 m-0 fts-14">
        <div class="vw-100 align-items-center">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            class="loader-unit"
            [theme]="{
              height: '16px',
              width: '64px',
              'border-radius': '16px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="vw-100">
      <div class="vw-100 align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '8px',
            width: '100%',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="d-flex ai-center justify-content-between vw-100">
      <div class="vw-100 align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          class="loader-unit"
          [theme]="{
            height: '16px',
            width: '48px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div class="vw-100 align-items-center d-flex justify-content-end">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          class="loader-unit"
          [theme]="{
            height: '16px',
            width: '48px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="d-flex ai-center justify-content-between">
      <div class="vw-100 align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          class="loader-unit"
          [theme]="{
            height: '16px',
            width: '64px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div class="vw-100 align-items-center d-flex justify-content-end">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          class="loader-unit"
          [theme]="{
            height: '16px',
            width: '64px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <!-- Línea divisoria -->
    <hr class="dividing-line-solid my-2 show-desktop" />
    <div class="d-flex ai-center justify-content-between">
      <div class="vw-100 align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          class="loader-unit"
          [theme]="{
            height: '16px',
            width: '128px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div class="vw-100 align-items-center d-flex justify-content-end">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          class="loader-unit"
          [theme]="{
            height: '16px',
            width: '64px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="d-flex ai-center justify-content-between">
      <div class="vw-100 align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          class="loader-unit"
          [theme]="{
            height: '16px',
            width: '128px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div class="vw-100 align-items-center d-flex justify-content-end">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          class="loader-unit"
          [theme]="{
            height: '16px',
            width: '64px',
            'border-radius': '16px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</section>