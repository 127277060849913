import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';
@Injectable({
  providedIn: 'root',
})
export class SelectedClientService {
  private url = environment.apiURL;
  constructor(private _http: HttpClienteService) { }

  getSelectClient(id: any) {
   const query = `${this.url}/wcl-seller/customer-list?code=${id}`;
    return this._http.get(query);
  }

  getRoutinesList(code: string | number) {
  //  const query =' https://demo0160928.mockable.io/rutinas';
    const query = `${this.url}/wcl-document/routines-by-seller-code?code=${code}`;
     return this._http.get(query);
   }
}
