import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss']
})
export class AlertMessagesComponent implements OnInit {
  @Input() type!: 'loading' | 'alert'| 'update';
  @Input() alertIcon!: string;
  @Input() messageLoading!: string;
  @Input() boldMessageLoading!: string;
  @Input() message!: string;
  @Input() boldMessage!: string;
  @Input() extraMessage: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
