<div class="container-business-unit mt-16 p-24">
  <div
    class="d-flex align-items-center justify-content-between"
  >
    <div class="d-flex align-items-center">
      <ngx-skeleton-loader
          [theme]="{
            width: '27px',
            'border-radius': '5',
            height: '27px',
            'margin-bottom': '0px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      <span class="text-show font-semiBold fts-16">
        <ngx-skeleton-loader
          [theme]="{
            width: '40px',
            'border-radius': '5',
            height: '12px',
            'margin-bottom': '0px'
          }"
        ></ngx-skeleton-loader>
      </span>
    </div>
    <div class="d-flex align-items-center">
      <span class="font-semiBold pr-8 fts-14"
        >
        <ngx-skeleton-loader
          [theme]="{
            width: '70px',
            'border-radius': '5',
            height: '12px',
            'margin-bottom': '0px'
          }"
        ></ngx-skeleton-loader>
      </span
      >
      <span class="fts-14">
        <ngx-skeleton-loader
          [theme]="{
            width: '70px',
            'border-radius': '5',
            height: '12px',
            'margin-bottom': '0px'
          }"
        ></ngx-skeleton-loader>
      </span>
    </div>
  </div>
  <div class="mt-10">
    <ngx-skeleton-loader
      [theme]="{
        width: '100%',
        'border-radius': '5',
        height: '10px',
        'margin-bottom': '0px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div
    class="d-flex justify-content-between mt-8 container-card-account"
  >
    <div class="fts-14">
      <div class="font-semiBold">
        <ngx-skeleton-loader
          [theme]="{
            width: '80px',
            'border-radius': '5',
            height: '12px',
            'margin-bottom': '0px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader
          [theme]="{
            width: '80px',
            'border-radius': '5',
            height: '12px',
            'margin-bottom': '0px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="fts-14">
      <div class="font-semiBold">
        <ngx-skeleton-loader
          [theme]="{
            width: '80px',
            'border-radius': '5',
            height: '12px',
            'margin-bottom': '0px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader
          [theme]="{
            width: '80px',
            'border-radius': '5',
            height: '12px',
            'margin-bottom': '0px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>