import * as ShoppingActions from './shoppingCar.actions';
import { createReducer, on } from '@ngrx/store';
import { CarsState } from '@core/interfaces/cars';

export const carsState: CarsState = {
  cars: localStorage.getItem('alicorp-cars') ? JSON.parse(localStorage.getItem('alicorp-cars') || '[]') : [],
}

const _searchReducer = createReducer(
  carsState,
  on(ShoppingActions.setCar, (state, { cars }) => {
    localStorage.setItem('alicorp-cars', JSON.stringify(cars));
    return { ...state, cars };
  }),
  on(ShoppingActions.unsetCar, (state) => {
    localStorage.removeItem('alicorp-cars');
    return { ...state, cars: [] };
  }),
  on(ShoppingActions.setProduct, (state, payload) => {
    const updatedCars = state.cars.map((car) => {
      if (car.unitBussines === payload.unitBussines) {
        return {
          ...car,
          products: car.products.map(product => {
            if (product.sku === payload.product.sku) {
              return payload.product;
            }
            return product;
          }),
          dateCheckout: payload.dateCheckout ? payload.dateCheckout : undefined, // Actualiza la fecha si se proporciona
          paymentType: payload.paymentType ? payload.paymentType : undefined // Actualiza el tipo de pago si se proporciona
        };
      }
      return car;
    });

    localStorage.setItem('alicorp-cars', JSON.stringify(updatedCars));
    return { ...state, cars: updatedCars };
  })
);

export function searchReducer(state, action) {
  return _searchReducer(state, action);
}
