import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';

@Injectable({
  providedIn: 'root',
})
export class CheckingAccountService {
  private url = environment.apiURL;
  private isAccessAllowed = false;
  private isFinalRouteVisited = false;

  constructor(private _http: HttpClienteService) {}

  getCheckingAccount(code: any) {
    const query = `${this.url}/wcl-customer/get-checking-account?code=${code}`;
    return this._http.get(query);
  }
 

  setAccessAllowed(value: boolean) {
    this.isAccessAllowed = value;
  }

  isAccessPermitted(): boolean {
    return this.isAccessAllowed;
  }

}
