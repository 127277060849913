import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, Renderer2  } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface errors {
  sku: string;
  description: string;
}

@Component({
  selector: 'app-check-stock-modal',
  templateUrl: './check-stock-modal.component.html',
  styleUrls: ['./check-stock-modal.component.scss']
})
export class CheckStockModalComponent implements OnInit, OnDestroy {

  dataRecieved: any ;
  modals =
    [
      {
        img: 'assets/image/modal/loader-upload.svg',
        title: 'Un momento por favor...',
        description: 'Estamos comprobando el stock de tu carrito',
      },
      {
        img: 'assets/image/modal/icon-check.svg',
        title: 'Genial, stock comprobado',
        description: 'Hemos realizado la comprobación de tu carrito y contamos con todo el stock de tus productos agregados.',
        actions: [{
          name: 'OK! ENTIENDO',
          class: 'btn-green font-medium',
          event: () => this.save(1, "success")
        }],
      },{
        img: 'assets/image/modal/icon-alert-new.svg',
        title: 'Algunos productos no están disponibles',
        description: 'Hemos encontrado que los siguientes productos se encuentran <span class="red-alert">SIN STOCK</span>, de momento se actualizará la lista de tu carrito para continuar tu pedido.',
        actions: [{
          name: 'OK! ENTIENDO',
          class: 'btn-green font-medium',
          event: () => this.save(1, "success")
        }],
      },{
        img: 'assets/image/modal/icon-alert-new.svg',
        title: 'Algunos productos no están disponibles',
        description: 'Hemos encontrado los siguientes productos ya no se encuentran disponibles, puedes omitirlos y <span class="bold-alert">continuar</span> o <span class="bold-alert">cancelar</span> para hacer seguir comprando.',
        actions: [{
          name: 'CANCELAR',
          class: 'btn-back-new fts-16 font-medium',
          event: () => this.save(2, "cancel")
        },{
          name: 'CONTINUAR',
          class: 'btn-green font-medium',
          event: () => this.save(1, "success")
        }],
      }]
  dataSource : errors[] = []
  displayedColumns: string[] = ['sku', 'description'];
  constructor(
    public dialogRef: MatDialogRef<CheckStockModalComponent>,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.dataRecieved = data;
      if(data.status == 2 || data.status == 3){
        this.dataSource = data.skuError
      }
    }

  ngOnInit(): void {
    this.openedStart()
  }
  
  ngOnDestroy(): void {
    this.closedStart()
  }

  
  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  save(status, message){
    this.dialogRef.close({status:status, message: message});
  }
  onNoClick(){
    this.dialogRef.close(false);
  }
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
