<div class="zeynep" [ngClass]="selectValidarClick ?'show-overflow-y':'hidden-overflow-y'">
  <div class="categories">
    <mat-toolbar class="container-categories">
      <mat-toolbar-row class="container-title-categories cursor-pointer">
        <a class="vw-100 d-flex ai-center nav-button ph-16">
          <img class="icon-nav-white pr-8" src="assets/image/home/nav/icon-menu.svg">
          <span class="font-book fts-16">Categorías</span>
        </a>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-nav-list *ngIf="list" class="pt-0">
      <mat-list class="pt-0" *ngIf="list.length > 0; else listSqueleton">
        <mat-list-item class="container-categories-list font-medium" *ngFor="let value of list">
          <a class="image-arrow vw-100 ta-s text-ellipsis" (click)="select_category(value)">{{value.categoryName}}
          </a>
        </mat-list-item>
      </mat-list>
      <ng-template class="pt-0" #listSqueleton>
        <ngx-skeleton-loader count="24" appearance="line" width="268px" style="display: flex; flex-direction: column;"></ngx-skeleton-loader>
      </ng-template>
    </mat-nav-list>
  </div>
  

  <div [ngClass]="selectValidarClick ?'sub-menu':'opened'">
    <mat-toolbar class="container-categories">
      <mat-toolbar-row class="container-title-categories cursor-pointer">
        <button class="vw-100 d-flex ai-center container-button-menu ph-16 sub-menu-header fts-16 font-book"
          (click)="back_to_category(true)">
          <img class="icon-arrow-white-back pr-8" src="assets/image/home/list-categories/menu-back.svg">
          <span class="font-book fts-16">Volver a Categorías</span>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-nav-list class="pt-0">
      <mat-list class="pt-0">
        <mat-list-item class="selected-category-name font-medium" (click)="select_brand('category', marcas)">
          <a class="vw-100 ta-s text-ellipsis">{{marcas?.categoryName}}
          </a>
        </mat-list-item>
        <mat-list-item class="container-categories-list font-medium" *ngFor="let value of marcas?.brands"
          (click)="select_brand('brand', marcas, value.brandCode, value.brandName )">
          <a class="vw-100 ta-s text-ellipsis">{{value.brandName}}
          </a>
        </mat-list-item>
      </mat-list>
    </mat-nav-list>
  </div>
</div>