<div class="business-unities-container d-flex jc-center ta-c hp-100 ai-center">
    <div class="container-box">
        <p class="text-header-title"><span class="font-medium">¡Genial!</span>Ahora solo elige una unidad de negocio
            para poder empezar</p>
        <div class="text-description mb-48">
            <p>Si perteneces a más de una, descuida podrás cambiarla en cualquier momento
            </p>
        </div>
        <div class="d-flex jc-center ta-c ai-center card">
            <div *ngFor="let unit of businessUnities; let i= index" class="d-flex mat-card-content m-12"
                (click)="selectUnit(unit)">
                <mat-card class="mat-card-box" (mouseenter)="unit.hover = true" (mouseleave)="unit.hover = false">
                    <div class="p-16">
                        <img *ngIf="!unit.hover" class="card-image" src="assets/image/business-unities/{{unit.image}}.svg">
                        <img *ngIf="unit.hover" class="card-image" src="assets/image/business-unities/{{unit.image}}-hover.svg">
                        <p class="mt-5 font-medium" [ngClass]="unit.hover?' text-description-hover':' text-description'"> {{unit.name}}</p>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>