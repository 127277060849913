<!-- desktop and table -->
<div class="products-container">
  <div class="show-desktop-tablet mt-16">
    <div class="header-table d-flex fts-16 pv-0 ph-24">
      <span class="pl-8 description-custom">Descripción</span>
      <span class="ta-c jc-center count-custom">Cantidad</span>
      <span class="last-colum ta-c jc-center price-custom p-24">
        Precio factura<br />
        con IGV
      </span>
      <div class="remove-custom"></div>
    </div>

    <div class="container-table-body">
      <div>
        <div class="body-history d-flex ai-center ph-24 pv-16">
          <div class="d-flex description-custom">
            <div class="ta-c d-flex align-items-center">
              <img appImageBroke [src]="'assets/image/search/photo-empty.svg'" width="64" height="64" />
            </div>
            <div class="pl-16">
              <div class="description-text description-column">
                <span class="cart-unid unity-p font-semiBold">
                  <div class="d-flex align-items-center justify-content-start vw-100">
                    <ngx-skeleton-loader count="1" [theme]="{
                        height: '8px',
                        width: '60px',
                        'border-radius': '8px',
                        ' margin-bottom': '0px'
                      }">
                    </ngx-skeleton-loader>
                  </div>
                </span>
                <span>
                  <div class="d-flex align-items-center justify-content-start vw-100">
                    <ngx-skeleton-loader count="1" [theme]="{
                        height: '8px',
                        width: '314px',
                        'border-radius': '8px',
                        ' margin-bottom': '0px'
                      }">
                    </ngx-skeleton-loader>
                  </div>
                </span>
              </div>
              <div class="color-primary">
                <span class="d-flex">
                  <div class="d-flex align-items-center justify-content-start">
                    <ngx-skeleton-loader count="1" [theme]="{
                        height: '8px',
                        width: '60px',
                        'border-radius': '8px',
                        ' margin-bottom': '0px'
                      }">
                    </ngx-skeleton-loader>
                  </div>
                  <!-- <div class="d-flex align-items-center justify-content-start">&nbsp;&nbsp;&nbsp;&nbsp;
                    <ngx-skeleton-loader count="1" [theme]="{
                        height: '8px',
                        width: '60px',
                        'border-radius': '8px',
                        ' margin-bottom': '0px'
                      }">
                    </ngx-skeleton-loader>
                  </div> -->
                </span>
              </div>
            </div>
          </div>
          <div class="count-custom ta-c">
            <div class="d-flex align-items-center justify-content-center vw-100">
              <ngx-skeleton-loader count="1" [theme]="{
                  height: '48px',
                  width: '80px',
                  'border-radius': '8px',
                  ' margin-bottom': '0px'
                }">
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="price-custom d-flex justify-content-center price-custom-color font-bold-dali fts-14">
            <div class="d-flex align-items-center justify-content-center vw-100">
              <ngx-skeleton-loader count="1" [theme]="{
                  height: '8px',
                  width: '60px',
                  'border-radius': '8px',
                  ' margin-bottom': '0px'
                }">
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="remove-custom">
            <img src="assets/svg/delete-manage-disabled.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile -->
  <div class="show-mobile mt-24">
    <div>
      <div class="ph-16 pv-16 body-history">
        <div class="d-flex jc-sb">
          <div class="d-flex align-items-center">
            <div class="img-photo">
              <img appImageBroke [src]="'assets/image/search/photo-empty.svg'" width="64" height="64" />
            </div>
            <div class="description ph-16">
              <div class="fts-14 description-text">
                <div class="d-flex align-items-center justify-content-start vw-100">
                  <ngx-skeleton-loader count="1" [theme]="{
                      height: '8px',
                      width: '60px',
                      'border-radius': '8px',
                      ' margin-bottom': '0px'
                    }">
                  </ngx-skeleton-loader>
                </div>
              </div>

              <div class="color-primary ">
                <span class="fts-16">
                  <div class="d-flex align-items-center justify-content-start vw-100 description-text mt-8">
                    <ngx-skeleton-loader count="1" [theme]="{
                        height: '8px',
                        width: '200px',
                        'border-radius': '8px',
                        ' margin-bottom': '0px'
                      }">
                    </ngx-skeleton-loader>
                    
                  </div>
                  <ngx-skeleton-loader count="1" [theme]="{
                    height: '8px',
                    width: '80px',
                    'border-radius': '8px',
                    ' margin-bottom': '0px'
                  }">
                </ngx-skeleton-loader>
                </span>
              </div>
              <div class="color-primary description-text">
                <span class="fts-16 d-flex">
                  <div class="d-flex align-items-center justify-content-start">
                    <ngx-skeleton-loader count="1" [theme]="{
                        height: '8px',
                        width: '60px',
                        'border-radius': '8px',
                        ' margin-bottom': '0px'
                      }">
                    </ngx-skeleton-loader>
                  </div>
                  <!-- <div class="d-flex align-items-center justify-content-start">&nbsp;&nbsp;&nbsp;&nbsp;
                    <ngx-skeleton-loader count="1" [theme]="{
                        height: '8px',
                        width: '60px',
                        'border-radius': '8px',
                        ' margin-bottom': '0px'
                      }">
                    </ngx-skeleton-loader>
                  </div> -->
                </span>
              </div>
            </div>
          </div>
          <div class="remove-custom d-flex align-items-center">
            <img src="assets/svg/delete-manage-disabled.svg" alt="" />
          </div>
        </div>
        <hr class="dividing-line mb-8" />
        <div class="d-flex second-section">
          <div class="size-30 ta-s">
            <div class="d-flex align-items-center justify-content-start vw-100">
              <ngx-skeleton-loader count="1" [theme]="{
                  height: '48px',
                  width: '80px',
                  'border-radius': '8px',
                  ' margin-bottom': '0px'
                }">
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="price-custom fts-14 ta-r">
            <span>Precio factura con IGV</span><br />
            <span class="price-custom-color font-bold-dali"> 
              <div class="d-flex align-items-center justify-content-end vw-100">
                <ngx-skeleton-loader count="1" [theme]="{
                    height: '8px',
                    width: '60px',
                    'border-radius': '8px',
                    ' margin-bottom': '0px'
                  }">
                </ngx-skeleton-loader>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>