import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GtmEventService } from '@core/analytics/gtm-events.service';
import { UtilsService } from '@core/services/utils/utils.service';

@Component({
  selector: 'app-edit-shopping-cart-modal',
  templateUrl: './edit-shopping-cart-modal.component.html',
  styleUrls: ['./edit-shopping-cart-modal.component.scss']
})
export class EditShoppingCartModalComponent implements OnInit, OnChanges {
  @ViewChildren('referenceInput2', { read: ElementRef })
  referenceInput2!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChildren('referenceInputResponsive', { read: ElementRef })
  referenceInputResponsive!: QueryList<ElementRef<HTMLInputElement>>;

  private _data: any;
  private localProducts: any[] = []; // Copia local de productos para trabajar dentro del modal
  products: any[] = [];
  private replacedProducts: any[] = [];
  private deletedProducts: any[] = [];
  repeatCount = Array(3);
  repeatCountMobile = Array(2);
  inStockProducts: any[] = [];
  outOfStockProducts: any[] = [];
  suggestedVisible: boolean[] = [];
  firstChange = false;

  constructor(
    public dialogRef: MatDialogRef<EditShoppingCartModalComponent>,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private _gtmEventService: GtmEventService,
    @Inject(MAT_DIALOG_DATA) public initialData: any
  ) {
    this._data = initialData;
    this.localProducts = JSON.parse(
      JSON.stringify(initialData.localCar.products)
    ); // Hacer una copia profunda de los productos iniciales para trabajar dentro del modal
  }

  ngOnInit(): void {
    if (this._data) {
      this.products = JSON.parse(JSON.stringify(this.localProducts)); // Usar la copia local de los productos
      this.separateProducts(this._data);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.firstChange) {
      this.firstChange = true;
    } else {
      this.ref.detectChanges();
    }
  }

  get data(): any {
    return this._data;
  }

  set data(value: any) {
    this._data = value;
    this.products = JSON.parse(JSON.stringify(this.localProducts)); // Usar la copia local de los productos
    this.separateProducts(value);
  }

  separateProducts(data: any): void {
    if (data && Array.isArray(data.modalData)) {
      this.products = this.mergeProductData(this.products, data.modalData);
      this.inStockProducts = data.modalData.filter((product) => product.stock);
      this.outOfStockProducts = data.modalData.filter(
        (product) => !product.stock
      );
    }
  }

  evaluateValue(p, index) {
    if (p.count == '' || parseFloat(p.count) === 0) {
      this.changeCount('1', p, index);
      const refInput = this.referenceInput2.find((_, i) => i == index);
      const refInputResponsive = this.referenceInputResponsive.find(
        (_, i) => i == index
      );
      refInput?.nativeElement.classList.add('animation');
      refInputResponsive?.nativeElement.classList.add('animation');
    }
  }

  validateStockReal(value: number, product: any): string {
    const replace = String(product).replace(/[^0-9-.]/g, '');
    if (value <= 0) {
      return 'Sin Stock';
    }
    if (value > 0 && value < parseInt(replace)) {
      return `Stock máx ${UtilsService.formatNumber(value)}`;
    }
    return '';
  }

  formatNumber(value) {
    return UtilsService.formatNumber(value);
  }

  countProductsOutStock(): number {
    return this.products.reduce(
      (count, product) =>
        this.validateStockReal(product.stockReal, product.count) !== ''
          ? count + 1
          : count,
      0
    );
  }

  mouseUp(e, p) {
    if (p.count == 0 || p.count == '') {
      p.count = '1';
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  validateInsufficientStock(stock: number, count: string): boolean {
    const replace = String(count).replace(/[^0-9-.]/g, '');
    return stock > 0 && stock < parseInt(replace);
  }

  deleteProduct(product: any, index: any): void {
    if(product.skuParent){
      const originalProduct = this.localProducts.find(p => p.sku === product.skuParent);
      this.deletedProducts.push(originalProduct); 
    } else {
      this.deletedProducts.push(product); 
    }
    this.replacedProducts = this.replacedProducts.filter(p => p.sku !== product.sku);
    this.products = this.products.filter((p) => p !== product);
  }

  toggleSuggested(index: number): void {
    this.suggestedVisible[index] = !this.suggestedVisible[index];
  }

  mergeProductData(products: any[], modalData: any[]): any[] {
    const modalDataMap = new Map();
    modalData.forEach((item) => modalDataMap.set(item.sku, { ...item }));

    return products.map((product) => {
      const countString = String(product.count).replace(/,/g, '');
      const count = Number(countString);
      const modalItem = modalDataMap.get(product.sku);
      if (modalItem && modalItem.suggestedProducts) {
        const suggestedProducts = product.modified
          ? product.suggestedProducts.filter(
              (suggested) =>
                (!suggested.stock || suggested.stock > 0) &&
                suggested.stock >= count
            )
          : modalItem.suggestedProducts
              .map((suggestedProduct) => ({
                ...suggestedProduct,
                count: count,
              }))
              .filter(
                (suggested) =>
                  (!suggested.stock || suggested.stock > 0) &&
                  suggested.stock >= count
              );
        return { ...product, suggestedProducts };
      } else {
        return product;
      }
    });
  }

  changeCount(newValue: string, p: any, index: number): void {
    const cleanedValue = newValue.replace(/,/g, '');
    const numberValue = parseFloat(cleanedValue);
    if (cleanedValue.trim() === '' || isNaN(numberValue)) {
      p.count = '0';
    } else {
      const maxValue = 999999;
      if (numberValue > maxValue) {
        p.count = this.formatNumber(maxValue);
      } else {
        p.count = this.formatNumber(numberValue);
      }
    }
    this.ref.detectChanges();
  }

  changeCountSuggested(
    newValue: any,
    suggested: any,
    i: number,
    j: number,
    newMax: any
  ): void {
    let value = Number(newValue);
    const max = Number(newMax);
    if (newValue === '' || isNaN(value)) {
      suggested.count = this.formatNumber(0);
    } else if (value > max) {
      suggested.count = this.formatNumber(max);
    } else {
      suggested.count = this.formatNumber(value);
    }

    const inputElement = document.querySelector(`#input-${i}-${j}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = suggested.count;
    }

    const inputElementMobile = document.querySelector(`#input-${i}-${j}-mobile`) as HTMLInputElement;
    if (inputElementMobile) {
      inputElementMobile.value = suggested.count;
    }

    this.ref.detectChanges();
  }

  replaceProduct(suggested: any, product: any, i: number, j: number): void {
    // Verificar si el producto sugerido es diferente del producto original
    const originalProduct = this.localProducts.find(p => p.sku === product.sku);
    
    if (originalProduct && originalProduct.sku !== suggested.sku) {
      // Solo agregar a replacedProducts si el producto sugerido es diferente del original
      this.replacedProducts = this.replacedProducts.filter(
        (p) => p.sku !== product.sku
      );
      this.replacedProducts.push(suggested);
    } else {
      // Si se vuelve a colocar el original, eliminar de replacedProducts
      this.replacedProducts = this.replacedProducts.filter(
        (p) => p.sku !== product.sku
      );
    }
  
    const newParent = {
      ...suggested,
      suggestedProducts: Array.isArray(suggested.suggestedProducts)
        ? [...suggested.suggestedProducts]
        : [],
    };
  
    const updatedSuggestedProducts = product.suggestedProducts.map(
      (item, index) => (index === j ? { ...product } : item)
    );
  
    this.products[i] = {
      ...newParent,
      suggestedProducts: updatedSuggestedProducts,
    };
  
    this.suggestedVisible[i] = false;
    this.ref.detectChanges();
  }  

  addProduct(suggested: any, product: any, i: number, j: number): void {
    const newSuggested = { ...suggested, suggested: true };

    const existingProductIndex = this.products.findIndex(
      (p) => p.sku === suggested.sku
    );
    if (existingProductIndex !== -1) {
      const existingProduct = this.products[existingProductIndex];
      existingProduct.count = String(
        Number(existingProduct.count) + Number(product.count)
      );
    } else {
      this.products.unshift(newSuggested);
    }

    product.suggestedProducts = product.suggestedProducts.filter(
      (_, index) => index !== j
    );
    product.modified = true;

    this.suggestedVisible[i] = false;
  }

  saveChanges(): void {
    this.replacedProducts.map(product => {
      this._gtmEventService.acceptedSuggestedProductEvent(product?.description, product?.count, product?.salePrice)
    })
    this.deletedProducts.map(product => {
      this._gtmEventService.deletedProductoWithoutStockEvent(product?.description, product?.count, product?.salePrice)
    })
    this._gtmEventService.checkAvailabilityEvent(this.localProducts.length, this.products.length)
    if (!this.verifiedCountProducts()) {
      this.dialogRef.close({
        products: this.products,
        hasChanges: true,
      });
    }
  }

  verifiedCountProducts(): boolean {
    for (const product of this.products) {
      if (product.count === '0') {
        return true;
      }
    }
    return false;
  }
}

