import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-skeleton-sales-detail-progress',
  templateUrl: './skeleton-sales-detail-progress.component.html',
  styleUrls: ['./skeleton-sales-detail-progress.component.scss'],
})
export class SkeletonSalesDetailProgressComponent implements OnInit {
  @ViewChild('skeletonContainer') skeletonContainer!: ElementRef;
  @Input() itemsSkeletonDetail: any;
  skeletonWidth: number = 0;
  maxWidth1: string = '118px'; // Tamaño máximo para el primer elemento
  maxWidth2: string = '80px';  // Tamaño máximo para el segundo y tercer elemento
  maxWidth3: string = '266px'; // Tamaño máximo para el cuarto elemento
  maxWidth4: string = '46px';  // Tamaño máximo para el quinto elemento

  constructor() {}

  ngOnInit() {}

 
}
