import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {
  @Input() headerTable: any;
  @Input() cantColmPromotionPrices: any;
  counter = Array;
  cantColm = 8;
  constructor() { 
    
  }

  ngOnInit() {
  }

}
