import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MensajeErrorEmpty } from '@core/interfaces/selected-client';

@Component({
  selector: 'app-message-empty-error',
  templateUrl: './message-empty-error.component.html',
  styleUrls: ['./message-empty-error.component.scss'],
})
export class MessageEmptyErrorComponent implements OnInit {
  @Input() messageError: Array<any> = [];

  @Output() retryDataError = new EventEmitter<string>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
  }

  buttonRetry($event: any) {
    if ($event == 'Reintentar') {
      this.retryDataError.emit();
    }
  }
  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
