<header>
    <div class="header-container vw-100">
        <div class="logo-welcome d-flex jc-sb pv-10 ph-15 ai-center">
            <div>
                <div class="d-flex ai-center">
                    <a [routerLink]="['/tienda']" class="logo">
                        <img src="assets/image/logo_alicorp.svg" width="114" height="32" alt="Alicorp" />
                    </a>
                    <div class="welcome-text fts-14 pl-16 pt-8 hidden-text-welcome">
                        <div class="font-book line-height-16">¡Bienvenido a nuestra nueva web!</div>
                        <div class="font-medium line-height-24 text-description">{{user?.name}} - {{user?.code}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex ai-center">
                <div>
                    <span mat-button class="d-flex ai-center cursor-pointer"
                        *ngIf="this.businessUnities.length > 1 ;else oneBusinessUnit" [matMenuTriggerFor]="unitMenu">
                        <div class="welcome-text-unit fts-14 ta-r hidden-xxs">
                            <div class="font-book line-height-16 w-space">Unidad de negocio</div>
                            <div class="font-medium line-height-24 text-description">{{unitSelect.name}}</div>
                        </div>
                        <img class="ml-8 img-nav" src="assets/image/business-unities/{{unitSelect.image}}-hover.svg">
                    </span>
                    <ng-template #oneBusinessUnit>
                        <span mat-button class="d-flex ai-center cursor-pointer">
                            <div class="welcome-text-unit fts-14 ta-r hidden-xxs">
                                <div class="font-book line-height-16 w-space">Unidad de negocio</div>
                                <div class="font-medium line-height-24 text-description">{{unitSelect.name}}</div>
                            </div>
                            <img class="ml-8 img-nav"
                                src="assets/image/business-unities/{{unitSelect.image}}-hover.svg">
                        </span>
                    </ng-template>

                    <mat-menu #unitMenu="matMenu" xPosition="before">
                        <button mat-menu-item class="d-flex ai-center jc-fe" *ngFor="let unit of businessUnitiesTrue"
                            (click)="selectOption(unit)" (mouseenter)="unit.hover = true"
                            (mouseleave)="unit.hover = false">
                            <span class="text-description font-medium line-height-24">{{unit.name}}</span>
                            <img *ngIf="!unit.hover" class="ml-8 img-mat-menu"
                                src="assets/image/business-unities/{{unit.image}}.svg">
                            <img *ngIf="unit.hover" class="ml-8 img-mat-menu"
                                src="assets/image/business-unities/{{unit.image}}-hover.svg">
                        </button>
                    </mat-menu>
                </div>
                <img class="ml-8 img-nav cursor-pointer" src="assets/image/home/icon-notification-back.svg" alt="">
            </div>
        </div>
    </div>
    <section class="navbar-section">
        <mat-toolbar color="accent" class="vw-100 jc-center">
            <div class="vw-100 nav-container">
                <div class="logo-welcome d-flex jc-sb ai-center">
                    <div class="d-flex jc-sb ai-center button-categories-responsive-mobile">
                        <div class="d-flex ai-center vw-100">
                            <mat-drawer-container class="example-container">
                                <mat-drawer #drawer class="categories-sidenav" mode="over" (openedStart)="openedStart()" (closedStart)="closedStart()">
                                    <app-categories-list [list]="listCategories" (onRedirect)="categoriesEmit($event)">
                                    </app-categories-list>
                                </mat-drawer>

                                <mat-drawer-content class="example-sidenav-content">
                                    <a mat-button (click)="openCategories()"
                                        class="vw-100 mat-icon-button-categories  container-section-categories nav-button ph-16 d-flex jc-center ai-center">
                                        <img class="icon-nav-white" src="assets/image/home/nav/icon-menu.svg">
                                        <span class="font-book fts-16 pl-5 hidden-text-cat-car">Categorías</span>
                                    </a>
                                </mat-drawer-content>

                            </mat-drawer-container>
                        </div>
                        <div class="d-flex ai-center pl-16  hidden-xms">
                            <form class="pos-re">
                                <input type="text" #autoCompleteInput placeholder="Buscar productos"
                                    class="input-search pl-16" [matAutocomplete]="auto"
                                    (ngModelChange)="getList($event)" [(ngModel)]="searchTextValue" (click)="clickSearchBar()"
                                    [ngModelOptions]="{standalone: true}" (blur)="mouseUp(searchTextValue)"
                                    (focus)="mouseClickInput(searchTextValue)" maxlength="50">
                                <i class="icon-search"> <img class="icon-nav-white" src="assets/image/home/nav/icon-search-green.svg" alt=""></i>
                                <mat-autocomplete #auto="matAutocomplete" class="container-autocomplete-search">
                                    <mat-option class=" text-option-autocomplete font-medium fts-12"
                                        *ngFor="let search of this.searchResponse$ | async"
                                        (click)="searchEngineSelectedProduct(search.sku, search.description)">
                                        {{search.description}}
                                    </mat-option>
                                </mat-autocomplete>
                            </form>
                        </div>
                    </div>
                    <div class="d-flex jc-sb ai-center showStyle">
                        <div class="d-flex ai-center container-button-menu showStyle" (click)="clickUploadOrder()" [routerLink]="['/herramientas/subir']">
                            <button mat-icon-button class="vw-100 nav-button ph-16">
                                <img class="icon-nav-white" src="assets/image/home/nav/icon-excel.svg">
                                <span class="font-book fts-16 pl-5 hidden-text-nav-sm">Subir pedido</span>
                            </button>
                        </div>
                        <div class="d-flex ai-center showStyle">
                            <mat-drawer-container class="example-container">
                                <mat-drawer #drawerCar class="car-sidenav" mode="over" (openedStart)="openedStart()" (closedStart)="closedStart()">
                                    <app-show-car #appShowCar [car]="car" (countProduct)="countProduct($event)" [dataCurrentAccount]="dataCurrentAccount"
                                        (deleteProduct)="deleteProduct($event)" (deleteCar)="deleteCar()" (selectConfirmationNoStock)="selectConfirmation(true)"
                                        (selectConfirmationStock)="selectConfirmation(false)" (closeModal)="closedStart()">
                                    </app-show-car>
                                </mat-drawer>

                                <mat-drawer-content class=" container-button-menu">
                                    <a mat-button class="vw-100 nav-button ph-16  d-flex jc-center ai-center"
                                        (click)="openCar()">
                                        <div class="d-flex ai-center jc-center">
                                            <div class="d-flex icon-nav-white" [matBadge]="badge"
                                                [matBadgeHidden]="hidden">
                                                <img class="icon-nav-white" src="assets/image/home/nav/icon-car.svg">
                                            </div>
                                            <span class="font-book fts-16 pl-5 hidden-text-cat-car">Mi carrito</span>
                                        </div>
                                    </a>
                                </mat-drawer-content>
                            </mat-drawer-container>
                        </div>
                        <div class="d-flex ai-center container-button-menu showStyle">
                            <button mat-icon-button
                                class=" vw-100 nav-button d-flex ai-center ph-16 center-button-my-account"
                                [matMenuTriggerFor]="belowMenu" (click)="clickMyAccount()">
                                <img class="icon-nav-white" src="assets/image/home/nav/icon-user.svg">
                                <span class="font-book fts-16 pl-5 hidden-text-nav-sm">Mi cuenta</span>
                            </button>
                            <mat-menu #belowMenu="matMenu" xPosition="before">
                               <div *ngFor="let icon of iconProfileUser">
                                <button mat-menu-item class="d-flex ai-center container-button-profile"
                                *ngIf="icon.value_menu" (click)="selectIconProfile(icon.name)"
                                (mouseenter)="icon.hover = true" (mouseleave)="icon.hover = false">
                                <img *ngIf="!icon.hover" class="mr-8 icon-img-profile"
                                    src="assets/image/home/profile-user/icon-{{icon.image}}.svg">
                                <img *ngIf="icon.hover" class="mr-8 icon-img-profile"
                                    src="assets/image/home/profile-user/icon-{{icon.image}}-white.svg">
                                <span
                                    [ngClass]="icon.hover?'text-profile-hover':'text-profile'">{{icon.name}}</span>
                                <span *ngIf="icon.cardNew"
                                    [ngClass]="icon.hover?'new-item-hover':'new-item'">NUEVO</span>
                                    <span *ngIf="icon.name=='Reconocimientos'"
                                    [ngClass]="icon.hover?'new-item-hover':'new-item'">Nueva versión</span>
                            </button>
                               </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
        </mat-toolbar>
        <mat-toolbar color="accent" class="vw-100 d-flex ai-center search-mobile show-xms">
            <div class="d-flex ai-center vw-100">
                <form class="pos-re vw-100 container-autocomplete-search-mobile">
                    <input type="text" placeholder="Buscar productos" class="vw-100 input-search-mobile pl-16"
                        [matAutocomplete]="autoMobile" (ngModelChange)="getList($event)" [(ngModel)]="searchTextValue"
                        [ngModelOptions]="{standalone: true}" (blur)="mouseUp(searchTextValue)"
                        (focus)="mouseClickInput(searchTextValue)" maxlength="50">
                    <i class="icon-search"> <img src="assets/image/home/nav/icon-search-green.svg"></i>
                    <mat-autocomplete #autoMobile="matAutocomplete" class="">
                        <mat-option class="text-option-autocomplete-mobile font-medium fts-12"
                            *ngFor="let search of this.searchResponse$ | async"
                            (click)="searchEngineSelectedProduct(search.sku)">
                            {{search.description}}
                        </mat-option>
                    </mat-autocomplete>
                </form>
            </div>
        </mat-toolbar>
    </section>
</header>
<div #search_opacity (click)="removeOpacityAutocomplete()"></div>
