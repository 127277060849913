import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-router-error',
  templateUrl: './router-error.component.html',
  styleUrls: ['./router-error.component.scss']
})
export class RouterErrorComponent implements OnInit {
  responseService: any;
  constructor(private _location: Location, private router: Router) { }

  ngOnInit() {
    this.dataError(this.router.url);
  }
  goBack() {
    this._location.back()
  }
  dataError(error) {
    switch (error) {
      case '/error':
        this.responseService = { img: 'assets/svg/spot-not-found.svg', button: true, title: 'Página no encontrada', description: 'Lo sentimos, pero la página que busca no existe o no se puede encontrar, esto puede deberse a que se ha escrito incorrectamente o que la página ha sido dada de baja.' };
        break;
      case '/404':
        this.responseService = { img: 'assets/svg/spot-not-found.svg', button: true, title: 'Página no encontrada', description: 'Lo sentimos, pero la página que busca no existe o no se puede encontrar, esto puede deberse a que se ha escrito incorrectamente o que la página ha sido dada de baja.' };
        break;
      case '/500':
        this.responseService = { img: 'assets/svg/spot-server-fail.svg', button: true, title: '¡Vaya! Algo salió mal', description: 'Lo sentímos, el servidor ha encontrado un error interno y no se encuentra disponible en este momento, por favor intenta más tarde.' };
        break;
      case '/503':
        this.responseService = { img: 'assets/svg/icon-maintenance-two.svg', button: false, title: 'Página en mantimiento', description: 'Estimado cliente, nos encontramos optimizando y rediseñando los procesos y las plataformas tecnológicas con las que operamos en Alicorp. Por ello, los siguientes días nuestra plataforma estará inactiva, te estaremos comunicando vía mail su reactivación y tus nuevas credenciales.Si tuvieras alguna consulta, no dudes en comunicarte con tu responsable de ventas y canales habituales' };
        break;
      case '/504':
        this.responseService = { img: 'assets/svg/spot-server-timeout.svg', button: true, title: 'Oops! Esto ha tardado demasiado', description: 'En este momento no se puede acceder a este sitio web. Ha tardado demasiado en responder' };
        break;
      default:
        break;
    }
  }
}
