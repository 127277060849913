<div class="container-modal" [ngClass]="dataRecieved.status == 2 || dataRecieved.status == 3 ? 'max-size':''">
  <div class="img-modal">
    <img class="icon " [ngClass]="dataRecieved.status == 1 ? 'check' :''" [src]="modals[dataRecieved.status].img" alt="">
   
  </div>
  <div class="message">
    <h1 class="fts-20 font-bold">{{modals[dataRecieved.status].title}}</h1>
    <p [innerHTML]="modals[dataRecieved.status].description"></p>
  </div>
</div>
