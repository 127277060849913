import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEspE from '@angular/common/locales/es-PE';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { RouterModule } from '@angular/router';
import { RedZoomModule } from 'ngx-red-zoom';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ShowCarComponent } from './components/header/show-car/show-car.component';
import { AlertModalComponent } from './components/modals/alert-modal/alert-modal.component';
import { ExcelTableModalComponent } from './components/modals/excel-table-modal/excel-table-modal.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { DowloadModalComponent } from './components/modals/dowload-modal/dowload-modal.component';
import { CheckStockModalComponent } from './components/modals/check-stock-modal/check-stock-modal.component';
import { FormatInputDirective } from '@core/directive/format-input.directive';
import { imageBrokeDirective } from '@core/directive/image-bronke.directive';
import { DialogCloseSesionComponent } from './components/modals/dialog-close-sesion/dialog-close-sesion.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { MessageEmptyErrorComponent } from './components/message-empty-error/message-empty-error.component';
import { HeaderClientComponent } from './components/header-seller/header-seller.component';
import { MenuResponsiveComponent } from './components/menu-responsive/menu-responsive.component';
import { ProfileResponsiveComponent } from './components/profile-responsive/profile-responsive.component';
import { ShowSellerCarComponent } from './components/header-seller/show-seller-car/show-seller-car.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ClientDataModalComponent } from './components/modals/client-data-modal/client-data-modal.component';
import { SatisfactionSuggestedOrderComponent } from './components/modals/suggested-order/satisfaction-suggested-order/satisfaction-suggested-order.component';
import { SuggestedOrderComponent } from './components/modals/suggested-order/suggested-order-modal/suggested-order-modal.component';
import { LoaderSuggestedOrderComponent } from './components/modals/suggested-order/loader-suggested-order/loader-suggested-order-modal.component';
import { ErrorSuggestedOrderComponent } from './components/modals/suggested-order/error-suggested-order/error-suggested-order.component';
import { SkeletonAwardsComponent } from './components/skeleton-awards/skeleton-awards.component';
import { SkeletonListYearsComponent } from './components/skeleton-list-years/skeleton-list-years.component';
import { ChargingModalComponent } from './components/modals/charging-modal/charging-modal.component';
import { SkeletonSalesProgressComponent } from './components/skeleton-sales-progress/skeleton-sales-progress.component';
import { SkeletonListCardComponent } from './components/skeleton-list-card/skeleton-list-card.component';
import { SkeletonSalesDetailProgressComponent } from './components/skeleton-sales-detail-progress/skeleton-sales-detail-progress.component';
import { SkeletonAccountComponent } from './components/skeleton-account/skeleton-account.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CardRoutinesComponent } from './components/card-routines/card-routines.component';
import { RoutineModalComponent } from './components/modals/routine-modal/routine-modal.component';
import { AlertMessagesComponent } from './components/alert-messages/alert-messages.component';
import { EditShoppingCartModalComponent } from './components/modals/edit-shopping-cart-modal/edit-shopping-cart-modal.component';
import { OrderDetailModalComponent } from './components/modals/order-detail-modal/order-detail-modal.component';
import { ManageOrderModalComponent } from './components/modals/manage-order-modal/manage-order-modal.component';
import { SkeletonListManageProductsComponent } from './components/skeleton-list-manage-products/skeleton-list-manage-products.component';


registerLocaleData(localeEspE, 'es-PE');

const MODULES = [
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgxSkeletonLoaderModule,
  RedZoomModule
];

const COMPONENTS = [
  CategoriesListComponent,
  ShowCarComponent,
  HeaderComponent,
  WhatsappComponent,
  AlertModalComponent,
  ChargingModalComponent,
  DowloadModalComponent,
  ExcelTableModalComponent,
  CheckStockModalComponent,
  DialogCloseSesionComponent,
  ClientDataModalComponent,
  SkeletonComponent,
  MessageEmptyErrorComponent,
  HeaderClientComponent,
  MenuResponsiveComponent,
  ProfileResponsiveComponent,
  ShowSellerCarComponent,
  ProfileComponent, SuggestedOrderComponent,
  LoaderSuggestedOrderComponent,
  SatisfactionSuggestedOrderComponent,
  ErrorSuggestedOrderComponent,
  SkeletonAwardsComponent,
  SkeletonListYearsComponent,
  SkeletonSalesProgressComponent,
  SkeletonSalesDetailProgressComponent,
  SkeletonListCardComponent,
  SkeletonAccountComponent,
  PaginatorComponent,
  CardRoutinesComponent,
  PaginatorComponent,
  RoutineModalComponent,
  AlertMessagesComponent,
  EditShoppingCartModalComponent,
  OrderDetailModalComponent,
  ManageOrderModalComponent,
  SkeletonListManageProductsComponent
]

const DIRECTIVES = [
  FormatInputDirective,
  imageBrokeDirective
]

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  imports: [...MODULES],
  exports: [
    ...MODULES,
    ...COMPONENTS,
    ...DIRECTIVES
  ],
})
export class SharedModule { }
