import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface errors {
  status: string;
  sku: string;
  description: string;
}
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit, OnDestroy {

  dataRecieved: any ;
  modals =
    [{
        img: 'assets/image/modal/icon-file-success.svg',
        title: 'Archivo subido con éxito',
        description: 'Tu archivo a sido subido de forma exitosa, ahora solo un paso más para confirmar tu pedido.',
        actions: [{
          name: 'ENTENDIDO',
          class: 'btn-green ',
          event: () => this.save(1, "success")
        }],
      },
      {
        img: 'assets/image/modal/icon-alert-new.svg',
        title: 'Proceso fallido',
        description: 'La columna CANTIDAD está vacía.',
        actions: [{
          name: 'ENTENDIDO',
          class: 'btn-green',
          event: () => this.save(2, "alert")
        }],
      },
      {
        img: 'assets/image/modal/icon-alert-new.svg',
        title: 'No se procesó todo el pedido',
        description: `Hemos encontrado la(s) siguiente(s) observacion(es) en el archivo, puedes omitirlas y  <b class="font-bold">continuar</b> o  <b class="font-bold">cancelar</b> para hacer la corrección`,
        actions: [{
          name: 'CANCELAR',
          class: 'btn-back-new fts-16 font-medium',
          event: () => this.save(2, "alert")
          },
          {
            name: 'CONTINUAR',
            class: 'btn-green',
            event: () => this.save(1, "success")
        }],
      },
      {
        img: 'assets/image/modal/icon-alert-new.svg',
        title: 'Formato de archivo no admitido',
        description: `Lo sentimos por el momento solo se permiten archivos en formato <b style="font-family: 'AlicorpWeb-Bold'">Excel (*.xls, *.xlsx)</b>.`,
        actions: [{
          name: 'ENTENDIDO',
          class: 'btn-green',
          event: () => this.save(1, "success")
        }],
      },
      {
        img: 'assets/image/modal/icon-file-success.svg',
        title: 'Archivo subido con éxito',
        description: 'Tu archivo ha sido subido de forma exitosa, ahora solo un paso más para confirmar tu pedido.',
        actions: [{
          name: 'ENTENDIDO',
          class: 'btn-green font-medium',
          event: () => this.save(1, "success")
        }],
      },
      {
        img: 'assets/image/modal/loader-mode-pay.svg',
        title: 'Un momento por favor...',
        description: 'Estamos actualizando el monto total, esto puede tardar varios segundos.',
      },
      {
        img: 'assets/image/modal/loader-upload.svg',
        title: 'Subiendo archivo',
        description: 'Por favor espera unos segundos mientras realizamos la carga de tu archivo.',
      },
      {
        img: 'assets/image/modal/icon-ops.png',
        title: 'Oops',
        description: 'Algo salió mal!',
        actions: [{
          name: 'OK',
          class: 'btn-green font-medium',
          event: () => this.save(1, "success")
        }],
      },
      {
        img: 'assets/image/modal/icon-check.svg',
        title: 'Verificación exitosa',
        actions: [{
          name: 'ENTENDIDO',
          class: 'btn-green font-medium opacity',
          event: () => this.save(1, "success")
        }],
      },
      {
        img: 'assets/image/modal/cart-spot.svg',
        title: 'Importe mínimo no válido',
        description: 'El valor del pedido no cubre el pedido mínimo rentable',
        actions: [{
          name: 'Ok, Entendido',
          class: 'btn-green font-medium opacity',
          event: () => this.save(1, "success")
        }],
      },
      {
        img: 'assets/image/modal/loader-upload.svg',
        title: 'Un momento por favor...',
        description: 'Estamos actualizando el monto total, esto puede tardar varios segundos.',
      },
      {
        img: 'assets/image/modal/icon-price.svg',
        title: 'Actualización de precios',
        description: 'Vamos a actualizar la lista de precios, es probable que veas ligeros cambios en el valor total.',
        actions: [{
          name: 'Ok, Entendido',
          class: 'btn-green font-medium opacity',
          event: () => this.save(1, "success")
        }],
      },
      ]
  dataSource : errors[] = []
  displayedColumns: string[] = ['status', 'sku', 'description'];
  constructor(
    public dialogRef: MatDialogRef<AlertModalComponent>,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.openedStart()
      this.dataRecieved = data;
      if(data.status === 2){
        this.dataSource = data.skuError
      }
    }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.closedStart()
  }

  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  save(status, message){
    this.dialogRef.close({status:status, message: message});
  }
  onNoClick(){
    this.dialogRef.close(false);
  }
  
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
