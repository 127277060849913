import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/authentication/auth.service';
import { environment } from '@env/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private ocpKey;
  constructor(private inj: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      this.ocpKey = environment.ocpKey;
    if (req.headers.has('Authorization')) {
      return next.handle(req);
    }

    const tok = this.inj.get(AuthService);

    const authHeader = tok.getToken();
    if (!tok.isLoggedIn()) {
      const dupReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Ocp-Apim-Subscription-Key', this.ocpKey),
      });
      return next.handle(dupReq);
    } else {
      const dupReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Ocp-Apim-Subscription-Key', this.ocpKey)
          .set('Authorization', `Bearer ${authHeader}`),
      });
      return next.handle(dupReq);
    }
  }
  obtenerUltimaPalabraDespuesDeBarra(url: string): string {
    // Dividir la cadena por las barras y seleccionar el último elemento
    const partes = url.split('/');
    const ultimaPalabra = partes[partes.length - 1];

    return ultimaPalabra;
  }
}
