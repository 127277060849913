import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GtmEventService } from '@core/analytics/gtm-events.service';
import { UtilsService } from '@core/services/utils/utils.service';

@Component({
  selector: 'app-edit-shopping-cart-modal',
  templateUrl: './edit-shopping-cart-modal.component.html',
  styleUrls: ['./edit-shopping-cart-modal.component.scss']
})
export class EditShoppingCartModalComponent implements OnInit, OnChanges {
  @ViewChildren('referenceInput2', { read: ElementRef })
  referenceInput2!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChildren('referenceInputResponsive', { read: ElementRef })
  referenceInputResponsive!: QueryList<ElementRef<HTMLInputElement>>;

  private _data: any;
  private localProducts: any[] = []; // Copia local de productos para trabajar dentro del modal
  products: any[] = [];
  private replacedProducts: any[] = [];
  private addProducts: any[] = [];
  private deletedProducts: any[] = [];
  private suggestedProducts: any[] = [];
  private changeProducts: any[] = [];
  repeatCount = Array(3);
  repeatCountMobile = Array(2);
  inStockProducts: any[] = [];
  outOfStockProducts: any[] = [];
  suggestedVisible: boolean[] = [];
  firstChange = false;
  prevTotalPrice: any;


  constructor(
    public dialogRef: MatDialogRef<EditShoppingCartModalComponent>,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private _gtmEventService: GtmEventService,
    @Inject(MAT_DIALOG_DATA) public initialData: any
  ) {
    this._data = initialData;
    this.localProducts = JSON.parse(
      JSON.stringify(initialData.localCar.products)
    ); // Hacer una copia profunda de los productos iniciales para trabajar dentro del modal
    this.prevTotalPrice = initialData?.localCar?.totalInvoicePrice;
  }

  ngOnInit(): void {
    if (this._data) {
      this.products = JSON.parse(JSON.stringify(this.localProducts)); // Usar la copia local de los productos
      this.separateProducts(this._data);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.firstChange) {
      this.firstChange = true;
    } else {
      this.ref.detectChanges();
    }
  }

  get data(): any {
    return this._data;
  }

  set data(value: any) {
    this._data = value;
    this.products = JSON.parse(JSON.stringify(this.localProducts)); // Usar la copia local de los productos
    this.separateProducts(value);
  }

  separateProducts(data: any): void {
    if (data && Array.isArray(data.modalData)) {
      this.products = this.mergeProductData(this.products, data.modalData);
      this.inStockProducts = data.modalData.filter((product) => product.stock);
      this.outOfStockProducts = data.modalData.filter(
        (product) => !product.stock
      );
    }
  }

  evaluateValue(p, index) {
    if (p.count == '' || parseFloat(p.count) === 0) {
      this.changeCount('1', p, index);
      const refInput = this.referenceInput2.find((_, i) => i == index);
      const refInputResponsive = this.referenceInputResponsive.find(
        (_, i) => i == index
      );
      refInput?.nativeElement.classList.add('animation');
      refInputResponsive?.nativeElement.classList.add('animation');
    }
  }

  validateStockReal(value: number, product: any): string {
    const replace = String(product).replace(/[^0-9-.]/g, '');
    if (value <= 0) {
      return 'Sin Stock';
    }
    if (value > 0 && value < parseInt(replace)) {
      return `Stock máx ${UtilsService.formatNumber(value)}`;
    }
    return '';
  }

  formatNumber(value) {
    return UtilsService.formatNumber(value);
  }

  countProductsOutStock(): number {
    return this.products.reduce(
      (count, product) =>
        this.validateStockReal(product.stockReal, product.count) !== ''
          ? count + 1
          : count,
      0
    );
  }

  mouseUp(e, p) {
    if (p.count == 0 || p.count == '') {
      p.count = '1';
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  validateInsufficientStock(stock: number, count: string): boolean {
    const replace = String(count).replace(/[^0-9-.]/g, '');
    return stock > 0 && stock < parseInt(replace);
  }

  deleteProduct(product: any, index: any): void {
    if(product.skuParent){
      const originalProduct = this.localProducts.find(p => p.sku === product.skuParent);
      this.deletedProducts.push(originalProduct); 
    } else {
      this.deletedProducts.push(product); 
    }
    this.replacedProducts = this.replacedProducts.filter(p => p.replace.sku !== product.sku);
    this.addProducts = this.addProducts.filter(p => p.add.sku !== product.sku);
    this.products = this.products.filter((p) => p !== product);
  }

  toggleSuggested(index: number): void {
    this.suggestedVisible[index] = !this.suggestedVisible[index];
    if(this.suggestedVisible[index] == true){
      const product = this.products[index];
      this._gtmEventService.suggestedViewEvent(product?.description, product?.InvoicePrice?.quantity, product?.InvoicePrice?.subTotal);
    }
  }

  mergeProductData(products: any[], modalData: any[]): any[] {
    const modalDataMap = new Map();
    modalData.forEach((item) => modalDataMap.set(item.sku, { ...item }));

    return products.map((product) => {
      const countString = String(product.count).replace(/,/g, '');
      const count = Number(countString);
      const modalItem = modalDataMap.get(product.sku);
      if (modalItem && modalItem.suggestedProducts) {
        const suggestedProducts = product.modified
          ? product.suggestedProducts.filter(
              (suggested) =>
                (!suggested.stock || suggested.stock > 0) &&
                suggested.stock >= count
            )
          : modalItem.suggestedProducts
              .map((suggestedProduct) => ({
                ...suggestedProduct,
                count: count,
              }))
              .filter(
                (suggested) =>
                  (!suggested.stock || suggested.stock > 0) &&
                  suggested.stock >= count
              );
        return { ...product, suggestedProducts };
      } else {
        return product;
      }
    });
  }

  changeCount(newValue: string, p: any, index: number): void {
    const cleanedValue = newValue.replace(/,/g, '');
    const numberValue = parseFloat(cleanedValue);
    if (cleanedValue.trim() === '' || isNaN(numberValue)) {
      p.count = '0';
    } else {
      const maxValue = 999999;
      if (numberValue > maxValue) {
        p.count = this.formatNumber(maxValue);
      } else {
        p.count = this.formatNumber(numberValue);
      }
    }
    this.ref.detectChanges();
  }

  changeCountSuggested(
    newValue: any,
    suggested: any,
    i: number,
    j: number,
    newMax: any
  ): void {
    let value = Number(newValue);
    const max = Number(newMax);
    if (newValue === '' || isNaN(value)) {
      suggested.count = this.formatNumber(0);
    } else if (value > max) {
      suggested.count = this.formatNumber(max);
    } else {
      suggested.count = this.formatNumber(value);
    }

    const inputElement = document.querySelector(`#input-${i}-${j}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = suggested.count;
    }

    const inputElementMobile = document.querySelector(`#input-${i}-${j}-mobile`) as HTMLInputElement;
    if (inputElementMobile) {
      inputElementMobile.value = suggested.count;
    }

    this.ref.detectChanges();
  }

  formatStockText(product){
    let textoProducto = 'Con Stock'
    if(product.stockReal <= 0){
      textoProducto = 'Sin Stock'
    } else if(this.validateInsufficientStock(product.stockReal, product.count) && !product.skuParent){
      textoProducto = 'Sin Suficiente stock'
    }
    return textoProducto;
  }

  replaceProduct(suggested: any, product: any, i: number, j: number): void {
    // Verificar si el producto sugerido es diferente del producto original
    const originalProduct = this.localProducts.find(p => p.sku === product.sku);
    
    if (originalProduct && originalProduct.sku !== suggested.sku) {
      // Solo agregar a replacedProducts si el producto sugerido es diferente del original
      this.replacedProducts = this.replacedProducts.filter(
        (p) => p.sku !== product.sku
      );
      let copiedProduct = { ...product };
      let copiedSuggested = { ...suggested };
      copiedProduct.replace = copiedSuggested
      this.replacedProducts.push(copiedProduct);
    } else {
      // Si se vuelve a colocar el original, eliminar de replacedProducts
      this.replacedProducts = this.replacedProducts.filter(
        (p) => p.sku !== product.sku
      );
    }
  
    const newParent = {
      ...suggested,
      suggestedProducts: Array.isArray(suggested.suggestedProducts)
        ? [...suggested.suggestedProducts]
        : [],
    };
  
    const updatedSuggestedProducts = product.suggestedProducts.map(
      (item, index) => (index === j ? { ...product } : item)
    );
  
    this.products[i] = {
      ...newParent,
      suggestedProducts: updatedSuggestedProducts,
    };
  
    this.suggestedVisible[i] = false;
    this._gtmEventService.replaceOrAddSuggestedEvent('Reemplazar',this.formatStockText(product) ,product.description, product.InvoicePrice.quantity, suggested.description, suggested.count);
    this.ref.detectChanges();
  }  

  addProduct(suggested: any, product: any, i: number, j: number): void {
    const newSuggested = { ...suggested, suggested: true };

    const existingProductIndex = this.products.findIndex(
      (p) => p.sku === suggested.sku
    );
    if (existingProductIndex !== -1) {
      const existingProduct = this.products[existingProductIndex];
      existingProduct.count = String(
        Number(existingProduct.count) + Number(product.count)
      );
    } else {
      this.products.unshift(newSuggested);
    }

    product.suggestedProducts = product.suggestedProducts.filter(
      (_, index) => index !== j
    );
    product.modified = true;

    this.suggestedVisible[i] = false;
    this._gtmEventService.replaceOrAddSuggestedEvent('Añadir al carrito',this.formatStockText(product), product.description, product.InvoicePrice.quantity, suggested.description, suggested.count);
    let copiedProduct = { ...product };
    let copiedSuggested = { ...suggested };
    copiedProduct.add = copiedSuggested
    this.addProducts.push(copiedProduct);
  }

  filterObjectsWithEmptySuggestedProducts(data) {
    return data.filter(item => item.suggestedProducts && item.suggestedProducts.length === 0);
  }
  filterObjectsWithNonEmptySuggestedProducts(data) {
    // Obtener los SKU de this.replacedProducts y this.addProducts
    const replacedSkus = new Set(this.replacedProducts.map(item => item.replace.sku));
    const addedSkus = new Set(this.addProducts.map(item => item.add.sku));

    // Filtrar los objetos en data para eliminar aquellos cuyo sku esté en replacedSkus o addedSkus
    let filteredData = data.filter(item => !replacedSkus.has(item.sku) && !addedSkus.has(item.sku));
    filteredData.map(item => {
      item.textoProducto = this.formatStockText(item)
    })
    // Retornar los objetos que tienen suggestedProducts no vacíos
    return filteredData.filter(item => item.suggestedProducts && item.suggestedProducts.length > 0);
  }

  gtmEventsOnSaveChanges(events){
    const noSuggested = this.filterObjectsWithEmptySuggestedProducts(this.products)
    noSuggested.map(product => {
      this._gtmEventService.outOfStockOutOfReplaceEvent(this.formatStockText(product), product?.description, product?.InvoicePrice?.quantity, product?.InvoicePrice?.subTotal)
    })
    if(this.deletedProducts.length != 0){
      this._gtmEventService.deleteProductCarEvent('eliminar', this.deletedProducts)
    }

    this._gtmEventService.addProductCartEvent("Guardar cambios", this.products);
    this._gtmEventService.events = events;
  }

  modifiedProductCount(){
    this.products.map(product => {
      const originalProduct = this.localProducts.find(p => p.sku === product.sku);
      if(originalProduct){
        const originalCount = Number(originalProduct.count);
        const changeCount = Number(product.count)
        if(product.textoProducto != 'Con Stock'){
          if (originalCount != changeCount){
            this.changeProducts.push(product)
          }
        }
      }
    })
  }

  saveChanges(): void {
    this.modifiedProductCount();
    this.replacedProducts.map(item => {
      item.textoProducto = this.formatStockText(item)
    })
    this.addProducts.map(item => {
      item.textoProducto = this.formatStockText(item)
    })
    this.suggestedProducts = this.filterObjectsWithNonEmptySuggestedProducts(this.products)
    const events = {
      replacedProducts: this.replacedProducts,
      addProducts: this.addProducts,
      suggestedProducts: this.suggestedProducts,
      prevTotalPrice: this.prevTotalPrice,
      deletedProducts: this.deletedProducts,
      changeProducts: this.changeProducts,
    }
    this.gtmEventsOnSaveChanges(events);
    if (!this.verifiedCountProducts()) {
      this.dialogRef.close({
        products: this.products,
        hasChanges: true,
        events: events
      });
    }
  }

  verifiedCountProducts(): boolean {
    for (const product of this.products) {
      if (product.count === '0') {
        return true;
      }
    }
    return false;
  }
}

