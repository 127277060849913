import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';
import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private url = environment.apiURL;
  constructor(
    private _http: HttpClienteService,
    private _localStorageService:LocalStorageService,
    ) { }

  getProductsForCategory(data: any){
    const query = `${this.url}/wcl-material/products-category-brand?categoryCode=${data.categoryCode}&code=${data.clientCode}&sector=${data.sector}&zone=${data.zone}&schema=${data.schema}&segment=${encodeURIComponent(data.segment)}`
    return this._http.get(query);
  }
  getProductsForCategoryAndBrand(data: any){
    const query = `${this.url}/wcl-material/products-category-brand?categoryCode=${data.categoryCode}&code=${data.clientCode}&sector=${data.sector}&zone=${data.zone}&schema=${data.schema}&segment=${encodeURIComponent(data.segment)}&brandCode=${data.brandCode}`
    return this._http.get(query);
  }
  getAllProducts(data: any){
    const query = `${this.url}/wcl-material/all-products?code=${data.code}&sector=${data.sector}&zone=${data.zone}&schema=${data.schema}&segment=${encodeURIComponent(data.segment)}`
    return this._http.get(query);
  }

  setValidateSKU(data: any){
    const query = `${this.url}/wcl-material/available-products`
    return this._http.post(query, data);
  }


  getLaunches(data: any){
    const query = `${this.url}/wcl-customer/releases-products?code=${data.code}&sector=${data.sector}&zone=${data.zone}&schema=${data.schema}&segment=${encodeURIComponent(data.segment)}`
    return this._http.get(query);
  }

}
