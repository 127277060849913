import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
  HostListener,
  AfterContentChecked,
} from '@angular/core';
import { Car } from '@core/interfaces/cars';
import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  selector: 'app-show-car',
  templateUrl: './show-car.component.html',
  styleUrls: ['./show-car.component.scss'],
})
export class ShowCarComponent
  implements OnInit, OnChanges, AfterContentChecked
{
  @Input() car!: Car;
  @Input() dataCurrentAccount!: any;
  @Output() countProduct = new EventEmitter<any>();
  @Output() selectConfirmationStock = new EventEmitter<any>();
  @Output() selectConfirmationNoStock = new EventEmitter<any>();
  @Output() deleteProduct = new EventEmitter<any>();
  @Output() deleteCar = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<void>(); // EventEmitter para cerrar el modal

  @ViewChildren('referenceInput', { read: ElementRef })
  referenceInput!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild('scrollableContainer', { read: ElementRef })
  scrollableContainer!: ElementRef;
  tempCar!: any;
  unit: any;
  carLength = 0;
  public shouldScroll: boolean = false;
  constructor(
    private _localStorageService: LocalStorageService,
    private ref: ChangeDetectorRef,
    
  ) {}

  ngOnChanges(): void {
    this.loadLocalData();
    if (this.car) {
      this.carLength = this.car.products ? this.car.products?.length : 0;
    } else {
      this.carLength = 0;
    }
  }

  ngAfterContentChecked() {
    if (
      this.scrollableContainer &&
      this.shouldScroll &&
      this.scrollableContainer.nativeElement.scrollTop > 0
    ) {
      this.scrollableContainer.nativeElement.scrollTop = 0;
      this.shouldScroll = false;
    }
  }

  ngOnInit() {
    this.loadCar();
    this.loadLocalData();
  }

  loadCar(){
    this.tempCar = this.car ? this.car.products.map((obj) => ({ ...obj })) : []
  }

  loadLocalData() {
    this.unit = this._localStorageService.getItem('sectorSelect');
  }

  changeNoSockCount(array1: any[], array2: any[]): boolean {
    if(array1 && array2){
      if (array1.length !== array2.length) {
        return false;
      }

      for (let i = 0; i < array1.length; i++) {
        const obj1 = array1[i];
        const obj2 = array2.find((o) => o.sku === obj1.sku && o.stock === obj1.stock);
        if (!obj2 || obj2.count !== obj1.count && obj1.stock === true ) {
          return true;
        }
      }

      return false;
    }
    return false
  }

  changeCount(e, p, index) {
    this.countProduct.emit({ e, p });
    this.ref.detectChanges();
    const refInput = this.referenceInput.find((_, i) => i == index);
    if (refInput) {
      refInput.nativeElement.select();
    }
  }

  delete(p) {
    this.deleteProduct.emit(p);
  }
  clean() {
    this.deleteCar.emit();
  }
  orderConfirmation() {
    if(this.changeNoSockCount(this.car.products, this.tempCar)){
      this.selectConfirmationNoStock.emit();
    } else {
      this.selectConfirmationStock.emit();
    }
    this.tempCar = this.car.products;
  }
  evaluateValue(p, index) {
    if (p.count == '' || p.count == 0) {
      this.changeCount('1', p, index);
      const refInput = this.referenceInput.find((_, i) => i == index);
      const refInputResponsive = this.referenceInput.find((_, i) => i == index);
      refInput?.nativeElement.classList.add('animation');
      refInputResponsive?.nativeElement.classList.add('animation');
    }
  }
}
