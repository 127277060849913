import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface errors {
  sku: string;
  description: string;
}

@Component({
  selector: 'app-satisfaction-suggested-order',
  templateUrl: './satisfaction-suggested-order.component.html',
  styleUrls: ['./satisfaction-suggested-order.component.scss'],
})
export class SatisfactionSuggestedOrderComponent implements OnInit, OnDestroy {
  acceptedProducts: boolean = false;
  rejectedProducts: boolean = false;
  dataRecieved: any;
  modals = [
    {
      img: '',
      title: '¡Genial! Todos los productos de tu carrito cuentan con stock.',
      description: '',
      actions: [
        {
          name: 'Entendido',
          class: 'btn-green font-medium',
          event: () => this.dialogRef.close(),
        },
      ],
    },
    {
      img: '',
      title: '¡Listo! Se aplicarán los siguientes cambios',
      description: '',
      actions: [
        {
          name: 'Entendido',
          class: 'btn-green font-medium',
          event: () => this.save(1, 'success'),
        },
      ],
    },
    {
      img: '',
      title: 'Tu pedido fue registrado con éxito',
      description: 'Los productos sin suficiente stock serán regularizados por tu asesor de ventas.',
      actions: [
        {
          name: 'Entendido',
          class: 'btn-green font-medium',
          event: () => this.save(1, 'success'),
        },
      ],
    },
  ];
  dataSource: any;
  displayedColumns: string[] = ['sku', 'description'];
  constructor(
    public dialogRef: MatDialogRef<SatisfactionSuggestedOrderComponent>,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.dataRecieved = data;
    if (data.status == 1) {
      this.dataSource = data.skuError;
      this.acceptedProducts = this.dataSource.some(
        (product: any) => product.acceptSuggestion === true
      );
    } else {
      this.acceptedProducts = false;
    }
  }

  ngOnInit(): void {
    this.openedStart();
  }

  ngOnDestroy(): void {
    this.closedStart();
  }

  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  save(status, message) {
    this.dialogRef.close({ status: status, message: message });
  }
  onNoClick() {
    this.dialogRef.close(false);
  }
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
