import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface errors {
  status: string;
  sku: string;
  description: string;
}
@Component({
  selector: 'app-charging-modal',
  templateUrl: './charging-modal.component.html',
  styleUrls: ['./charging-modal.component.scss']
})
export class ChargingModalComponent implements OnInit, OnDestroy {

  dataRecieved: any ;
  modals =
    [{
        img: 'assets/image/modal/icon-file-success.svg',
        title: 'Un momento por favor... <br> estamos actualizando los precios de tu pedido',
        description: 'Esto puede tardar unos minutos.',
       
      }]
  constructor(
    public dialogRef: MatDialogRef<ChargingModalComponent>,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.openedStart()
      this.dataRecieved = data;
    
    }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.closedStart()
  }

  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  save(status, message){
    this.dialogRef.close({status:status, message: message});
  }
  onNoClick(){
    this.dialogRef.close(false);
  }
  
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
