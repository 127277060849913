<div class="modal-body">
  <div *ngIf="data.modalData; else nullRoutine">
    <div class="container header-container">
      <div class="modal-title font-bold-dali">
        <div class="d-flex align-items-center">
          <img
            src="assets/image/modal/edit-card.svg"
            alt="alert"
            class="pr-12"
          />
          <div>
            Tienes productos sin stock ({{countProductsOutStock()}})
          </div>
        </div>
      </div>
      <img
        class="cursor-pointer"
        src="assets/image/modal/close-modal.svg"
        alt="alert"
        (click)="close()"
      />
    </div>
    <div class="content-container">
      <div
        *ngFor="let product of products; let i = index"
        class="d-flex flex-column desktop"
      >
        <div class="product">
          <div class="product-description-container d-flex align-items-center">
            <div class="product-img">
              <div class="ta-c">
                <img
                  appImageBroke
                  [src]="product.url ? product.url : 'assets/image/search/photo-empty.svg'"
                  width="80"
                  height="80"
                />
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center">
              <div class="product-sku">
                <div>
                  <div class="d-flex align-items-center">
                    <div
                      *ngIf="product.stockReal <= 0"
                      class="status out-stock fts-12 line-height-14 mr-8 font-regular"
                    >
                      Sin stock
                    </div>
                    <div
                      *ngIf="validateInsufficientStock(product.stockReal, product.count) && !product.skuParent"
                      class="status insufficient-stock fts-12 line-height-14 mr-8 font-regular"
                    >
                      Sin stock suficiente
                    </div>
                    <div
                      *ngIf="product.skuParent"
                      class="status suggested-stock fts-12 line-height-14 mr-8 font-regular"
                    >
                      Producto sugerido
                    </div>
                    <div class="fts-12 color-gray-darken">
                      {{ product.sku }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-descripcion">
                {{ product.buyUnd || product.und }} - {{ product.description }}
              </div>
              <div class="product-price">
                Precio lista: S/ {{ product.salePrice }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div
              class="product-count-container d-flex align-items-center justify-content-center"
            >
              <div class="ta-c">
                <input
                  #referenceInput2
                  type="text"
                  class="ta-c h-40 p-0 input-car"
                  [ngClass]="{
						'input-car-enabled': validateStockReal(product.stockReal, product.count) !== 'Sin Stock',
						'input-car-disabled': validateStockReal(product.stockReal, product.count) === 'Sin Stock'
					  }"
                  appFormatInput
                  [ngModel]="product.count"
                  (ngModelChange)="changeCount($event, product, i)"
                  [disabled]="validateStockReal(product.stockReal, product.count) === 'Sin Stock'"
                />
                <div
                  class="fts-12 line-height-16 font-regular color-subTitle-custom pt-8"
                >
                  {{ validateStockReal(product.stockReal, product.count) }}
                </div>
              </div>
            </div>
            <div class="view-suggested-container">
              <div
                *ngIf="product?.skuParent || product?.stockReal === 0 || (product?.stockReal > 0 && validateInsufficientStock(product?.stockReal, product?.count))"
              >
                <div
                  *ngIf="(!product.suggestedProducts || product.suggestedProducts.length === 0) && !product.suggested; else suggested"
                  class="whitout-suggested d-flex align-items-center justify-content-center"
                >
                  <span>Sin sugerencia</span>
                </div>
                <ng-template #suggested>
                  <div
                    *ngIf="!product.suggested"
                    class="d-flex align-items-center justify-content-center cursor-pointer"
                    style="gap: 5px;"
                    (click)="toggleSuggested(i)"
                  >
                    <span>Ver sugerencia</span>
                    <img
                      *ngIf="!suggestedVisible[i]"
                      src="assets/image/modal/suggested-icon-down.svg"
                      alt="alert"
                      class="pr-8"
                      width="16"
                      height="16"
                    />
                    <img
                      *ngIf="suggestedVisible[i]"
                      src="assets/image/modal/suggested-icon-up.svg"
                      alt="alert"
                      class="pr-8"
                      width="16"
                      height="16"
                    />
                  </div>
                </ng-template>
              </div>
            </div>
            <div
              class="delete-container-container d-flex align-items-center justify-content-center"
            >
              <img
                src="assets/image/modal/trash-bold.svg"
                alt="alert"
                class="pr-8 cursor-pointer"
                width="16"
                height="16"
                (click)="deleteProduct(product, i)"
              />
            </div>
          </div>
        </div>
        <div class="suggested-container" *ngIf="suggestedVisible[i]">
          <ng-container
            *ngFor="let suggested of product.suggestedProducts | slice:0:3; let j = index"
          >
            <div
              class="suggested-product"
            >
              <div>
                <div
                  class="d-flex align-items-center suggested-product-description"
                >
                  <div class="product-img">
                    <div class="ta-c">
                      <img
                        appImageBroke
                        [src]="suggested.url ? suggested.url : 'assets/image/search/photo-empty.svg'"
                        width="80"
                        height="80"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <div class="product-sku">
                      <div>
                        <div class="d-flex align-items-center">
                          <div
                            *ngIf="suggested.stockReal <= 0"
                            class="status out-stock fts-12 line-height-14 mr-8 font-regular"
                          >
                            Sin stock
                          </div>
                          <div
                            *ngIf="validateInsufficientStock(suggested.stockReal, suggested.count) && !suggested.skuParent"
                            class="status insufficient-stock fts-12 line-height-14 mr-8 font-regular"
                          >
                            Sin stock suficiente
                          </div>

                          <div
                            *ngIf="suggested.skuParent"
                            class="status suggested-stock fts-12 line-height-14 mr-8 font-regular"
                          >
                            Producto sugerido
                          </div>
                          <div class="fts-12 color-gray-darken">
                            {{ suggested.sku }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="product-descripcion">
                      {{ suggested.buyUnd || suggested.und }} - {{
                      suggested.description }}
                    </div>
                    <div class="product-price">
                      Precio lista: S/ {{ suggested.price || suggested.cashPrice
                      }}
                    </div>
                  </div>
                </div>
                <div class="suggested-product-options">
                  <div>
                    <input
                      id="input-{{i}}-{{j}}"
                      #referenceInput3
                      type="text"
                      class="ta-c h-40 p-0 input-car input-car"
                      [ngClass]="{
                        'input-car-enabled': suggested.stockReal === undefined,
                        'input-car-disabled': suggested.stockReal <= 0
                      }"
                      appFormatInput
                      [(ngModel)]="suggested.count"
                      (ngModelChange)="changeCountSuggested($event, suggested, i, j, product.count)"
                      [disabled]="suggested.stockReal <= 0"
                    />
                    <div
                      class="fts-12 line-height-16 font-regular color-subTitle-custom pt-8"
                    >
                      Stock máx {{formatNumber(suggested.stock || suggested.stockReal)}}
                    </div>
                  </div>
                  <div
                    class="d-flex flex-column justify-content-between align-items-end"
                  >
                    <button
                      mat-flat-button
                      class="btn-options replace"
                      (click)="replaceProduct(suggested, product, i, j)"
                      [disabled]="suggested.stockReal <= 0"
                    >
                      Reemplazar
                    </button>
                    <button
                      mat-flat-button
                      class="btn-options add"
                      (click)="addProduct(suggested, product, i, j)"
                      [disabled]="suggested.stockReal <= 0"
                    >
                      Anadir al carrito
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div
        *ngFor="let product of products; let i = index"
        class="product-mobile mobile"
      >
        <div>
          <div class="d-flex align-items-center suggested-product-description">
            <div class="product-img">
              <div class="ta-c">
                <img
                  appImageBroke
                  [src]="product.url ? product.url : 'assets/image/search/photo-empty.svg'"
                  width="80"
                  height="80"
                />
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center">
              <div class="product-sku">
                <div>
                  <div class="d-flex align-items-center">
                    <div
                      *ngIf="product.stockReal <= 0"
                      class="status out-stock fts-12 line-height-14 mr-8 font-regular"
                    >
                      Sin stock
                    </div>
                    <div
                      *ngIf="validateInsufficientStock(product.stockReal, product.count) && !product.skuParent"
                      class="status insufficient-stock fts-12 line-height-14 mr-8 font-regular"
                    >
                      Sin stock suficiente
                    </div>

                    <div
                      *ngIf="product.skuParent"
                      class="status suggested-stock fts-12 line-height-14 mr-8 font-regular"
                    >
                      Producto sugerido
                    </div>
                    <div class="fts-12 color-gray-darken">
                      {{ product.sku }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-descripcion">
                {{ product.buyUnd || product.und }} - {{ product.description }}
              </div>
              <div class="product-price">
                Precio lista: S/ {{ product.salePrice }}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="assets/image/modal/trash-bold.svg"
                alt="alert"
                class="pr-8 cursor-pointer"
                width="16"
                height="16"
                (click)="deleteProduct(product, i)"
              />
            </div>
          </div>
          <div class="suggested-product-options">
            <div class="ta-c">
              <input
                #referenceInput2
                type="text"
                class="ta-c h-40 p-0 input-car"
                [ngClass]="{
          'input-car-enabled': validateStockReal(product.stockReal, product.count) !== 'Sin Stock',
          'input-car-disabled': validateStockReal(product.stockReal, product.count) === 'Sin Stock'
          }"
                appFormatInput
                [ngModel]="product.count"
                (ngModelChange)="changeCount($event, product, i)"
                [disabled]="validateStockReal(product.stockReal, product.count) === 'Sin Stock'"
              />
              <div
                class="fts-12 line-height-16 font-regular color-subTitle-custom pt-8"
              >
                {{ validateStockReal(product.stockReal, product.count) }}
              </div>
            </div>
            <div
              class="view-suggested-container d-flex align-items-center justify-content-end"
            >
              <div
                *ngIf="product?.skuParent || product?.stockReal === 0 || (product?.stockReal > 0 && validateInsufficientStock(product?.stockReal, product?.count))"
              >
                <div
                  *ngIf="(!product.suggestedProducts || product.suggestedProducts.length === 0) && !product.suggested; else suggested"
                  class="whitout-suggested d-flex align-items-center justify-content-center"
                >
                  <span>Sin sugerencia</span>
                </div>
                <ng-template #suggested>
                  <div
                    *ngIf="!product.suggested"
                    class="d-flex align-items-center justify-content-center cursor-pointer"
                    style="gap: 5px;"
                    (click)="toggleSuggested(i)"
                  >
                    <span>Ver sugerencia</span>
                    <img
                      *ngIf="!suggestedVisible[i]"
                      src="assets/image/modal/suggested-icon-down.svg"
                      alt="alert"
                      class="pr-8"
                      width="16"
                      height="16"
                    />
                    <img
                      *ngIf="suggestedVisible[i]"
                      src="assets/image/modal/suggested-icon-up.svg"
                      alt="alert"
                      class="pr-8"
                      width="16"
                      height="16"
                    />
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="suggestedVisible[i]">
          <ng-container
            *ngFor="let suggested of product.suggestedProducts | slice:0:3; let j = index"
          >
            <div
              class="product-mobile mt-16"
            >
              <div>
                <div
                  class="d-flex align-items-center suggested-product-description"
                >
                  <div class="product-img">
                    <div class="ta-c">
                      <img
                        appImageBroke
                        [src]="suggested.url ? suggested.url : 'assets/image/search/photo-empty.svg'"
                        width="80"
                        height="80"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <div class="product-sku">
                      <div>
                        <div class="d-flex align-items-center">
                          <div
                            *ngIf="suggested.stockReal <= 0"
                            class="status out-stock fts-12 line-height-14 mr-8 font-regular"
                          >
                            Sin stock
                          </div>
                          <div
                            *ngIf="validateInsufficientStock(suggested.stockReal, suggested.count) && !suggested.skuParent"
                            class="status insufficient-stock fts-12 line-height-14 mr-8 font-regular"
                          >
                            Sin stock suficiente
                          </div>

                          <div
                            *ngIf="suggested.skuParent"
                            class="status suggested-stock fts-12 line-height-14 mr-8 font-regular"
                          >
                            Producto sugerido
                          </div>
                          <div class="fts-12 color-gray-darken">
                            {{ suggested.sku }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="product-descripcion">
                      {{ suggested.buyUnd || suggested.und }} - {{
                      suggested.description }}
                    </div>
                    <div class="product-price">
                      Precio lista: S/ {{ suggested.price || suggested.cashPrice
                      }}
                    </div>
                  </div>
                </div>
                <div class="suggested-product-options">
                  <div>
                    <input
                      id="input-{{i}}-{{j}}-mobile"
                      #referenceInput3
                      type="text"
                      class="ta-c h-40 p-0 input-car input-car"
                      [ngClass]="{
                        'input-car-enabled': suggested.stockReal === undefined,
                        'input-car-disabled': suggested.stockReal <= 0
                      }"
                      appFormatInput
                      [(ngModel)]="suggested.count"
                      (ngModelChange)="changeCountSuggested($event, suggested, i, j, product.count)"
                      [disabled]="suggested.stockReal <= 0"
                    />
                    <div
                      class="fts-12 line-height-16 font-regular color-subTitle-custom pt-8"
                    >
                      Stock máx {{formatNumber(suggested.stock || suggested.stockReal)}}
                    </div>
                  </div>
                  <div
                    class="d-flex flex-column justify-content-between align-items-end"
                  >
                    <button
                      mat-flat-button
                      class="btn-options replace"
                      (click)="replaceProduct(suggested, product, i, j)"
                      [disabled]="suggested.stockReal <= 0"
                    >
                      Reemplazar
                    </button>
                    <button
                      mat-flat-button
                      class="btn-options add"
                      (click)="addProduct(suggested, product, i, j)"
                      [disabled]="suggested.stockReal <= 0"
                    >
                      Anadir al carrito
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="footer-content desktop-flex">
        <div class="footer-icon">
          ⚠️
        </div>
        <div class="footer-description">
          Te sugerimos editar las cantidades o eliminar los productos sin stock
          para completar tu compra.
          <span class="primary-text">Los precios se actualizarán</span> cuando
          guardes tus cambios.
        </div>
      </div>
      <div class="footer-actions">
        <button
          mat-flat-button
          class="btn-cancel d-flex justify-content-center align-items-center"
          (click)="close()"
        >
          Cancelar
        </button>
        <button
          mat-flat-button
          class="btn-save d-flex justify-content-center align-items-center"
          (click)="saveChanges()"
          [disabled]="verifiedCountProducts()"
        >
          Guardar cambios
        </button>
      </div>
    </div>
  </div>
  <ng-template #nullRoutine>
    <div class="container header-container">
      <div class="modal-title font-bold-dali">
        <div class="d-flex align-items-center">
          <img
            src="assets/image/modal/edit-card.svg"
            alt="alert"
            class="pr-8"
          />
          <span>
            <ngx-skeleton-loader
              count="1"
              [theme]="{
				  height: '16px',
				  width: '240px',
				  'border-radius': '8px',
				  'margin-bottom': '0px'
				}"
            >
            </ngx-skeleton-loader>
          </span>
        </div>
      </div>
      <img
        src="assets/image/modal/close-modal.svg"
        alt="alert"
        (click)="close()"
      />
    </div>
    <div class="content-container">
      <div *ngFor="let _ of repeatCount" class="product desktop-flex">
        <div class="product-description-container d-flex align-items-center">
          <div class="product-img">
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '80px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '247px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="product-count-container d-flex align-items-center justify-content-center"
          >
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '48px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
          <div
            class="view-suggested-container d-flex align-items-center justify-content-center"
          >
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
          <div
            class="delete-container-container d-flex align-items-center justify-content-center"
          >
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '16px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
        </div>
      </div>
      <div
        *ngFor="let _ of repeatCountMobile"
        class="product-mobile mobile-flex"
      >
        <div class="d-flex align-items-center suggested-product-description">
          <div class="product-img">
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '80px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '150px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
          <div
            class="delete-container-container d-flex align-items-center justify-content-center"
          >
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '16px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="product-count-container d-flex align-items-center justify-content-center"
          >
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '48px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
          <div
            class="view-suggested-container d-flex align-items-center justify-content-end"
          >
            <span>
              <ngx-skeleton-loader
                count="1"
                [theme]="{
							  height: '16px',
							  width: '80px',
							  'border-radius': '8px',
							  'margin-bottom': '0px'
							}"
              >
              </ngx-skeleton-loader>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="footer-content desktop-flex">
        <div class="footer-icon">
          ⚠️
        </div>
        <div class="footer-description">
          Te sugerimos editar las cantidades o eliminar los productos sin stock
          para completar tu compra.
          <span class="primary-text">Los precios se actualizarán</span> cuando
          guardes tus cambios.
        </div>
      </div>
      <div class="footer-actions desktop-flex">
        <span>
          <ngx-skeleton-loader
            count="1"
            [theme]="{
					  height: '40px',
					  width: '91px',
					  'border-radius': '8px',
					  'margin-bottom': '0px'
					}"
          >
          </ngx-skeleton-loader>
        </span>
        <span>
          <ngx-skeleton-loader
            count="1"
            [theme]="{
					  height: '40px',
					  width: '145px',
					  'border-radius': '8px',
					  'margin-bottom': '0px'
					}"
          >
          </ngx-skeleton-loader>
        </span>
      </div>
      <div class="footer-actions mobile-flex">
        <span>
          <ngx-skeleton-loader
            count="1"
            [theme]="{
					  height: '40px',
					  width: '91px',
					  'border-radius': '8px',
					  'margin-bottom': '0px'
					}"
          >
          </ngx-skeleton-loader>
        </span>
        <span>
          <ngx-skeleton-loader
            count="1"
            [theme]="{
					  height: '40px',
					  width: '243px',
					  'border-radius': '8px',
					  'margin-bottom': '0px'
					}"
          >
          </ngx-skeleton-loader>
        </span>
      </div>
    </div>
  </ng-template>
</div>
